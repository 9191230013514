import { GoogleTokenCallback } from './../../interfaces/google-token-callback';
import { GoogleUserdataCallback } from './../../interfaces/google-userdata-callback';
import { GoogleService } from './../../service/social/google.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OAuthService } from 'src/app/o-auth.service';
import { UserService } from 'src/app/service/user.service';
import { Spinner } from 'src/app/utils/stpiner';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { SocialPayload } from 'src/app/interfaces/social-payload';
import { StorageUtil } from 'src/app/utils/storage';

@Component({
  selector: 'app-google-return',
  templateUrl: './google-return.component.html',
  styleUrls: ['./google-return.component.css']
})
export class GoogleReturnComponent implements OnInit {

  public subs: Subscription;

  constructor(
    private googleService: GoogleService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private oAuthService: OAuthService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.subs = this.route.queryParams.subscribe(params => {
      if (params.code == null) {
        this.router.navigate(['/login']);
      } else {
        this.getAccessToken(params.code);
      }
    });
  }

  getAccessToken(code: string) {
    Spinner.spinner(document, true);
    this.googleService.getAccessToken(code).subscribe((r: GoogleTokenCallback) => {
      console.log('access token', r);
      if (r.accessToken) {
        this.getUserData(r.accessToken);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Google. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  async getUserData(accessToken: string) {
    Spinner.spinner(document, true);
    await this.googleService.loadGapi();
    this.googleService.getUserData(accessToken).subscribe((r: GoogleUserdataCallback) => {
      Spinner.spinner(document);
      console.log('user data', r);
      if (r.error) {
        ErrorReturnUtil.buildError(null, 'Ocorreu um erro no login via Google. Por favor, tente novamente mais tarde.');
        this.ngZone.run(() => {
          this.router.navigate(['/login']);
        });
        return;
      }
      this.ngZone.run(() => {
        this.socialLogin(accessToken, r);
      });
    });
  }

  socialLogin(accessToken: string, user: GoogleUserdataCallback) {
    const payload: SocialPayload = {
      email: user.email,
      id: user.id,
      name: user.name,
      provider: 'GOOGLE',
      token: accessToken
    };

    this.userService.socialLogin(payload).subscribe((r) => {
      Spinner.spinner(document);
      StorageUtil.setJwt(r.jwtToken);

      const tokenDecoded = this.oAuthService.getDecodedAccessToken(r.jwtToken);
      const userId = tokenDecoded['system-user'];

      this.userService.redirectAfterLogin(userId);

    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Google. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );

  }

}
