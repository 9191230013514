import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitar-nova-senha',
  templateUrl: './solicitar-nova-senha.component.html',
  styleUrls: ['./solicitar-nova-senha.component.css']
})
export class SolicitarNovaSenhaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
