import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from 'src/app/interfaces/headers';
import { StorageUtil } from 'src/app/utils/storage';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getAccessToken(code) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}social/FACEBOOK/token?code=${code}&redirect_uri=${environment.facebook_redirect_uri}`, requestOptions);
  }

  getUserData(accessToken) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`https://graph.facebook.com/me?fields=name,email,id&access_token=${accessToken}`, requestOptions);
  }
}
