import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { StorageUtil } from 'src/app/utils/storage';
import { ThrowStmt } from '@angular/compiler';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meus-dependentes',
  templateUrl: './meus-dependentes.component.html',
  styleUrls: ['./meus-dependentes.component.css']
})
export class MeusDependentesComponent implements OnInit {

  cols = [];
  values = [];
  table;
  page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  pagination = { page: 0, size: 10 };
  id: any;
  constructor(private service: ApiService, public router: Router) { }

  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.load();
    }
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.load();
    }
  }

  public setId(id): void {
    this.id = id;
  }

  public goPage(id): void {
    this.router.navigate([ 'clientes-dependentes' + '/' + id ]);
  }

  public bloquear(): void {
    this.service.path = this.id + '/bloquear';

    this.service.put(null, 'CLIENTE').subscribe(() => {
      this.load();
      this.service.path = '';
    });
  }

  public load() {
    this.service.params = { titular_id: StorageUtil.getUserPerfil() };
    this.service.get('DEPENDENTE').subscribe((r: any) => {

      this.table = true;
      if (r._embedded.dependenteResponseList) {
        r._embedded.dependenteResponseList.forEach((element) => {
          if (element.cliente.telefones.length > 0) {
            element.cliente.telefones = '(' + element.cliente.telefones[0].ddd + ') ' + element.cliente.telefones[0].numero;
          }
          this.values.push(element.cliente);
        });
      }

      this.page = r.page;
    }, (error: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(error.error.messages[0].message);
    });
  }

  public logout() {
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.load();
    this.cols = [
      { field: 'nome', header: 'Nome' },
      { field: 'email', header: 'E-mail' },
      { field: 'documento', header: 'Documento' },
      { field: 'sexo', header: 'Sexo' },
      { field: 'telefones', header: 'Telefone' },
    ];
  }
}

