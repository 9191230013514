import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../service/user.service';
import { MsgUtil } from '../../../../utils/msgUtil';
import { Spinner } from '../../../../utils/stpiner';

@Component({
  selector: 'app-prenchimento-agendamento',
  templateUrl: './prenchimento-agendamento.component.html',
  styleUrls: ['./prenchimento-agendamento.component.css']
})
export class PrenchimentoAgendamentoComponent implements OnInit {
  public maxDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getUTCDate()
  };
  public gender = ['Feminino', 'Masculino'];
  public sexo;
  public birthDate = null;
  public userID: string;

  @Output()
  public saveEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userID = this.userService.currentUserValue.customer.id;
  }

  public onCheck(value: string): void {
    switch (value) {
      case 'Feminino': {
        this.sexo = 'FEMALE';
        break;
      }

      case 'Masculino': {
        this.sexo = 'MALE';
        break;
      }
    }
  }

  public get isValid() {
    return this.birthDate && this.sexo && this.userID;
  }

  public cancel(): void {
    this.saveEvent.emit(false);
    this.ngbActiveModal.close();
  }

  public save(): void {
    if (!this.isValid) { return; }
    Spinner.spinner(document, true);

    const d = new Date(this.birthDate.replace(
      /(\d\d)(\d\d)(\d\d\d\d)/, '$3-$2-$1'
    ));

    const backDate = d.toISOString().substr(0, 10);
    const userInfo = {
      birthDate: backDate,
      sex: this.sexo
    };

    this.userService.patchUser(userInfo, this.userID).subscribe(res => {
      this.ngbActiveModal.close();
      MsgUtil.msgSuccessToast('Prontinho, dados salvos com sucesso!');
      this.userService.updateSubject();
    }, err => {
      MsgUtil.msgErrorToast('Houve um erro ao enviar seu formulário, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
    this.birthDate = null;
    this.sexo = null;
  }

}
