import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.css']
})
export class FaleConoscoComponent implements OnInit {

  dataFormGroup: FormGroup;
  constructor( private formBuilder: FormBuilder, private service: ApiService ) { }

  public sendEmail(): void {
    const formdata = new FormData();

    formdata.append('email_subject', 'Fale conosco');
    formdata.append('email_from', this.dataFormGroup.value.email);
    formdata.append('email_from_name', this.dataFormGroup.value.nome);
    formdata.append('email_to', 'atendimento@doutorpass.com');
    formdata.append('email_to_name', 'Doutorpass web');
    formdata.append('email_html_body',
   '<p> <b>Nome: </b>' + this.dataFormGroup.value.nome + '</p>' +
    '<p> <b>E-mail: </b>' + this.dataFormGroup.value.email + '</p><br>' +
    '<p> <b>Telefone: </b>' + this.dataFormGroup.value.telefone + '</p>' +
    '<p> <b>Mensagem: </b>' + this.dataFormGroup.value.mensagem + '</p>'
   );

    this.service.sendEmail(formdata).subscribe(() => {
      MsgUtil.msgSuccessToast('E-mail enviado com sucesso');

      this.dataFormGroup.reset();

    }, (error: HttpErrorResponse) => { });
  }

  ngOnInit() {
    this.dataFormGroup = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required]],
      telefone: ['', [Validators.required]],
      assunto: ['', [Validators.required]],
      mensagem: ['', [Validators.required]]
    });
  }

}
