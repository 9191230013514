import { UserDataModalService } from './../service/user-data-modal.service';
import { Component, OnInit } from '@angular/core';
import { StorageUtil } from '../utils/storage';
import { ApiService } from '../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequetsAgendamento } from './request';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from '../utils/msgUtil';
import { CalendarConfig } from '../utils/calendar-config';
import { AgendamentoUtil } from './agendamentoUtil';
import { Providers } from '../interfaces/providers';
import { ProviderService } from '../service/provider.service';
import { Schedules } from '../interfaces/schedules';
import { UserService } from '../service/user.service';
import { UserData } from '../interfaces/userData-interface';
import { Specialities } from '../interfaces/specialities';
import { DatePipe } from '@angular/common';
import { SpecialitiesSchedule } from '../interfaces/specialitiesSchedule';
import { ScheduleService } from '../service/schedule.service';
import { Subscription } from 'rxjs';
import { FormateDate } from 'src/app/utils/formateDate';

declare var $;

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.css']
})
export class AgendamentoComponent extends CalendarConfig implements OnInit {

  data1 = { data: '', periodo: '' };
  data2 = { data: '', periodo: '' };
  data3 = { data: '', periodo: '' };
  detalhes = { valor: undefined, obs: '', tipo: '' };
  observacao;
  provider: Providers = StorageUtil.getAgendamento();
  specialitiesSelected: SpecialitiesSchedule;
  isScheduled = false;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  constructor(private service: ApiService, private route: ActivatedRoute, private router: Router, private providersService: ProviderService, private userService: UserService, private scheduleService: ScheduleService, private userDataModalService: UserDataModalService) {
    super();
  }

  ngOnInit() {
    // Ordenar
    this.provider.specialities.sort(function(a, b) {
      if (a.speciality.name < b.speciality.name) {
        return -1;
      }
      if (a.speciality.name > b.speciality.name) {
        return 1;
      }
      return 0;
    });

    this.loadUserDataSubscription();
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  specialitiesSelect() {
    console.log('ESPECIALIDADE SELECIONADA', this.specialitiesSelected);
  }

  public getPeriodo1(value) {
    this.data1.periodo = value;
  }

  public getPeriodo2(value) {
    this.data2.periodo = value;
  }

  public getPeriodo3(value) {
    this.data3.periodo = value;
  }

  public confirmarAgendamento() {

    if (this.userDataIsComplete) {
      if (this.data1.data === '' || this.data1.periodo === '') {
        MsgUtil.msgErrorToast('Você precisa selecionar a Data 1');
        return;
      }

      if (this.data2.data === '' || this.data2.periodo === '') {
        MsgUtil.msgErrorToast('Você precisa selecionar a Data 2');
        return;
      }

      if (this.data3.data === '' || this.data3.periodo === '') {
        MsgUtil.msgErrorToast('Você precisa selecionar a Data 3');
        return;
      }

      if (FormateDate.formateData(this.data1.data) < FormateDate.formateData(Date.now()) || FormateDate.formateData(this.data2.data) < FormateDate.formateData(Date.now()) || FormateDate.formateData(this.data3.data) < FormateDate.formateData(Date.now())) {
        MsgUtil.msgErrorToast('Não é possível agendar para uma data no passado.');
        return;
      }

      // Check Period Date
      const manhaPeriodo = '1990-01-01 13:00:00';
      if (FormateDate.formateData(this.data1.data) === FormateDate.formateData(Date.now())) {
        if ((this.data1.periodo === 'MORNING') && (FormateDate.formateDateHours(Date.now()) > (FormateDate.formateDateHours(manhaPeriodo)))) {
          MsgUtil.msgErrorToast('A data 1 não pode ser agendada para o dia de hoje no período da manhã.');
          return;
        }
      }
      if (FormateDate.formateData(this.data2.data) === FormateDate.formateData(Date.now())) {
        if ((this.data2.periodo === 'MORNING') && (FormateDate.formateDateHours(Date.now()) > (FormateDate.formateDateHours(manhaPeriodo)))) {
          MsgUtil.msgErrorToast('A data 2 não pode ser agendada para o dia de hoje no período da manhã.');
          return;
        }
      }
      if (FormateDate.formateData(this.data3.data) === FormateDate.formateData(Date.now())) {
        if ((this.data3.periodo === 'MORNING') && (FormateDate.formateDateHours(Date.now()) > (FormateDate.formateDateHours(manhaPeriodo)))) {
          MsgUtil.msgErrorToast('A data 3 não pode ser agendada para o dia de hoje no período da manhã.');
          return;
        }
      }

      if (!this.specialitiesSelected) {
        MsgUtil.msgErrorToast('Você precisa selecionar a especialidade');
        return;
      }

      // Get user
      this.userService.getCurrentUser().subscribe((userData: UserData) => {
        const dataPipe = new DatePipe('en-US');

        const data1Payload = {
          optionDay: dataPipe.transform(this.data1.data, 'yyyy-MM-dd'),
          period: this.data1.periodo
        };
        const data2Payload = {
          optionDay: dataPipe.transform(this.data2.data, 'yyyy-MM-dd'),
          period: this.data2.periodo
        };
        const data3Payload = {
          optionDay: dataPipe.transform(this.data3.data, 'yyyy-MM-dd'),
          period: this.data3.periodo
        };

        const scheduleOptions = [];
        scheduleOptions.push(data1Payload, data2Payload, data3Payload);

        const payloadSchedule: Schedules = {
          customerId: userData.customer.id,
          notes: this.observacao ? this.observacao : '',
          providerSpecialityId: this.specialitiesSelected.id,
          scheduleOptions
        };

        console.log('payloadSchedule', payloadSchedule);

        this.scheduleService.schedulePost(payloadSchedule).subscribe((r: any) => {
          if (r.id) {
            this.isScheduled = true;
          }
        }, (error: HttpErrorResponse) => {
          MsgUtil.msgErrorToast(error.error.message);
        });

      });
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }


  }

  public goPerfil() {
    StorageUtil.cleanAgendamento();
    this.router.navigate(['/clientes-meus-agendamentos']);
  }

  OnDestroy() {
    StorageUtil.cleanAgendamento();
  }
}
