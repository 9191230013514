import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/utils/util';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { StorageUtil } from 'src/app/utils/storage';
import { FormateDate } from 'src/app/utils/formateDate';

@Component({
  selector: 'app-prestador-historico-agendamentos',
  templateUrl: './prestador-historico-agendamentos.component.html',
  styleUrls: ['./prestador-historico-agendamentos.component.css']
})
export class PrestadorHistoricoAgendamentosComponent implements OnInit {

  cols: any[] = [];
  values: any[] = [];
  table = false;
  page =  { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  pagination = { page: 0, size: 10 };

  constructor(private service: ApiService, private router: Router) {
    this.service.key = 'PRESTADOR';
  }

  public nextPage(): void {
   if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.load();
   }
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
       this.pagination.page--;
       this.load();
    }
   }

   private load() {
    this.buildParametros();
    this.service.getById( StorageUtil.getUserPerfil() + '/agendamentos' ).subscribe((r: any) => {
      this.buildList( r );
      this.page = r.page;
    });
  }

  private buildList(r) {

    if (r.content) {
      r.content.forEach(element => {
        const value = String(element.valor);

        element.data_confirmacao = FormateDate.formateDataDDMMAAByString(element.data_confirmacao);
        element.valor = Number(value.substring(0, value.length - 2));

        this.values.push( element );
       });
    }
  }

  private buildParametros(): void {
    this.service.params = {
      page: this.pagination.page,
      size: this.pagination.size,
      status: 'CONFIRMADO',
      checkin: 1
    };
  }

  private loadCamposTable() {
  this.cols = [
      { field: 'nome_prestador', header: 'nome_prestador' },
      { field: 'nome_medico', header: 'nome_medico' },
      { field: 'nome_cliente', header: 'nome_cliente' },
      { field: 'data_confirmacao', header: 'Data confirmação' },
      { field: 'hora_inicio', header: 'Hora' },
      { field: 'forma_pagamento', header: 'Forma de pagamento' }
    ];
  }

  ngOnInit() {
    this.loadCamposTable();
    this.load();
    Util.dataTable();
  }

  OnDestroy() {
    this.service.key = '';
  }

}
