import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Cities } from 'src/app/interfaces/cities';
import { States } from 'src/app/interfaces/states';
import { Providers, ProvidersCallback } from 'src/app/interfaces/providers';
import { CardRedeParceiros } from 'src/app/interfaces/card-rede-parceiros';
import { Specialities } from 'src/app/interfaces/specialities';
import { SpecialitiesCallback } from 'src/app/interfaces/specialities-callback';
import { ApiService } from 'src/app/service/api.service';
import { LocationService } from 'src/app/service/location.service';
import { ProviderService } from 'src/app/service/provider.service';
import { SpecialitiesService } from 'src/app/service/specialities.service';
import { StorageUtil } from 'src/app/utils/storage';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { SpecialitiesTypes } from 'src/app/interfaces/specialities-types';
import { SpecialitiesTypesCallback } from 'src/app/interfaces/specialities-types-callback';
import { Spinner } from 'src/app/utils/stpiner';
import { UserService } from 'src/app/service/user.service';
import * as internal from 'assert';
import { MatPaginator } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { EpharmaService } from 'src/app/service/epharma.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
@Component({
  selector: 'app-rede-parceiros-new',
  templateUrl: './rede-parceiros-new.component.html',
  styleUrls: ['./rede-parceiros-new.component.css']
})

export class RedeParceirosNewComponent implements OnInit {

  //Pagination
  @ViewChild(MatPaginator, { static: true }) pagination: MatPaginator;
  paginationPageSizeOptions = [10, 20, 50];
  paginationPageSize = 10;
  paginationTotalElements;

  // Listas
  states: States[] = [];
  citiesList: Cities[] = []; // Lista de todas as cidades do estado selecionado
  citiesFiltered: string[] = []; // Lista das cidades após filtragem - suggestions
  specialitiesList: Specialities[] = []; // Lista de todas as especialidades
  providersList: Providers[] = [];
  cardsList: CardRedeParceiros[] = [];
  cardsShow: CardRedeParceiros[] = [];

  specialitiesTypesList: SpecialitiesTypes[] = [];

  // Selecionados
  stateSelected: string;
  citySelected: string;
  specialitiesSelected: string;

  specialitiesTypeSelected: SpecialitiesTypes;

  // Paginação
  //pagination = { size: 10, page: 0 };
  //page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };

  // Regra de negócio
  showButtons = { enable: false, messageUnauthorized: '' };

  // animation digit
  duration = 1000;
  @ViewChild('animatedDigit', { static: true }) animatedDigit: ElementRef;

  customer$: Observable<any>;

   // Check User Data is Complete
   userDataSubscription: Subscription;
   userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  constructor(private service: ApiService, private specialitiesService: SpecialitiesService, private providerService: ProviderService, private locationService: LocationService, private router: Router, private userService: UserService, private userDataModalService: UserDataModalService, private epharmaService: EpharmaService) { }

  ngOnInit() {
    this.customer$ = this.userService.getCurrentUser();
    this.paginationFirstPage();
    this.statesLoad();
    this.loadUserDataSubscription();
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  activeEpharma() {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);
      this.epharmaService.active(this.userService.currentUserValue.customer.id).subscribe(async (r: any) => {
        MsgUtil.msgSuccessToast('Você já pode utilizar o seu benefício em farmácias.');
        await this.userService.updateSubject();
      }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro ativação do seu benefício em farmácias. Por favor, tente novamente mais tarde.'),
        () => { Spinner.spinner(document); }
      );
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  private statesLoad() {
    this.locationService.getStates().subscribe((state: States[]) => {
      this.states = state;
      // Ordenar
      this.states.sort(function (a, b) {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });
    });
  }

  public selectState() {
    this.citySelected = null;
    this.resetCity();
    //this.resetPagination();
    this.paginationFirstPage();
    this.resetResult();
    this.resetSpecialities();
    this.resetSpecialitiesType();
    this.citiesLoad();
  }

  public citiesLoad() {
    Spinner.spinner(document, true);
    this.citiesList = [];
    this.locationService.getCities(this.stateSelected).subscribe((cities: Cities[]) => {
      this.citiesList = cities;
    }, err => {
      MsgUtil.msgErrorToast('Houve um erro carregar as cidades. Tente novamente mais tarde.');
    }, () => Spinner.spinner(document));
  }

  public citiesFilter(event) {
    this.citiesFiltered = [];
    let cities: Cities;
    for (cities of this.citiesList) {
      const brand: any = cities.nome;
      if (brand !== undefined) {
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          const c = this.citiesFiltered.findIndex((option) => option === brand);
          if (c < 0) {
            this.citiesFiltered.push(brand);
          }
        }
      }
    }
  }

  public citySelect() {
    // this.resetPagination();
    this.paginationFirstPage();
    this.resetResult();
    this.resetSpecialities();
    this.resetSpecialitiesType();
  }

  search() {
    if (!this.stateSelected) {
      MsgUtil.msgErrorToast('O estado é obrigatório');
      return;
    }
    this.specialitiesTypesLoad();
  }

  specialitiesTypesLoad() {
    Spinner.spinner(document, true);
    this.specialitiesService.getSpecialitiesTypes().subscribe((specialitiesTypes: SpecialitiesTypesCallback) => {
      this.specialitiesTypesList = specialitiesTypes.content;
      this.specialitiesTypesList.map(e => e._IMAGE = this.specialitiesTypesIcon(e.name));
      this.specialitiesTypesList.map(e => e._TYPE = 'SPECIALITIES_TYPE');
      this.providersTypesLoad();
    }, err => {
      MsgUtil.msgErrorToast('Houve um erro ao carregar os tipos de especialidade. Tente novamente mais tarde.');
    }, () => Spinner.spinner(document));
  }

  providersTypesLoad() {
    Spinner.spinner(document, true);
    this.providerService.getProvidersTypes().subscribe((providersTypes: SpecialitiesTypesCallback) => {
      providersTypes.content = providersTypes.content.filter(e => e.name !== 'CLINICAS');
      providersTypes.content.map(e => e._IMAGE = this.specialitiesTypesIcon(e.name));
      providersTypes.content.map(e => e._TYPE = 'PROVIDERS_TYPE');
      this.specialitiesTypesList = this.specialitiesTypesList.concat(providersTypes.content);
      this.hardCodeSpecialitiesType(); // HARD CODE
    }, err => {
      MsgUtil.msgErrorToast('Houve um erro ao carregar os tipos de especialidade. Tente novamente mais tarde.');
    }, () => Spinner.spinner(document));
  }

  specialitiesTypesIcon(name) {
    let image: string;
    switch (name) {
      case 'CONSULTA':
        image = 'consultas.png';
        break;

      case 'EXAME':
        image = 'exames.png';
        break;

      case 'HOSPITAIS':
        image = 'hospital.png';
        break;

      case 'FARMACIAS':
        image = 'farmacia.png';
        break;

      case 'ACADEMIAS':
        image = 'bem_estar.png';
        break;

      case 'OUTROS_PARCEIROS':
        image = 'outros.png';
        break;

      default:
        image = 'outros.png';
        break;
    }
    return image;
  }

  public clearFilter() {
    this.resetState();
    this.resetCity();
    // this.resetPagination();
    this.paginationFirstPage();
    this.resetResult();
    this.resetSpecialities();
    this.resetSpecialitiesType();
    this.citiesLoad();
  }

  public specialitiesTypeSelect(s: SpecialitiesTypes) {
    this.specialitiesTypeSelected = s;
    // this.resetPagination();
    this.paginationFirstPage();
    this.resetSpecialities();
    this.resetResult();
    this.specialitiesLoad();
    this.providersLoad();
  }

  public specialitiesLoad() {
    // REFATORAR, ESSA REGRA DEVERIA SER PASSADA COMO FILTRO PARA O SERVICE
    if (this.specialitiesTypeSelected.name === 'CONSULTA') {
      Spinner.spinner(document, true);
      const size = 99999; // ALTERAR PARA O PARAMETRO UNPAGED QUANDO O MESMO ESTIVER FUNCIONANDO No BACKEND
      this.specialitiesService.getSpecialities(size).subscribe((r: SpecialitiesCallback) => {
        this.specialitiesList = r.content;

        // Ordenar
        this.specialitiesList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }, err => {
        MsgUtil.msgErrorToast('Houve um erro ao carregar as especialidades. Por favor tente novamente mais tarde!');
      }, () => Spinner.spinner(document));
    }
  }

  public specialitiesSelect(i: Specialities) {
    if (i.name === this.specialitiesSelected) {
      this.specialitiesSelected = null;
    } else {
      this.specialitiesSelected = i.name;
    }
    // this.resetPagination();
    this.paginationFirstPage();
    this.providersLoad();
  }

  public providersLoad() {
    Spinner.spinner(document, true);
    //    this.providerService.getProviders(this.pagination.page, this.pagination.size, this.specialitiesTypeSelected, this.specialitiesSelected, this.stateSelected, this.citySelected).subscribe((providers: ProvidersCallback) => {
    this.providerService.getProviders(null, null, this.specialitiesTypeSelected, this.specialitiesSelected, this.stateSelected, this.citySelected).subscribe((providers: Providers[]) => {
      /* this.page.number = providers.number;
      this.page.size = providers.size;
      this.page.totalElements = providers.totalElements;
      this.page.totalPages = providers.totalPages;
      this.pagination.page = this.page.number;
      this.pagination.size = this.page.size; */
      providers = this.hardCodeProviders(providers); // HARD CODE
      this.providersList = providers;
      this.buildCard(providers);
      this.paginationMapping(providers.length);
    }, err => {
      MsgUtil.msgErrorToast('Houve um erro ao carregar a lista dos prestadores. Por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  private buildCard(providers: Providers[]) {
    const card: CardRedeParceiros[] = [];

    console.log('PROVIDERS', providers);

    for (const provider of providers) {

      let description;
      if (provider.description) {
        description = provider.description;
      } else {
        description = null;
        if (provider.specialities.length > 0) {
          description = provider.specialities.map(e => e.speciality.name).join(', ') + '.';
        }
      }

      let address = null;
      if (provider.address) {
        address = provider.address + ', ' + provider.addressCity + ' - ' + provider.addressState + ', ' + provider.addressZipNumber;
      }

      let urlMap = null;
      if (provider.address) {
        urlMap = 'http://maps.google.com/?q=' + address;
      }

      let isScheduled = false;
      if (this.specialitiesTypeSelected.name === 'CONSULTA') {
        isScheduled = true;
      }

      card.push({
        id: provider.id,
        providerName: provider.name,
        address,
        phone: provider.contactNumber,
        benefits: provider.benefits,
        description,
        urlMap,
        onlyTopPlan: false,
        isScheduled,
        provider,
        links: provider.links,
        image: provider.image,
        whatsapp: provider.whatsapp
      });

    }

    this.cardsList = card;
    this.setEnableButtons();
    console.log('CARDS', this.cardsList);
    this.paginationFront();
  }

  setEnableButtons() {

    const messageQualquerPlano = 'Esse benefício poderá somente ser usufruído após a assinatura de <strong>qualquer Plano Doutorpass.</strong> Basta <a class="button_clique_aqui" href="' + environment.url_payment + '">clicar aqui</a> para conferir os planos.';
    const messagePlanoTop = 'Esse benefício poderá somente ser usufruído após a assinatura do <strong>Plano TOP.</strong> Para conferir os planos basta <a class="button_clique_aqui" href="' + environment.url_payment + '">clicar aqui</a> para escolher seu plano e começar a usar!';

    if (!StorageUtil.getUser()) {
      this.showButtons.enable = false;
      if (this.specialitiesTypeSelected.name === 'ACADEMIAS' || this.specialitiesTypeSelected.name === 'FARMACIAS') {
        this.showButtons.messageUnauthorized = messagePlanoTop;
      } else {
        this.showButtons.messageUnauthorized = messageQualquerPlano;
      }
    }

    if (StorageUtil.getUser() && this.userService.getPlanType() === 'BASICO') {
      this.showButtons.enable = true;
      if (this.specialitiesTypeSelected.name === 'ACADEMIAS' || this.specialitiesTypeSelected.name === 'FARMACIAS') {
        this.showButtons.enable = false;
        this.showButtons.messageUnauthorized = messagePlanoTop;
      }
    }

    if (StorageUtil.getUser() && this.userService.getPlanType() === 'TOP') {
      this.showButtons.enable = true;
      this.showButtons.messageUnauthorized = '';
    }

  }

  public goAgendar(card: CardRedeParceiros): void {
    StorageUtil.goAgendamento(card.provider);
    this.router.navigate(['/agendamentos/']);
  }

  /*
  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.providersLoad();
    }
  }

  public firstPage(): void {
    this.pagination.page = 0;
    this.providersLoad();
  }

  public lastPage(): void {
    this.pagination.page = this.page.totalPages - 1;
    this.providersLoad();
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.providersLoad();
    }
  }

  private resetPagination() {
    this.pagination.page = 0;
    this.pagination.size = 10;
  } */

  private resetState() {
    this.stateSelected = null;
  }

  private resetCity() {
    this.citiesList = [];
    this.citySelected = null;
  }

  private resetSpecialitiesType() {
    this.specialitiesTypesList = [];
    this.specialitiesTypeSelected = null;
  }

  private resetSpecialities() {
    this.specialitiesList = [];
    this.specialitiesSelected = null;
  }

  private resetResult() {
    this.providersList = [];
    this.cardsList = [];
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  //Pagination
  paginationChangePage(event) {
    this.pagination.pageIndex = event.pageIndex;
    this.paginationFront();
  }
  paginationChangePageSize() {
    this.paginationFirstPage();
    this.paginationFront();
  }
  paginationMapping(totalElements) {
    this.paginationTotalElements = totalElements;
  }
  paginationFirstPage() {
    this.cardsShow = [];
    this.pagination.pageIndex = 0;
  }
  paginationFront() {
    this.cardsShow = [];
    let startLooping = Number(this.pagination.pageIndex) * Number(this.paginationPageSize);
    let endingLooping = Number(startLooping) + Number(this.paginationPageSize);
    console.log('this.pagination.pageIndex', Number(this.pagination.pageIndex));
    console.log('this.paginationPageSize', Number(this.paginationPageSize));
    console.log('inicio looping', startLooping);
    console.log('end looping', endingLooping);
    for (let i = startLooping; i < endingLooping; i++) {
      if (this.cardsList[i]) {
        this.cardsShow.push(this.cardsList[i]);
      }
    }
  }





  /* ############## HARD CODE ######################  */

  // Função criada para inserir manualmente o tipo de especialidade "ACADEMIAS". Quando este dado retornar da api, deve ser retirado da função providersTypesLoad()
  hardCodeSpecialitiesType() {

    /* this.specialitiesTypesList.push({
      id: 'ID_GENERICO',
      name: 'ACADEMIAS',
      _IMAGE: this.specialitiesTypesIcon('ACADEMIAS'),
      _TYPE: 'PROVIDERS_TYPE'
    }); */

    this.specialitiesTypesList.push({
      id: 'ID_OUTROS',
      name: 'OUTROS',
      _IMAGE: this.specialitiesTypesIcon('OUTROS'),
      _TYPE: 'PROVIDERS_TYPE'
    });
  }

  // Função criada para inserir manualmente telemedicina e smartfit. Quando estes dados retornarem da api, deve ser removido da função providersLoad()
  hardCodeProviders(content: Providers[]) {
    let newContent: Providers[] = [];

    // Telemedicina
    //if (this.specialitiesTypeSelected.name === 'CONSULTA' && this.pagination.page === 0) {
    if (this.specialitiesTypeSelected.name === 'CONSULTA') {
      newContent.push({
        address: null,
        addressCity: '',
        addressCountry: '',
        addressNeighborhood: '',
        addressNumber: '',
        addressState: '',
        addressZipNumber: '',
        contactNumber: '',
        id: 'ID_TELEMEDICINA',
        name: 'Médico online',
        providerTypeId: 'ID_PROVIDER',
        specialities: [
          {
            id: 'ID_SPECIALITIES',
            price: '',
            speciality: {
              description: '',
              id: 'ID_SPECIALITIES',
              name: ''
            },
            specialityType: {
              id: '',
              name: 'CONSULTA'
            }
          }
        ],
        links: [
          {
            label: 'Acessar Médico Online',
            url: '/#/clientes-medico-online'
          },
          {
            label: 'Saiba Mais',
            url: '/#/medico-online'
          }
        ],
        description: 'Médico On-line é uma plataforma de telemedicina no qual o cliente terá acesso a médicos altamente qualificados em duas modalidades de serviços.',
        image: 'assets/img/medicoonline.png'
      });
      //this.page.totalElements = this.page.totalElements + 1;
      this.paginationTotalElements = this.paginationTotalElements + 1;
    }

    // Smartfit
    //if (this.specialitiesTypeSelected.name === 'ACADEMIAS' && this.pagination.page === 0) {
    if (this.specialitiesTypeSelected.name === 'ACADEMIAS') {
      newContent.push({
        address: null,
        addressCity: '',
        addressCountry: '',
        addressNeighborhood: '',
        addressNumber: '',
        addressState: '',
        addressZipNumber: '',
        contactNumber: '1140001383',
        id: 'ID_SMARTFIT',
        name: 'SMARTFIT',
        providerTypeId: 'ID_PROVIDER_SMARTFIT',
        specialities: [
          {
            id: 'ID_SPECIALITIES_SMARTFIT',
            price: '',
            speciality: {
              description: '',
              id: 'ID_SPECIALITIES_SMARTFIT',
              name: ''
            },
            specialityType: {
              id: '',
              name: 'ACADEMIAS'
            }
          }
        ],
        description: '<ul><li>Isenção da taxa de adesão.</li><li>Isenção da primeira mensalidade.</li><li>Isenção da primeira manutenção anual.</li><li>Ligue para nossa central para obter o codigo de desconto.</li><p class="mt-3 text-right">* válido somente para novas contratações</p></ul>',
        benefits: 'Plano Smart e Black',
        whatsapp: '551132303925'
      });
      //this.page.totalElements = this.page.totalElements + 1;
      this.paginationTotalElements = this.paginationTotalElements + 1;
    }


    // V1 Aplicativo
    //if (this.specialitiesTypeSelected.name === 'OUTROS' && this.stateSelected === 'ES' && this.pagination.page === 0) {
    if (this.specialitiesTypeSelected.name === 'OUTROS' && this.stateSelected === 'ES') {
      newContent.push({
        address: null,
        addressCity: '',
        addressCountry: '',
        addressNeighborhood: '',
        addressNumber: '',
        addressState: '',
        addressZipNumber: '',
        contactNumber: '',
        id: 'ID_V1_APLICATIVO',
        name: 'Cliente Doutorpass, agora tem descontos na V1 Aplicativo de Transporte!',
        providerTypeId: 'ID_PROVIDER_V1',
        specialities: [
          {
            id: 'ID_SPECIALITIES_V1',
            price: '',
            speciality: {
              description: '',
              id: 'ID_SPECIALITIES_V1',
              name: ''
            },
            specialityType: {
              id: '',
              name: 'OUTROS'
            }
          }
        ],
        links: [],
        description: 'Com motoristas profissionais e frota própria.<br /><strong>Utilize o código DOUTORPASSV1 quando for solicitar uma viagem no aplicativo e ganhe 25% de desconto.</strong>',
        image: 'assets/img/v1.png'
      });
      //this.page.totalElements = this.page.totalElements + 1;
      this.paginationTotalElements = this.paginationTotalElements + 1;
    }

    newContent = newContent.concat(content);
    return newContent;
  }

  /* ############## HARD CODE ######################  */

}



