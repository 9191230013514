import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-redirect',
  templateUrl: './test-redirect.component.html',
  styleUrls: ['./test-redirect.component.css']
})
export class TestRedirectComponent implements OnInit {

  url = 'http://dr-pass-payment.drpassweb-hml.svc/';

  constructor() { }

  ngOnInit() {
  }

}
