import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Config } from 'src/app/cliente/perfil-cliente/request';
import { ApiService } from 'src/app/service/api.service';
import { StorageUtil } from 'src/app/utils/storage';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { HttpErrorResponse } from '@angular/common/http';

declare var $;

@Component({
  selector: 'app-prestador-configuracoes',
  templateUrl: './prestador-configuracoes.component.html',
  styleUrls: ['./prestador-configuracoes.component.css']
})
export class PrestadorConfiguracoesComponent implements OnInit {

  objEdit: any = '';
  configFormControl: FormGroup;
  senhaAtual: any = '';
  novaSenha = '';
  repetirSenha = '';
  senhaIvalida = true;
  img = 'assets/img/avatar.jpg';

  constructor( private service: ApiService, private formBuilder: FormBuilder, ) {
  }

  private loadFromGroup(): void {
    this.configFormControl = this.formBuilder.group({
      login: [''],
      senha: [''],
      nome: [''],
    });
  }
  public loadUserById(): void {

        this.service.getById( StorageUtil.getUser().id, 'USUARIO' )
                                          .subscribe((r: any) => {
                                              this.objEdit = r;
                                          }) ;
  }

  public login() {
    if ( this.senhaAtual === '') {
        return;
    }

    this.service.path = 'login';
    this.service.post({login: this.objEdit.login, senha: this.senhaAtual}, 'USUARIO').subscribe((r) => {

          this.senhaIvalida = false;
          MsgUtil.msgInfoToast('Digite a nova senha');

    }, (error: HttpErrorResponse) => {
           MsgUtil.msgErrorToast(error.error.messages[0].message);
    });
  }

  public update() {
    /* if( this.configFormControl.invalid ){
      MsgUtil.msgInfoToast('Todos os campos são obrigatórios');
        return
    } */

    this.validateNewSenha();

    this.service.path = StorageUtil.getUser().id;
    this.configFormControl.value.senha = this.novaSenha;
    this.service.put( this.configFormControl.value, 'USUARIO' ).subscribe((r) => {
      this.objEdit = r;
      $('.onSucess').click();
     }, (error: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(error.error.messages[0].mensagem);
    });
  }

  public validateNewSenha() {

    if (!this.senhaIvalida) {
      if (this.novaSenha === '' || this.repetirSenha === '') {
        MsgUtil.msgErrorToast('Preencha a nova senha para continuar');
        throw new EvalError();
     }

      if (this.novaSenha !== this.repetirSenha) {
         MsgUtil.msgErrorToast('As senhas devem ser iguas');
         throw new EvalError();
      }
    }
  }

  /* converter base64 */

  public setImage(event): void {

    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(event.target.files[0]);
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
   /*  this.objEdit.foto = 'data:image/jpeg;base64,' + btoa(binaryString); */
  }

  ngOnInit() {
    this.loadFromGroup();
    this.loadUserById();
  }

  OnDestroy() {
    this.service.path = '';
    this.service.key = '';
  }

}
