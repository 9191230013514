import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UriService } from './uri-service.service';

@Injectable({
  providedIn: 'root'
})
export class HttpUtil {

  constructor(private uriService: UriService) { }

  public buildUrl(key: any): any {
    return environment.urlBase + this.uriService.urlValue(key);
  }

  public buildLocation(path: any): any {
    return `${environment.urlLocation}${path}`;
  }

  public buildUrlGeonames(key: any, path?): any {

    if (path === undefined) {
     return  `${environment.urlBaseGeonames}${this.uriService.urlValue(key)}`;
    }

    return `${environment.urlBaseGeonames}${this.uriService.urlValue(key)}${path}`;
  }

  public buildUrlAdmin(key: any): any {
    return environment.urlBaseAdmin + this.uriService.urlValue(key);
  }

  public headerEmail(token): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      Authorization: environment.emailToken,
      'Api-Version': 'v3'
    };
  }

  public buildSMS(key: any): any {
    return environment.sms + this.uriService.urlValue(key);
  }

  public header(token): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      'Content-Type': 'application/json',
    };
  }

  public headerTLMedicina(token, tipoAtendimento): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      tipoAtendimento,
      'Content-Type': 'application/json',
      Authorization: 'Bearer access_token'
    };
  }

  public headerRecuperarSenha(token, login): any {
    return {
      client_id: environment.client_id,
      access_token: token,
      'Content-Type': 'application/json',
      login
    };
  }

  /* Header gateway */

  public buildUrlOauth(key: any): any {
    return environment.urlBaseOauth + this.uriService.urlValue(key);
  }

  public headerOauth(): any {
    return {
      client_id: environment.client_id,
      access_token: '',
      'Content-Type': 'application/json'
    };
  }
}
