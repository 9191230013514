import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageUtil } from 'src/app/utils/storage';

@Component({
  selector: 'app-einstein-conecta',
  templateUrl: './einstein-conecta.component.html',
  styleUrls: ['./einstein-conecta.component.css']
})
export class EinsteinConectaComponent implements OnInit {

  url = environment.url_payment;
  isLogged = StorageUtil.getJwt();

  constructor(public router: Router) { }

  ngOnInit() {}

  smoothScroll($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
