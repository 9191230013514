import { PlanOutput } from "./../../service/outputs/plan.output";
import { UserService } from "./../../service/user.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "src/app/modals/confirm/confirm-modal.component";
import { SubscriptionResourceService } from "src/app/service/subscription-resource.service";
import { MsgUtil } from "src/app/utils/msgUtil";
import { StorageUtil } from "src/app/utils/storage";
import { Router } from "@angular/router";
import { NgZone } from "@angular/core";
import { Plan } from "src/app/interfaces/plan";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { UserDataModalService } from "src/app/service/user-data-modal.service";

// Planos ainda nao dinamizados
@Component({
  selector: "app-alterar-plano",
  templateUrl: "./alterar-plano.component.html",
  styleUrls: ["./alterar-plano.component.css"],
})
export class AlterarPlanoComponent implements OnInit {
  plan = PlanOutput;
  currentPlanType: string;
  currentPlan: Plan;
  removeFreePlan = environment.removeFreePlan;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  constructor(
    private ngbModal: NgbModal,
    private subscriptionResourceService: SubscriptionResourceService,
    private userService: UserService,
    private router: Router,
    private ngZone: NgZone,
    private userDataModalService: UserDataModalService,
  ) {}

  ngOnInit() {
    this.loadUserDataSubscription();
    this.currentPlan = this.userService.getPlan();
    this.currentPlanType = this.userService.getPlanType();
  }

  public downgradePlan(): void {
    if (this.currentPlanType === this.plan.TOP) {
      this.checkUserDataOnDowngrade();
    } else {
      MsgUtil.msgInfoToast("Você já faz parte do " + this.currentPlan.name);
    }
  }

  public upgradePlan(): void {
    if (this.currentPlanType === this.plan.BASICO) {
      this.router.navigateByUrl("/plano-upgrade");
    } else {
      MsgUtil.msgInfoToast("Você já faz parte do " + this.currentPlan.name);
    }
  }

  checkUserDataOnDowngrade() {
    if (this.userDataIsComplete) {
      this.openModalConfirm();
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  private openModalConfirm(): void {
    const confirmNgbModalRef: NgbModalRef = this.ngbModal.open(
      ConfirmModalComponent
    );
    confirmNgbModalRef.componentInstance.title = "Alteração de plano";

    // refatorar
    if (this.currentPlan.name === "Plano top") {
      confirmNgbModalRef.componentInstance.message =
        "Tem certeza que deseja cancelar o seu plano?";
    } else {
      confirmNgbModalRef.componentInstance.message =
        "Ao realizar alteração para o Plano Básico, você não poderá mais retornar para o plano " +
        this.currentPlan.name +
        ".<br /><br />Tem certeza que deseja cancelar o seu plano?";
    }

    confirmNgbModalRef.componentInstance.confirm.subscribe(
      async (response: boolean) => {
        await this.onConfirmDowngradePlan(response, confirmNgbModalRef);
      }
    );
  }

  private async onConfirmDowngradePlan(
    response: boolean,
    confirmNgbModalRef: NgbModalRef
  ): Promise<void> {
    if (response) {
      confirmNgbModalRef.componentInstance.showLoading();
      await this.executeConfirmationDowngradePlanAsync();
    }

    confirmNgbModalRef.componentInstance.hideLoading();
    confirmNgbModalRef.close();
  }

  private async executeConfirmationDowngradePlanAsync(): Promise<void> {
    const user: any = StorageUtil.getUser();

    await this.subscriptionResourceService
      .downgradeAsync(user.subscription.id)
      .then((response: boolean) => {
        if (response) {
          MsgUtil.msgSuccessToast("Plano alterado com sucesso.");
        }
      })
      .catch((erro: any) => {
        MsgUtil.msgErrorToast(
          "Ocorreu um erro na atualização de plano. Por favor, tente novamente mais tarde."
        );
      });

    await this.userService.updateSubject();
    this.ngZone.run(() => {
      this.router.navigate(["/clientes-perfil"]);
    });
  }
}
