import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Headers } from '../interfaces/headers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageUtil } from '../utils/storage';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getFaq(question?: string) {
    let paramQuestion = '';
    if (question) {
      paramQuestion = 'question=' + question;
    }
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/faq?${paramQuestion}`, requestOptions);
  }

}
