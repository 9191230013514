import { PagamentoRetorno } from './../../../interfaces/pagamentoRetorno';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { Plan } from 'src/app/interfaces/plan';
import { UserCallback } from 'src/app/interfaces/user-callback';
import { PlanService } from 'src/app/service/plan.service';
import { UserService } from 'src/app/service/user.service';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { SubscriptionPurchase } from 'src/app/interfaces/subscription-purchase';
import { StorageUtil } from 'src/app/utils/storage';
import { SubscriptionBarcodeInterface } from 'src/app/interfaces/subscription-barcode';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';

@Component({
  selector: 'app-pagamento-regra',
  templateUrl: './pagamento-regra.component.html',
  styleUrls: ['./pagamento-regra.component.css']
})
export class PagamentoRegraComponent implements OnInit {

  data: UserCallback;
  plano: Plan;

  tipoPessoa: 'PJ' | 'PF';
  subsId: string;

  social = '';

  habilitaComponenteCartao = false;


  // Dados Componente Pagamento
  cartaoQuantidadeParcelas = 6;
  cartaoValor: number;
  boletoValor: number;
  boletoVencimento: string;

  // Componente Cartão
  habilitaComponenteCartaoToken = false; // Habilita Componente Cartão Token
  qtdParcelas;
  cartaoNumero;
  cartaoNome;
  cartaoValidade;
  cartaoCodigo;
  cartaoToken;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  tipoPagamento: 'CARTAO' | 'BOLETO' = 'CARTAO';

  constructor(
    private planService: PlanService,
    private route: ActivatedRoute,
    private userService: UserService,
    private ngZone: NgZone,
    private router: Router,
    private userDataModalService: UserDataModalService
  ) { }

  ngOnInit() {
    this.loadUserDataSubscription();
    this.loadData();
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
      if (!userDataIsComplete) {
        this.ngZone.run(() => {
          MsgUtil.msgErrorToast('Para prosseguir com o pagamento, é obrigatório preencher os campos.');
          this.router.navigate(['/alterar-plano']);
        });
        return;
      }
    });
    this.userDataModalService.checkUserDataInitial(true);
  }




  /***************** REGRAS DE CARREGAMENTO DOS VALORES DE CARTÃO ***************************/

  // Regra de negócio para pessoa física que acabou de se cadastrar
  private loadData() {
    this.tipoPessoa = 'PF';
    const groupedResponse = zip(this.userService.getCurrentUser(), this.planService.getPlan('Plano top'));
    groupedResponse.subscribe(res => {
      this.data = res[0] as UserCallback;
      this.plano = res[1] as Plan;
      const cartaoValor = Number(this.plano.price) * this.cartaoQuantidadeParcelas;
      const boletoValor = Number(this.plano.price) * this.cartaoQuantidadeParcelas;
      const boletoVencimento = this.loadDateExpiration();
      this.gerarValoresPagamento(cartaoValor, boletoValor, boletoVencimento);
    }, err => MsgUtil.msgErrorToast('Houve um erro ao carregar, por favor tente novamente mais tarde...'),
      () => { Spinner.spinner(document); }
    );
  }

  private gerarValoresPagamento(cartaoValor?, boletoValor?, boletoVencimento?) {
    this.cartaoValor = cartaoValor;
    this.boletoValor = boletoValor;
    this.boletoVencimento = boletoVencimento;
    this.habilitaComponenteCartao = true;
  }

  /***************** REGRAS DE CARREGAMENTO DOS VALORES DE CARTÃO ***************************/

  setTipoPagamento(event) {
    this.tipoPagamento = event;
  }

  pagamentoEvento(event: PagamentoRetorno) {
    if (event.pagamentoSelecionado === 'CARTAO') {
      this.processaDadosCartao(event);
      return;
    }

    if (event.pagamentoSelecionado === 'BOLETO') {
      this.cartaoToken = null;
      this.finalizaPagamento();
      return;
    }
  }

  /***************** REGRAS DE COMUNICAÇÃO ENTRE O COMPONENTE DO TOKEN IUGU ***************************/
  processaDadosCartao(event: PagamentoRetorno) {
    this.cartaoNumero = event.number;
    this.cartaoNome = event.name;
    this.cartaoCodigo = event.cvc;
    this.cartaoValidade = event.expiry;
    this.qtdParcelas = event.parcela;
    this.habilitaComponenteCartaoToken = true;
  }

  recebeTokenIugu(event) {
    this.habilitaComponenteCartaoToken = false;
    if (event !== 'ERRO') {
      console.log('recebendo token iugu', event);
      this.cartaoToken = event;
      this.finalizaPagamento();
    } else {
      MsgUtil.msgErrorToast('O cartão não é válido! Por favor, confira os dados inseridos e tente novamente.');
    }
  }
  /***************** REGRAS DE COMUNICAÇÃO ENTRE O COMPONENTE DO TOKEN IUGU ***************************/



  /***************** REGRAS DE PAGAMENTO ***************************/

  // Redireciona para finalização correta
  finalizaPagamento() {
    if (this.tipoPessoa === 'PF') { this.finalizaPessoaFisica(); }
  }

  // Regra de negócio para pessoa física que acabou de se cadastrar, ou que veio do ADMIN Ou PARCEIRO DE VENDAS
  finalizaPessoaFisica() {
    Spinner.spinner(document, true);
    let payload: SubscriptionPurchase;
    if (!this.cartaoToken) {
      payload = {
        customerId: this.data.customer.id,
        paymentMethod: 'BANK_SLIP',
        planId: this.plano.id
      };
    } else {
      payload = {
        cardToken: this.cartaoToken,
        customerId: this.data.customer.id,
        month: Number(this.qtdParcelas),
        paymentMethod: 'CREDIT_CARD',
        planId: this.plano.id
      };
    }

    this.planService.setPlanToCustomer(payload).subscribe(async (r: any) => {
      await this.userService.updateSubject();
      if (!this.cartaoToken && r.subscription.pdf) {
        // get barcode
        this.planService.getSubscriptionBarcode(r.subscription.id).subscribe((s: SubscriptionBarcodeInterface) => {
          this.navigateSuccess(r.subscription.pdf, s.bankSlip.barcode);
        }, () => {
          this.navigateSuccess(r.subscription.pdf, null);
        });
      } else {
        this.navigateSuccess();
      }
    }, () => {
      MsgUtil.msgErrorToast('Houve um erro ao processar o pagamento, por favor tente novamente mais tarde!');
    }, () => Spinner.spinner(document));
  }

  /***************** REGRAS DE PAGAMENTO ***************************/

  private navigateSuccess(bankSlipUrl?, bankSlipBarCode?) {
    this.ngZone.run(() => {
      this.router.navigate(['/plano-upgrade-success'], { queryParams: { tipoPagamento: this.tipoPagamento, bankSlipUrl, bankSlipBarCode } });
    });
  }


  // Helpers
  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    return FormateDate.formateData(date);
  }

}
