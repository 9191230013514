import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";


if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: "https://ce0c3a51966b4cee8886c377703c8cc0@o965274.ingest.sentry.io/5963253",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", environment.url_web],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // webhook - 02 dezembro de 2020
