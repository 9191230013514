import { ScheduleOnlineService } from './../../service/schedule-online';
import { ErrorReturnUtil } from './../../utils/errorReturnUtil';
import { ScheduleService } from './../../service/schedule.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { StorageUtil } from 'src/app/utils/storage';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { UserService } from 'src/app/service/user.service';
import { Schedules } from 'src/app/interfaces/schedules';
import { UserData } from 'src/app/interfaces/userData-interface';
import { SchedulesUserCallback } from 'src/app/interfaces/schedules-user-callback';
import { HttpErrorResponse } from '@angular/common/http';
import { Spinner } from 'src/app/utils/stpiner';
import { OnlineSchedule } from 'src/app/interfaces/online-schedules';
import { ClinicTypeInput } from 'src/app/service/inputs/clinic-type.input';
import { ClinicsMagicLinkOutput } from 'src/app/service/outputs/clinics-magic-link.output';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { Subscription } from 'src/app/interfaces/subscription';
import { ClinicsService } from 'src/app/service/clinics.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConhecaTopComponent } from '../medico-online/modals/conheca-top/conheca-top.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { ConfirmModalComponent } from 'src/app/modals/confirm/confirm-modal.component';
import { DatePipe } from '@angular/common';


declare var $;

enum statuses {
  PENDENTE = 'PENDENTE',
  CONFIRMADO = 'CONFIRMADO',
  CANCELADO = 'CANCELADO'
}

@Component({
  selector: 'app-meus-agendamentos',
  templateUrl: './meus-agendamentos.component.html',
  styleUrls: ['./meus-agendamentos.component.css'],
  /* animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        animate(
          500,
          keyframes([
            style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 })
          ])
        )
      ]),
      transition('* => void', [
        animate(
          500,
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
          ])
        )
      ])
    ])
  ] */
})
export class MeusAgendamentosComponent implements OnInit {

  agendamentos: any[] = [];
  agendamentosOnline: OnlineSchedule[];
  page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  pagination = { page: 0, size: 10 };
  id: any;
  today;
  cancelamento: Schedules;
  on = true;

  /* next = 0;
  nextOnline = 0;
  agendamentosFadein: any[] = [];
  agendamentosOnlineFadein: OnlineSchedule[] = []; */

  activate = 'active show';
  clinicTypeInput = ClinicTypeInput;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();



  constructor(
    private service: ApiService,
    public router: Router,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private scheduleOnline: ScheduleOnlineService,
    private userDataModalService: UserDataModalService,
    private clinicsService: ClinicsService,
    private ngbModal: NgbModal,
    private datePipe: DatePipe
    ) {
  }

  ngOnInit() {
    this.today = new Date().toISOString();
    this.loadAgendamentos();
    this.loadAgendamentosOnline();
    this.loadUserDataSubscription();
  }


  /* doNextOnline() {
    if (this.nextOnline < this.agendamentosOnline.length) {
      this.agendamentosOnlineFadein.push(this.agendamentosOnline[this.nextOnline++]);
    }
  }

  doNext() {
    if (this.next < this.agendamentos.length) {
      console.log('donext', this.agendamentos);
      this.agendamentosFadein.push(this.agendamentos[this.next++]);
    }
  } */

  private loadAgendamentos() {
  //  this.agendamentosOnline = [];
  //  this.agendamentosOnlineFadein = [];
    this.agendamentos = [];
    Spinner.spinner(document, true);
    // Get user
    this.userService.getCurrentUser().subscribe((userData: UserData) => {
      this.userService.getSchedulesByCustomer(userData.customer.id).subscribe((schedules: SchedulesUserCallback) => {
        this.page.number = schedules.pageable.pageNumber;
        this.page.number = schedules.number;
        this.page.size = schedules.size;
        this.page.totalElements = schedules.totalElements;
        this.page.totalPages = schedules.totalPages;
        this.agendamentos = schedules.content;
        // Spinner.spinner(document);
        // this.doNext();
      }, (error: HttpErrorResponse) => {
        ErrorReturnUtil.buildError(error);
      }, () => Spinner.spinner(document));
    });
  }

  private loadAgendamentosOnline() {
    this.agendamentos = [];
   // this.agendamentosFadein = [];
    Spinner.spinner(document, true);
    this.scheduleOnline.getOnlineAppointment().subscribe((data: OnlineSchedule[]) => {
     // Spinner.spinner(document);
      this.agendamentosOnline = data;
     // this.doNextOnline();
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  presencial() {
    if (this.on) {
      this.on = !this.on;
     // this.next = 0;
      this.loadAgendamentos();
    }
  }

  online() {
    if (!this.on) {
      this.on = !this.on;
      // this.nextOnline = 0;
      this.loadAgendamentosOnline();
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  redirect() {
    this.router.navigate(['/rede-parceiros']);
  }

  disable(status: string) {
    return status === statuses.CONFIRMADO || status === statuses.CANCELADO;
  }

  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.loadAgendamentos();
    }
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.loadAgendamentos();
    }
  }

  public itemPerPage(value): void {
    this.pagination.size = value;
    this.loadAgendamentos();
  }

  public dropDown(): void {
    $('.drop').click();
  }

  public goSmartCard(item): void {

    let date = '';
    let endereco = '';
    let especialidade = '';

    if (item.data_confirmacao) {
      date = FormateDate.formateDataDDMMAA(item.data_confirmacao) + ' às ' + item.horario_inicio + ' hs';
    }

    if (item.nome_especialidade) { especialidade = item.nome_especialidade; }

    if (item.endereco) { endereco = item.endereco; }

    window.open('https://mp.vibescm.com/c/jgrqjy/'
      + item.codigo + '?data[full_name]=' + item.nome_cliente
      + '&data[nome_especialiade]=' + especialidade
      + '&data[data_hora_consulta]=' + date
      + '&data[nome_prestador]=' + item.nome_medico
      + '&data[endereco_prestador]=' + endereco
      + '&data[valor_consulta]=' + item.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      + '&data[codigo_checkin]=' + item.codigo,
      '_blank');
  }

  public enviarSms(item) {
    this.service.path = item.codigo;
    this.service.sendSMS('EV_AGENDAMENTO').subscribe(() => {
      MsgUtil.msgSuccessToast('Enviado com sucesso');
    });
  }

  public getId(value) {
    this.id = value;
  }

  public cancelar() {
    this.service.path = this.id + '/cancelamentos';
    this.service.putAdmin(null, 'AGENDAMENTO')
      .subscribe((r) => {
        MsgUtil.msgSuccessToast('Agendamento cancelado com sucesso');
        this.loadAgendamentos();
      });
  }

  openModalCancelar(a: Schedules) {
    this.cancelamento = a;
  }

  iniciarAtendimento(clinicTypeInput: ClinicTypeInput, appointment: string) {
    Spinner.spinner(document, true);

    this.clinicsService.getMagicLinkAsync(clinicTypeInput, appointment).subscribe((clinicsMagicLinkOutput: ClinicsMagicLinkOutput) => {
        this.tratarRetornoAtedimentoComSucesso(clinicTypeInput, clinicsMagicLinkOutput);
      }, (error: HttpErrorResponse) => {
        if (error.status === 412) {
          console.log('ERROR', error);
          this.exibirModalParaUpgradeDePlano();
          return;
        }
        MsgUtil.msgErrorToast('Ocorreu um erro ao tentar iniciar o atendimento. Por favor, tente novamente mais tarde.');
      }, () => Spinner.spinner(document));
  }

  cancelarOnline(appID) {
    Spinner.spinner(document, true);
    this.scheduleOnline.cancelOnlineAppointment(appID).subscribe((data) => {
      console.log(data);
      MsgUtil.msgSuccessToast('Agendamento cancelado com sucesso!');
      this.loadAgendamentosOnline();
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  cancelarAgendamento() {
    Spinner.spinner(document, true);
    this.scheduleService.cancelSchedule(this.cancelamento.id).subscribe(() => {
      MsgUtil.msgSuccessToast('Agendamento cancelado com sucesso!');
      this.loadAgendamentos();
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  public iniciarAgendamento(clinicTypeInput: ClinicTypeInput) {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);

      this.clinicsService.getMagicLinkAsync(clinicTypeInput).subscribe((clinicsMagicLinkOutput: ClinicsMagicLinkOutput) => {
        this.tratarRetornoAtedimentoComSucesso(clinicTypeInput, clinicsMagicLinkOutput);
      }, (error: HttpErrorResponse) => {
        if (error.status === 412) {
          console.log('ERROR', error);
          this.exibirModalParaUpgradeDePlano();
          return;
        }
        MsgUtil.msgErrorToast('Ocorreu um erro ao tentar iniciar o atendimento. Por favor, tente novamente mais tarde.');
      }, () => Spinner.spinner(document));

    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  private tratarRetornoAtedimentoComSucesso(clinicTypeInput: ClinicTypeInput, clinicsMagicLinkOutput: ClinicsMagicLinkOutput): void {
    console.log('tratarRetornoAtedimentoComSucesso');
    if (clinicsMagicLinkOutput != null) {
      window.open(clinicsMagicLinkOutput.magicLinkWeb, '_blank');
    }
  }

  openModalConfirm(id: string): void {
    const confirmNgbModalRef: NgbModalRef = this.ngbModal.open(ConfirmModalComponent);
    confirmNgbModalRef.componentInstance.title = 'Cancelamento';


    confirmNgbModalRef.componentInstance.message = 'Tem certeza que deseja cancelar a sua consulta?';


    confirmNgbModalRef.componentInstance.confirm.subscribe(async (response: boolean) => {
      if (response) {
        confirmNgbModalRef.close();
        this.cancelarOnline(id);
      } else {
        console.log('FECHOU');
        confirmNgbModalRef.close();
      }
    });
  }

  status(status: string) {
    if (status === statuses.PENDENTE) {
      return 'label-pre';
    }
    if (status === statuses.CONFIRMADO) {
      return 'label-confirmed';
    }
    if (status === statuses.CANCELADO) {
      return 'label-canceled';
    }
}

  loadUserDataSubscription() {
      this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
      this.userDataModalService.checkUserDataInitial(false);
  }

  private exibirModalParaUpgradeDePlano(): void {
    const resultModal = this.ngbModal.open(ConhecaTopComponent);
    resultModal.result.then(
      (data: any) => {
        if (data === ClinicTypeInput.EMERGENCY) {
          this.iniciarAgendamento(data);
        }
      },
      (reason: any) => { });
  }

  dateTrasform(date: any) {
    return date.split(' ')[0];
  }

  timeTransform(date: any) {
    return date.split(' ').pop();
  }


  OnDestroy() {
    this.service.path = '';
    this.service.key = '';
  }
}
