import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClinicsService } from '../../../../service/clinics.service';
import { ClinicTypeInput } from '../../../../service/inputs/clinic-type.input';
import { ClinicsMagicLinkOutput } from '../../../../service/outputs/clinics-magic-link.output';
import { StorageUtil } from '../../../../utils/storage';
import { Spinner } from '../../../../utils/stpiner';
import { MagicLinkOpenComponent } from '../magic-link-open/magic-link-open.component';

@Component({
  selector: 'app-upgrade-plano',
  templateUrl: './upgrade-plano.component.html',
  styleUrls: ['./upgrade-plano.component.css']
})
export class UpgradePlanoComponent {
  @Input()
  public uri: string;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    protected ngbModal: NgbModal,
    private clinicsService: ClinicsService
  ) { }

  private tratarRetornoAtedimentoComSucesso(clinicTypeInput: ClinicTypeInput, clinicsMagicLinkOutput: ClinicsMagicLinkOutput): void {
    if (clinicTypeInput === ClinicTypeInput.ELECTIVE && clinicsMagicLinkOutput != null) {
      this.exibirModalComLinkMagico(clinicsMagicLinkOutput.magicLinkWeb);
    }
  }

  private exibirModalComLinkMagico(uri: string): void {
    const confirmNgbModalRef: NgbModalRef = this.ngbModal.open(MagicLinkOpenComponent);
    confirmNgbModalRef.componentInstance.uri = uri;
  }

  private exibirModalParaUpgradeDePlano(): void {
    this.ngbModal.open(UpgradePlanoComponent);
  }

  public closeCurrentModal(eventoExecutadoComSucesso: boolean): void {
    this.ngbActiveModal.close(eventoExecutadoComSucesso);
  }

  public get usuarioNome(): string {
    const user: any = StorageUtil.getUser();
    return user.user.name;
  }
}
