import { UserService } from './../../../../service/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { Spinner } from 'src/app/utils/stpiner';

@Component({
  selector: 'app-modal-verifica-nome',
  templateUrl: './modal-verifica-nome.component.html',
  styleUrls: ['./modal-verifica-nome.component.css']
})
export class ModalVerificaNomeComponent implements OnInit {

  customerId;
  showModal = false;

  nameForm: FormGroup;
  nameFormsubmitted = false;

  constructor(private userService: UserService, private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.loadUsuarioLogado();
  }

  loadUsuarioLogado() {
    const name = this.userService.currentUserValue.user.name;
    this.customerId = this.userService.currentUserValue.customer.id;
    console.log('name', name);
    console.log('user full', this.userService.currentUserValue)
    if (name.indexOf('@') !== -1) {
      this.nameFormLoad();
      this.showModal = true;
    }
  }

  nameFormLoad() {
    // Profile Form
    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required],
    }, {
      //  validator: MustMatch('password', 'confirmPassword')
    });
  }

  get nameFormControl() {
    return this.nameForm.controls;
  }

  nameFormSubmit() {
    this.nameFormsubmitted = true;

    // stop here if form is invalid
    if (this.nameForm.invalid) {
      return;
    }

    console.log('form value', this.nameForm.getRawValue());
    Spinner.spinner(document, true);
    this.userService.registerCustomer(this.customerId, this.nameForm.getRawValue()).subscribe(async (r) => {
      await this.userService.updateSubject();
      MsgUtil.msgSuccessToast('Nome salvo com sucesso!');
      this.fecharModal();
    },
      err => ErrorReturnUtil.buildError(err),
      () => Spinner.spinner(document)
    );
  }

  fecharModal() {
    this.showModal = false;
  }

}
