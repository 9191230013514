import { Injectable } from '@angular/core';
import { Headers } from '../interfaces/headers';
import { StorageUtil } from './../utils/storage';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpecialitiesService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) {}

  getSpecialities(size): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    const paramPage = 'page=0' + '&' + 'size=' + size + '&';
    return this.http.get(`${environment.urlBase}open/specialities?` + paramPage, requestOptions);
  }

  getSpecialitiesTypes(): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/specialities/types`, requestOptions);
  }

}
