import { Injectable } from '@angular/core';
import { Headers } from '../interfaces/headers';
import { StorageUtil } from './../utils/storage';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SpecialitiesTypes } from '../interfaces/specialities-types';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getProviders(page, size, specialityType?: SpecialitiesTypes, specialityName?, addressState?, addressCity?): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };

    const paramPage = 'page=' + page + '&' + 'size=' + size + '&';
    const paramState = 'addressState=' + addressState + '&';
    const paramAddressCity = addressCity ? 'addressCity=' + addressCity + '&' : '';

    let paramSpecialityType;
    switch (specialityType._TYPE) {
      case 'SPECIALITIES_TYPE':
        paramSpecialityType = specialityType ? 'specialityType=' + specialityType.name + '&' : '';
        break;

      case 'PROVIDERS_TYPE':
        paramSpecialityType = specialityType ? 'providerTypeId=' + specialityType.id + '&' : '';
        break;
    }

    const paramSpecialityName = specialityName ? 'specialityName=' + specialityName + '&' : '';
    const params = paramPage + paramState + paramAddressCity + paramSpecialityType + paramSpecialityName;

    return this.http.get(`${environment.urlBase}open/services/providers?` + params, requestOptions);
  }

  getProvidersTypes(): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/services/providers/types`, requestOptions);
  }

}
