import { UserService } from './../../service/user.service';
import { ErrorReturnUtil } from './../../utils/errorReturnUtil';
import { Component, OnInit } from '@angular/core';
import { Spinner } from 'src/app/utils/stpiner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  email;
  success = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  enviar() {

    if (!this.email) {
      ErrorReturnUtil.buildError(null, 'O e-mail é obrigatório.');
      return;
    }

    const payload = {
      userName: this.email
    };

    Spinner.spinner(document, true);
    this.userService.resetPassword(payload).subscribe((r) => {
      this.success = true;
    }, err => {
      console.log(err);
      ErrorReturnUtil.buildError(err);
    }, () => Spinner.spinner(document));

  }

  goLogin() {
    window.location.href = '/login';
  }

}
