import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageUtil } from './../utils/storage';
import { SubscriptionPurchase } from '../interfaces/subscription-purchase';
import { Headers } from '../interfaces/headers';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getPlans() {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}plans`, requestOptions);
  }

  getPlan(name: string) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/find-plans?planName=` + name, requestOptions);
  }

  setPlanToCustomer(data: SubscriptionPurchase) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}subscriptions/purchase`, data, requestOptions);
  }

  getSubscriptionBarcode(subscriptionId: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}subscriptions/${subscriptionId}/payment`, requestOptions);
  }

}
