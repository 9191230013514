import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { of } from 'rxjs';
import { Headers } from 'src/app/interfaces/headers';
import { StorageUtil } from 'src/app/utils/storage';
import { environment } from './../../../environments/environment';

declare var gapi;
@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getAccessToken(code) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}social/GOOGLE/token?code=${code}&redirect_uri=${environment.google_redirect_uri}`, requestOptions);
  }

  loadGapi() {
    return new Promise((resolve, reject) => {
      gapi.load('client', () => {
        gapi.client.init({ client_id: environment.google_client_id }).then(() => {
          resolve(gapi.client);
        });
      });
    });
  }

  getUserData(accessToken): Observable<any> {
    return new Observable((observer) => {
       const op = this._request(accessToken);
       op.execute((resp) => {
           observer.next(resp);
           observer.complete();
       });
     });
  }

  _request(accessToken) {
    return gapi.client.request({
      path: `${environment.google_user_data_url}?access_token=${accessToken}&alt=json`,
    });
  }
}
