import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GetLocationService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getIpAddress() {
    return this.http
          .get('https://api.ipify.org/?format=json')
          .pipe(
            catchError(this.handleError)
          );
  }

  getGEOLocation(ip) {
  // api email: leonardonatal94@gmail.com : Platformbuilders
  const key = 'ipstack';
  const access = 'ea50b5764f801d888dc85c20c4d5ff07';
  const url = ip + '?access_key=' + access;
  // return this.http
  //         .get(url)
  //         .pipe(
  //           catchError(this.handleError)
  //         );
  return this.api.getLocation(key, url).pipe(
    catchError(this.handleError)
   );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
