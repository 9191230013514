import { ClinicTypeInput } from 'src/app/service/inputs/clinic-type.input';
import { Queue } from './../../../../interfaces/queue-callback';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicsService } from 'src/app/service/clinics.service';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-static-modal',
  templateUrl: './static-modal.component.html',
  // add NgbModalConfig and NgbModal to the component providers
  providers: [NgbModalConfig, NgbModal],
  styleUrls: ['./static-modal.component.css']
})

export class StaticModalComponent implements OnChanges {

  @Input() queue: Queue;
  @Input() clinicType: ClinicTypeInput;
  @ViewChild('content', { static: true }) content;
  @Output() public atendimento: EventEmitter<ClinicTypeInput> = new EventEmitter();


  isLoading = false;
  isOpenning = false;
  inNewTab = false;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private clinicsService: ClinicsService, private userService: UserService) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('estou aqui');
    console.log('changes.queue.currentValue', changes.queue.currentValue)
    console.log('changes.queue.firstChange', changes.queue.firstChange);
    console.log('this.inNewTab', this.inNewTab);

    if (changes.queue.currentValue || changes.queue.firstChange) {
      if (changes.queue.currentValue !== 'SEM_FILA') {
        if (!this.isOpenning) {
          this.modalService.open(this.content);
          this.isOpenning = true;
        }
        if (changes.queue.currentValue.posicaoFila === 1 && changes.queue.currentValue.tamanhoFila === 0 && !this.inNewTab) { this.playAudio(); }
      } else {
        this.resetComponent();
      }

    }
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../../../assets/audio/beep.wav';
    audio.load();
    audio.play();
  }

  goAppointment() {
    this.modalService.dismissAll();
    this.isOpenning = false;
    this.inNewTab = true;
    this.atendimento.emit(this.clinicType);
  }

  resetComponent() {
    this.modalService.dismissAll();
    this.isLoading = false;
    this.isOpenning = false;
    this.inNewTab = false;
  }

  cancelAppointment() {
    this.isLoading = true;
    console.log('tes', this.clinicType);
    if (this.clinicType) {
      const cpf = this.userService.currentUserValue.prospect.documentNumber;
      this.clinicsService.cancelAppointment(this.clinicType, cpf).subscribe((data) => {
        this.isLoading = false;
        MsgUtil.msgSuccessToast('Cancelado com sucesso');
        this.modalService.dismissAll();
        this.isOpenning = false;
      }, err => {
        this.isLoading = false;
        MsgUtil.msgErrorToast('Houve um erro ao cancelar, por favor tente novamente');
      });
    }
  }
}
