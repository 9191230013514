import { ClinicTypeInput } from 'src/app/service/inputs/clinic-type.input';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Headers } from '../interfaces/headers';
import { StorageUtil } from '../utils/storage';
import { ClinicsMagicLinkOutput } from './outputs/clinics-magic-link.output';

@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken(),
    clinicType: ''
  };

  constructor(private http: HttpClient) { }

  getMagicLinkAsync(clinicType: ClinicTypeInput, query?: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    this.headers.clinicType = clinicType.toString();

    let qry = '';
    if (query) {
      qry = '?appointmentId=' + query;
    }

    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}clinics/magic-link${qry}`, requestOptions);
  }


  queuePosition(clinicType: ClinicTypeInput, cpf: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    //this.headers.clinicType = clinicType.toString();

    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlConexa}clinica/${environment[clinicType.toString()]}/appointments/immediate/active/${cpf}`, requestOptions);
  }

  cancelAppointment(clinicType: ClinicTypeInput, cpf: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    //this.headers.clinicType = clinicType.toString();

    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.delete(`${environment.urlConexa}clinica/${environment[clinicType.toString()]}/appointments/immediate/${cpf}`, requestOptions);
  }



}
