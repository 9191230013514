declare var $;

export class Util {

   public static dataTable() {
    $(document).ready(function() {
        $('#example').DataTable();
    } );
   }

   public static defaulValueSelect( value: string ): boolean {

            if ( value.indexOf( 'Selecionar' ) > -1 ) {
                return true;
            }

            return false;
   }

   public static captalize( value ) {
    const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

    return capitalize(value);
   }

}
