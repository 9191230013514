export const environment = {
  production: false,
  environment: 'hml',
  client_id: '12075ed4-5a38-3e51-910f-0a6a2275e9d3',
  passWord: '09856cbc-cfcf-3392-95bd-01a35c36f6f1',
  urlBaseOauth: 'https://api-athenasaude.sensedia.com/homologacao/sandbox/oauth/',
  urlBase: 'https://api-athenasaude.sensedia.com/homologacao/drpass/v2/',
  urlLocation: 'https://api-athenasaude.sensedia.com/ipstack/v1/',
  urlBaseGeonames: 'https://api-athenasaude.sensedia.com/homologacao/geonames/v1/',
  urlBaseAdmin: 'https://api-athenasaude.sensedia.com/homologacao/drpass/administracao/v1/',
  emailToken: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImExNmM3N2M0ZDRlZDZlYTU1YzIyODU3ODllYjFmMjMyOWEwNjM1Y2MyZWNhN2RhZjcwZTc3MGFiYjM4OTU3OWVlNWNlMmE3ODA3Y2M2MDhkIn0.eyJhdWQiOiIxIiwianRpIjoiYTE2Yzc3YzRkNGVkNmVhNTVjMjI4NTc4OWViMWYyMzI5YTA2MzVjYzJlY2E3ZGFmNzBlNzcwYWJiMzg5NTc5ZWU1Y2UyYTc4MDdjYzYwOGQiLCJpYXQiOjE1OTAxNjQzNTAsIm5iZiI6MTU5MDE2NDM1MCwiZXhwIjoxNjIxNzAwMzUwLCJzdWIiOiIxNjkwIiwic2NvcGVzIjpbXX0.yuTkL60ZNAIINJPsC4AijN4qBwEaM58MKDI1C4P75nh7UaB-017AXirZx0G57TSU4bshaT_u5VbZxrmceL3btOvsFQc0hFwfeVHKnJMriaZ4o1CobUMZ0CK-EmmOiY1s36WJg2eCaagDDNI0UT_XwQs3IVdeVwdjU5rg3SLpgXrOvja98HKMbY964y_8MR_clQpx-rTtGt5qyf5f7upMi1-3rRdLntHse8IbykNY7wyFrn7gJU5OLSkfAdo4r4H0ZIso0361NLwgXsbZlJrlGqCeuUd-QXNkSHn03f73ifXi2QxX5OZH44OwnjK4pBsk7UN6gNiMxq_1VP8eUMnf93Tnw1lLN9F105_krBRcr3VaLeyQCCIX_dklUGgbwAHmZwheMaQeVvt1lx5iy6OQK-baaVE7nMcSm2EQWOMYfW6tezQAhF53ovAMLoX_3SWfCIFWzbIBt-kpc808tcq9UK3XiP_8fBLpR6nTjqvwwEVPjJ75GlbC8ga2kbMwkod6T41XaJpv9sB3Ip72h4wwbJzI5aqObyWrQNZuYNajFJc4i60piQG2wHKpeCWwbanUDW2SnFRd0UDr2D1BwibTwrT6sHxA3yLHctN6DmBvMRQikG3d8nisjh2Xk5MTohtgt5UnH5ta4CvBHQWuot9wiKYiLPu72jxbBPnM-vcGDbc',
  dominio: 'doutorpass.com',
  email_from: 'atendimento@doutorpass.com',
  sms: 'https://api-athenasaude.sensedia.com/homologacao/sms/v1/',
  url_payment: 'https://hml-payment.doutorpass.com/',
  url_admin: 'https://hml-admin.doutorpass.com/#/empresas/',
  service: 'https://api-athenasaude.sensedia.com/homologacao/drpass/service/v1/clientes/',
  cep: 'https://api-athenasaude.sensedia.com/homologacao/drpass/administracao/v1/enderecos/',
  public_key: 'TEST-d050dcf0-cc3f-4d76-a7c9-0f4ef320297f',
  urlMP: 'https://api.mercadopago.com/v1/',
  iugu_account_Id: 'CB0E71BC058C0A9555FBF85AD3631DC7',
  externalHome: 'https://hml-web.doutorpass.com/',
  redirectHome: '',
  url_web: 'https://hml-web.doutorpass.com/',

  // Login Facebook
  facebook_client_id: '804615003529486',
  facebook_redirect_uri: 'https://hml-web.doutorpass.com/returnfb',

  // Login Google
  google_client_id: '170137183142-5ma3pvvevat67l0l0b3fatj8qsst2mds.apps.googleusercontent.com',
  google_redirect_uri: 'https://hml-web.doutorpass.com/returngoogle',
  google_user_data_url: 'https://www.googleapis.com/oauth2/v2/userinfo',

   // Login Apple
   apple_client_id: 'com.doutorpass.loginappleservice',
   apple_redirect_uri: 'https://hml-web.doutorpass.com',
   apple_redirect_ios12: 'doutorpass:handleLoginResponse',

   // Hotjar
   hotjar: '2476843',

   removeFreePlan: false,

   urlConexa: 'https://api-athenasaude.sensedia.com/homologacao/api-conexa/v2/',
   EMERGENCY_FREE: '2417',
   EMERGENCY: '2372'
};
