declare var $;
export class AgendamentoUtil {

    public static agendamentoJquey(): void {

        $('#datepicker').datepicker({
            uiLibrary: 'bootstrap4',
            locale: 'pt-br',
            format: 'dd/mm/yyyy',
            iconsLibrary: 'fontawesome',
        });
        $('#timepicker').timepicker({
            uiLibrary: 'bootstrap4',
            locale: 'pt-br',
            format: 'HH.MM',
            iconsLibrary: 'fontawesome',
        });
        $(document).ready(function() {
            $('#example').DataTable();
        });
        $('#menu-mobile').click(function() {
            $('.sidebar').animate({ width: 'toggle' }, 350);
            $('.main-page').toggleClass('mobile');
        });
    }
}
