import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-toggle-message',
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ]
    )
  ],
  templateUrl: './toggle-message.component.html',
  styleUrls: ['./toggle-message.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ToggleMessageComponent implements OnInit {

  @Input() message;
  show = false;

  constructor() { }

  ngOnInit() {
  }

}
