declare var $;

export class FloatInput {

    labelCep = 'hidden';
    labelCNPJ = 'hidden';
    labelTelefone1 = 'hidden';
    labelTelefone2 = 'hidden';
    labelDate = 'hidden';
    labelDOCPF = 'hidden';

    placeHolder = 'CEP';
    placeHolderDOC = 'CNPJ';
    placeHolderTelefone1 = 'Telefone';
    placeHolderTelefone2 = 'Telefone';
    placeHolderDate;
    placeHolderDOCPF = 'CPF';

    public onFocus() {
        this.labelCep = 'visible';
        this.placeHolder = '';
    }

    public onBlur() {
        if ($('#cep').val()) {
            this.labelCep = 'visible';
            this.placeHolder = '';
        } else {
            this.labelCep = 'hidden';
            this.placeHolder = 'CEP';
        }
    }

    public onFocusDOCPF(value?) {
        this.labelDOCPF = 'visible';
        this.placeHolderDOCPF = value ? value : 'CPF';
    }

    public onBlurDOCPF(value?) {
        if ($('#docPF').val()) {
            this.labelDOCPF = 'visible';
            this.placeHolderDOCPF = '';
        } else {
            this.labelDOCPF = 'hidden';
            this.placeHolderDOCPF = value ? value : 'CPF';
        }
    }

    public onFocusDOC() {
        this.labelCNPJ = 'visible';
        this.placeHolderDOC = 'CNPJ';
    }

    public onBlurDOC() {
        if ($('#doc').val()) {
            this.labelCNPJ = 'visible';
            this.placeHolderDOC = '';
        } else {
            this.labelCNPJ = 'hidden';
            this.placeHolderDOC = 'CNPJ';
        }
    }

    public onFocusTEL1() {
        this.labelTelefone1 = 'visible';
        this.placeHolderTelefone1 = 'Telefone';
    }

    public onBlurTEL1() {
        if ($('#tel1').val()) {
            this.labelTelefone1 = 'visible';
            this.placeHolderTelefone1 = '';
        } else {
            this.labelTelefone1 = 'hidden';
            this.placeHolderTelefone1 = 'Telefone';
        }
    }

    public onFocusTEL2() {
        this.labelTelefone2 = 'visible';
        this.placeHolderTelefone2 = 'Telefone';
    }

    public onBlurTEL2() {
        if ($('#tel1').val()) {
            this.labelTelefone2 = 'visible';
            this.placeHolderTelefone2 = '';
        } else {
            this.labelTelefone2 = 'hidden';
            this.placeHolderTelefone2 = 'Telefone';
        }
    }

    public onFocusDate(value) {
        this.labelDate = 'visible';
        this.placeHolderDate = value;
    }

    public onBlurDate(value) {
        if ($('#date').val()) {
            this.labelDate = 'visible';
            this.placeHolderDate = '';
        } else {
            this.labelDate = 'hidden';
            this.placeHolderDate = value;
        }
    }
}
