import { Role } from './../interfaces/role';
import { environment } from './../../environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from '../utils/msgUtil';
import { ApiService } from '../service/api.service';
import { StorageUtil } from '../utils/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthService } from '../o-auth.service';
import { UserService } from '../service/user.service';
import { UserData } from '../interfaces/userData-interface';
import { Login } from '../interfaces/login';
import { Spinner } from '../utils/stpiner';
import { AppleService } from '../service/social/apple.service';
import { SocialPayload } from '../interfaces/social-payload';
import { ErrorReturnUtil } from '../utils/errorReturnUtil';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginPayload: Login;
  tipoLogin = Role.Cliente;
  recupearLogin = '';
  user: UserData;

  constructor(private formBuilder: FormBuilder,
              private service: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private oAuthService: OAuthService,
              private userService: UserService,
              private appleService: AppleService) {
  }

  public getTipoLogin(value): void {
    this.tipoLogin = value;
  }

  public login() {
    if (this.loginPayload.userName === '' || this.loginPayload.password === '') {
      MsgUtil.msgErrorToast('Campo email e senha são obrigatórios');
      return;
    }

    this.oAuthService.login(this.loginPayload).subscribe((r) => {
      Spinner.spinner(document);
      StorageUtil.setTipoLogin(this.tipoLogin);
      StorageUtil.setJwt(r.jwtToken);

      const tokenDecoded = this.oAuthService.getDecodedAccessToken(r.jwtToken);
      const userId = tokenDecoded['system-user'];

      this.userService.redirectAfterLogin(userId);

    }, (error: HttpErrorResponse) => {
      console.log(error);
      MsgUtil.msgErrorToast(error.error.message);
    });
  }


  public gopage() {
    this.userService.gopage();
  }



  private verificarUsuarioLogado(): void {
    if (StorageUtil.getUser()) {
      this.router.navigate(['/clientes-medico-online']);
      return;
    }
  }

  public recuperarSenha(): void {

    if (this.recupearLogin === '') {
      MsgUtil.msgErrorToast('Digite um E-mail para continuar');
      return;
    }

    this.service.getRecuperarSenha(this.recupearLogin).subscribe((r: any) => {
      $('#recuperarSenhaID').click();
      MsgUtil.msgSuccessToast('A senha foi enviada para você por SMS');

    }, (error: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(error.error.messages[0].message);
    });
  }

  goToRegister() {
    window.location.href = environment.url_payment;
  }

  ngOnInit() {
    this.verificarUsuarioLogado();
    this.loginPayload = { userName: '', password: '' };
  }

  OnDestroy() {
    this.service.key = '';
  }

  socialLogin(provider) {
    switch (provider) {
      case 'FACEBOOK':
        // Get Code
        console.log('https://www.facebook.com/v10.0/dialog/oauth?client_id=' + environment.facebook_client_id + '&redirect_uri=' + environment.facebook_redirect_uri + '&state=public_profile,email&scope=email');
        window.location.href = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=' + environment.facebook_client_id + '&redirect_uri=' + environment.facebook_redirect_uri + '&state=public_profile,email&scope=email';
        break;

      case 'GOOGLE':
        // Get Code
        console.log('https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&redirect_uri=' + environment.google_redirect_uri + '&client_id=' + environment.google_client_id);
        window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&redirect_uri=' + environment.google_redirect_uri + '&client_id=' + environment.google_client_id;
        break;

      case 'APPLE':
        this.appleService.loadApple();
        $('#appleid-signin').click();
        break;
    }
  }

  // Getting information from apple pop up
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin === 'https://appleid.apple.com') {
      const payload = this.appleService.responseApple(event);
      if (payload) {
        this.redirectApple(payload);
      } else {
        ErrorReturnUtil.buildError(null, 'Ocorreu um erro no login via Apple. Por favor, tente novamente mais tarde.');
      }
    }
  }

  redirectApple(payload) {
    Spinner.spinner(document, true);
    this.userService.socialLogin(payload).subscribe((r) => {
      StorageUtil.setJwt(r.jwtToken);

      const tokenDecoded = this.oAuthService.getDecodedAccessToken(r.jwtToken);
      const userId = tokenDecoded['system-user'];

      this.userService.redirectAfterLogin(userId);

    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Apple. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

}

const TIPOLOGIN = [
  { key: 'CLIENTE', route: '/clientes-medico-online' },
  { key: 'PRESTADOR', route: '/prestador-chekin' }
];
