import { ClinicTypeInput } from 'src/app/service/inputs/clinic-type.input';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Headers } from '../interfaces/headers';
import { StorageUtil } from '../utils/storage';
import { ClinicsMagicLinkOutput } from './outputs/clinics-magic-link.output';

@Injectable({
  providedIn: 'root'
})
export class EpharmaService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  active(customerId: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.patch(`${environment.urlBase}customers/${customerId}/epharma/activate`, null, requestOptions);
  }

}
