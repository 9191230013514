import { GetLocationService } from './../service/get-location.service';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';


declare var $;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  cars = [];
  cars2 = [];
  responsiveOptions;



  banner: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  };

  url = environment.url_payment;

  constructor(private getLocation: GetLocationService, private route: ActivatedRoute) {
    if (environment.redirectHome) { window.location.href = environment.redirectHome; }
  }

  ngOnInit() {
    this.getRegion();
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '768px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 2
      }
    ];

    this.cars2 = [
      {brand: 'banner1.jpg' },
      {brand: 'img_05.jpg'},
      {brand: 'header.jpg'}
    ];

    this.cars = [
      { brand: 'carrocel5.jpg' },
      { brand: 'carrocel3.jpg' },
      { brand: 'carrocel1.jpg' },
      { brand: 'carrocel8.jpg' },
      { brand: 'carrocel9.jpg' },
      { brand: 'carrocel10.jpg' },
      { brand: 'carrocel11.jpg' },
      { brand: 'carrocel7.jpg' },
      { brand: 'carrocel16.jpg' },
      { brand: 'carrocel6.jpg' }
    ];


    this.route.queryParams.subscribe(params => {
      if (params.goTo) {
        this.scrollToElementById(params.goTo);
      }
    });

  }

  public getRegion() {
    this.getLocation.getIpAddress().subscribe((res: any) => {
      this.getLocation.getGEOLocation(res.ip).subscribe((region: any) => {
        if (region.country_code) {
          console.log('REGIAO', region.country_code);
          if (region.country_code !== 'BR') { window.location.href = 'https://global.doutorpass.com/'; }
        }
        return;
      });
    });
  }

  public goBeneficios() {
      setTimeout(() => {
        $('html, body').animate(
          { scrollTop: $('#beneficios').position().top },
          'slow'
        );
      }, 300);
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToElementById(id) {
    setTimeout(() => {
      $('html, body').animate(
        { scrollTop: $('#' + id).position().top },
        'slow'
      );
    }, 1000);
  }

  setCarrocelStyle(image: string) {
    let style;
    switch (image) {
      case 'carrocel8.jpg':
      case 'carrocel9.jpg':
      case 'carrocel10.jpg':
      case 'carrocel11.jpg': {
         style = {width: '100%', 'max-width': '170px', height: 'auto' };
         return style;
      }
      case 'carrocel1.jpg':
      case 'carrocel3.jpg':
      case 'carrocel5.jpg':
      case 'carrocel6.jpg':
      case 'carrocel7.jpg':
      case 'carrocel16.jpg': {
        style = {width: '100%', 'max-width': '300px', height: 'auto' };
        return style;
      }
   }
  }
}
