import { Injectable } from '@angular/core';
import { Headers } from './../interfaces/headers';
import { environment } from './../../environments/environment';
import { StorageUtil } from '../utils/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOnlineService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  getOnlineAppointment() {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}appointments`, requestOptions);
  }

  cancelOnlineAppointment(appId: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.delete(`${environment.urlBase}appointments/elective/${appId}`, requestOptions);
  }

  gotoOnlineAppointoment() {

  }

}
