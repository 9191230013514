import { environment } from 'src/environments/environment';
const CryptoJS = require('crypto-js');

export class StorageUtil {

    private static USER = 'USER';
    private static COOKIE = 'ACCEPT_C1';
    private static SHOW_TOP_INCENTIVE = 'SHOW_TOP_INCENTIVE';

    /* decript */

    private static encryptSecretKey = environment.passWord;

    private static encryptData(data) {
        if (data === null || data === undefined) {
            return;
        }

        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), StorageUtil.encryptSecretKey).toString();
        } catch (e) {
            console.error(e);
        }
    }

    private static decryptData(data) {

        if (data === null || data === undefined) {
            return;
        }
        try {
            const bytes = CryptoJS.AES.decrypt(data, StorageUtil.encryptSecretKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.error(e);
        }
    }

    /* outros */

    public static setUser(data: any): void {
        localStorage.setItem(StorageUtil.USER, JSON.stringify(data));
    }

    public static setUserPerfil(data: any): void {
        localStorage.setItem('perfil', data);
    }

    public static setAccessToken(data: any): void {
        localStorage.setItem('at', data);
    }

    public static getAccessToken(): any {
        return localStorage.getItem('at');
    }

    public static setJwt(data: any): void {
        localStorage.setItem('jt', data);
    }

    public static getJwt(): any {
        return localStorage.getItem('jt');
    }

    public static clearJwt(): void {
        localStorage.removeItem('jt');
    }

    public static getUserPerfil(): any {
        return localStorage.getItem('perfil');
    }

    public static getUser(): any {
        return JSON.parse(localStorage.getItem(StorageUtil.USER));
    }

    public static goAgendamento(params) {
        localStorage.setItem('prestador', JSON.stringify(params));
    }

    public static getAgendamento() {
        return JSON.parse(localStorage.getItem('prestador'));
    }

    public static cleanAgendamento() {
        localStorage.removeItem('prestador');
    }

    public static setTipoLogin(data) {
        localStorage.setItem('TYPE', data);
    }

    public static getTipoLogin() {
        return localStorage.getItem('TYPE');
    }

    public static getStatuscookies(): string {
        return localStorage.getItem(StorageUtil.COOKIE);
    }

    public static setStatuscookies(): void {
        localStorage.setItem(StorageUtil.COOKIE, '1');
    }

    public static getShowTopIncentive(): string {
      return localStorage.getItem(this.SHOW_TOP_INCENTIVE);
    }

    public static setShowTopIncentive(data): void {
        localStorage.setItem(this.SHOW_TOP_INCENTIVE, data);
    }

    public static clearSession(): void {
        localStorage.removeItem('perfil');
        localStorage.removeItem(this.USER);
        localStorage.removeItem(this.SHOW_TOP_INCENTIVE);
    }
}
