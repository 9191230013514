import { Component, OnInit } from '@angular/core';
import { Util } from 'src/app/utils/util';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { StorageUtil } from 'src/app/utils/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from 'src/app/utils/msgUtil';

declare var $;

@Component({
  selector: 'app-prestador-checkin',
  templateUrl: './prestador-checkin.component.html',
  styleUrls: ['./prestador-checkin.component.css']
})
export class PrestadorCheckinComponent implements OnInit {

  cols: any[] = [];
  values: any[] = [];
  table = false;
  codigoAgendamento = '';
  agendamentos = [];
  page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  pagination = { page: 0, size: 10 };

  constructor(private service: ApiService, private router: Router) {
    this.service.key = 'PRESTADOR';
  }

  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.buscarAgendamentoPorCodigo();
    }
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.buscarAgendamentoPorCodigo();
    }
  }

  public check_in( id ) {

    this.service.key = 'AGENDAMENTO';
    this.service.path = id + '/checkin';

    this.service.put( null ).subscribe(( r: any ) => {
      this.service.key = 'PRESTADOR';
      $('#open_sucess').click();
      this.service.path = '';
      this.buscarAgendamentoPorCodigo();
    });
  }

  public load() {

    this.buildParametrosLoad();

    this.service.getById( StorageUtil.getUserPerfil() + '/agendamentos' ).subscribe((r: any) => {

      if (r.content) {
        this.buildList(r.content);
        this.codigoAgendamento = '';
        this.table = true;
        this.page = r.page;
      }
    });
  }

  public buscarAgendamentoPorCodigo() {

    this.buildParametros();

    this.service.getById( StorageUtil.getUserPerfil() + '/agendamentos' ).subscribe((r: any) => {
      if (r.content) {
      this.buildList( r.content );
      this.codigoAgendamento = '';
      this.table = true;
      this.page = r.page;
      }
    }, (error: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(error.error.messages[0].mensagem);
    });
  }

  private buildParametrosLoad(): void {

   this.service.params = {
      status: 'CONFIRMADO',
    };
  }

  private buildParametros(): void {
    this.service.params = {
      agendamento_id: this.codigoAgendamento,
      status: 'CONFIRMADO',
    };
  }

  private buildList(content): void {

    this.agendamentos = [];

    content.forEach(element => {
      if (element.valor > 0) {
        const value = String(element.valor);
        element.valor = Number(value.substring(0, value.length - 2));
      }
      this.agendamentos.push( element );
     });
  }

  ngOnInit() {
    this.load();
    Util.dataTable();
  }

  OnDestroy() {
    this.service.params = {};
    this.service.key = '';
    this.service.path = '';
  }
}
