import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClinicsService } from '../../../../service/clinics.service';
import { ClinicTypeInput } from '../../../../service/inputs/clinic-type.input';
import { ClinicsMagicLinkOutput } from '../../../../service/outputs/clinics-magic-link.output';
import { StorageUtil } from '../../../../utils/storage';
import { Spinner } from '../../../../utils/stpiner';
import { MagicLinkOpenComponent } from '../magic-link-open/magic-link-open.component';

@Component({
  selector: 'app-conheca-top',
  templateUrl: './conheca-top.component.html',
  styleUrls: ['./conheca-top.component.css']
})

export class ConhecaTopComponent {
  @Input()
  public uri: string;
  clinicTypeInput = ClinicTypeInput;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    protected ngbModal: NgbModal,
    private clinicsService: ClinicsService,
    private router: Router
  ) { }

  public closeCurrentModal(eventoExecutadoComSucesso: boolean): void {
    this.ngbActiveModal.close(eventoExecutadoComSucesso);
  }

  public iniciarAtendimento(a) {
    this.ngbActiveModal.close(a);
  }

  redirectChangePlan() {
    this.ngbActiveModal.close();
    this.router.navigate(['/alterar-plano']);
  }
}

