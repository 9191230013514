import { OAuthService } from './../../o-auth.service';
import { ErrorReturnUtil } from './../../utils/errorReturnUtil';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { MustMatch } from 'src/app/utils/must-match';
import { Spinner } from 'src/app/utils/stpiner';
import { StorageUtil } from 'src/app/utils/storage';
import { MsgUtil } from 'src/app/utils/msgUtil';

@Component({
  selector: 'app-reset-password-confirmation',
  templateUrl: './reset-password-confirmation.component.html',
  styleUrls: ['./reset-password-confirmation.component.css']
})
export class ResetPasswordConfirmationComponent implements OnInit, OnDestroy {

  temporarytoken: string;
  public subs: Subscription;
  registerForm: FormGroup;
  submitted = false;

  urlAtual;

  constructor(private formBuilder: FormBuilder, public route: ActivatedRoute, private router: Router, private userService: UserService, private oAuthService: OAuthService) { }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  ngOnInit() {
    this.subs = this.route.queryParams.subscribe(params => {
      this.temporarytoken = params.token;
      if (this.temporarytoken == null) {
        this.router.navigate(['/login']);
      }
    });

    this.registerForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      ]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: [MustMatch('password', 'confirmPassword')]
    });

    this.geraUrlAtual();

  }

  geraUrlAtual() {
    this.urlAtual = window.location.href;
  }

  redireciona_url(param) {
    if ('1') { window.location = this.urlAtual; }
    if ('2') { window.location.href = this.urlAtual; }
    if ('3') { window.location.replace(this.urlAtual); }
  }

  enviar() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const payload = {
      password: this.registerForm.getRawValue().password,
      passwordConfirmation: this.registerForm.getRawValue().confirmPassword
    };

    Spinner.spinner(document, true);
    this.userService.resetPasswordConfirmation(this.temporarytoken, payload).subscribe(r => {

      StorageUtil.setJwt(r.jwtToken);

      const tokenDecoded = this.oAuthService.getDecodedAccessToken(r.jwtToken);
      const userId = tokenDecoded['system-user'];

      MsgUtil.msgSuccessToast('Senha alterada com sucesso!');
      this.userService.redirectAfterLogin(userId);
    }, err => {
      ErrorReturnUtil.buildError(err);
      this.router.navigate(['/login']);
    }, () => { Spinner.spinner(document); });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
