import { ErrorReturnUtil } from './../../utils/errorReturnUtil';
import { ClinicTypeInput } from 'src/app/service/inputs/clinic-type.input';
import { Queue } from './../../interfaces/queue-callback';
import { ConhecaTopComponent } from './modals/conheca-top/conheca-top.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/service/user.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateFormat';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { ClinicsService } from '../../service/clinics.service';
import { ClinicsMagicLinkOutput } from '../../service/outputs/clinics-magic-link.output';
import { PlanOutput } from '../../service/outputs/plan.output';
import { StorageUtil } from '../../utils/storage';
import { ApiService } from './../../service/api.service';
import { MagicLinkOpenComponent } from './modals/magic-link-open/magic-link-open.component';
import { PrenchimentoAgendamentoComponent } from './modals/prenchimento-agendamento/prenchimento-agendamento.component';
import { UpgradePlanoComponent } from './modals/upgrade-plano/upgrade-plano.component';
import { Subscription, throwError, timer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-medico-online',
  templateUrl: './medico-online.component.html',
  styleUrls: ['./medico-online.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class MedicoOnlineComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('myModal', { static: false }) myModal: ElementRef;
  @ViewChild('closebutton', { static: false }) closebutton: ElementRef;

  iPhone;
  usuario;
  displayModal = true;
  birthDate = null;
  maxDate = { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getUTCDate() };
  gender = ['Feminino', 'Masculino'];
  sexo;
  userID: string;

  user = { plano: '' };

  clinicTypeInput = ClinicTypeInput;

  tipoDeClinica: ClinicTypeInput;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  iframe = false;
  urlSafe: SafeResourceUrl;
  fila: any;
  subscription: Subscription;

  removeFreePlan = environment.removeFreePlan;

  constructor(
    public router: Router,
    private service: ApiService,
    private userService: UserService,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private clinicsService: ClinicsService,
    private ngbModal: NgbModal,
    private userDataModalService: UserDataModalService,
    public sanitizer: DomSanitizer
  ) { }

  private loadTypeDevice(): void {
    if (window.clientInformation.appVersion.toLowerCase().indexOf('iphone') > -1) {
      this.iPhone = true;
    }

    if (window.clientInformation.appVersion.toLowerCase().indexOf('ipad') > -1) {
      this.iPhone = true;
    }
  }

  public iniciarAtendimento(clinicTypeInput: ClinicTypeInput) {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);

      this.clinicsService.getMagicLinkAsync(clinicTypeInput).subscribe((clinicsMagicLinkOutput: ClinicsMagicLinkOutput) => {
        this.tratarRetornoAtedimentoComSucesso(clinicTypeInput, clinicsMagicLinkOutput);
      }, (error: HttpErrorResponse) => {
        if (error.status === 412) {
          console.log('ERROR', error);
          this.exibirModalParaUpgradeDePlano();
          return;
        }
        MsgUtil.msgErrorToast('Ocorreu um erro ao tentar iniciar o atendimento. Por favor, tente novamente mais tarde.');
      }, () => Spinner.spinner(document));

    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  private tratarRetornoAtedimentoComSucesso(clinicTypeInput: ClinicTypeInput, clinicsMagicLinkOutput: ClinicsMagicLinkOutput): void {
    if (clinicsMagicLinkOutput != null) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(clinicsMagicLinkOutput.magicLinkWeb);
      this.iframe = true;
      this.tipoDeClinica = clinicTypeInput;
      this.checkQueue(clinicTypeInput);
      //window.open(clinicsMagicLinkOutput.magicLinkWeb, '_blank');
    }
  }

  goNewTab(clinicType: ClinicTypeInput) {
    Spinner.spinner(document, true);
    this.clinicsService.getMagicLinkAsync(clinicType).subscribe((clinicsMagicLinkOutput: ClinicsMagicLinkOutput) => {
      if (clinicsMagicLinkOutput) {
        window.open(clinicsMagicLinkOutput.magicLinkWeb, '_blank');
      }
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  private exibirModalParaUpgradeDePlano(): void {
    const resultModal = this.ngbModal.open(ConhecaTopComponent);
    resultModal.result.then(
      (data: any) => {
        if (data === ClinicTypeInput.EMERGENCY) {
          this.iniciarAtendimento(data);
        }
      },
      (reason: any) => { });
  }

  private checkQueue(clinicTypeInput: ClinicTypeInput) {
    const cpf = this.userService.currentUserValue.prospect.documentNumber;
    const stopTimer$ = timer(60 * 60 * 1000);
    this.subscription = timer(0, 15000)
      .pipe(
        switchMap(() => this.clinicsService.queuePosition(clinicTypeInput, cpf)),
        takeUntil(stopTimer$)
      )
      .subscribe((data: Queue) => {
        if (data) {
          this.fila = data;
          this.iframe = false;
        } else {
          this.fila = "SEM_FILA";
        }
      });
  }

  private isInQueueEmergencyFree() {
    const cpf = this.userService.currentUserValue.prospect.documentNumber;
    if (cpf) {
      Spinner.spinner(document, true);
      this.clinicsService.queuePosition(ClinicTypeInput.EMERGENCY_FREE, cpf).subscribe((data: Queue) => {
        Spinner.spinner(document);
        if (data) {
          this.tipoDeClinica = ClinicTypeInput.EMERGENCY_FREE;
          this.fila = data;
          this.iframe = false;
          setTimeout(() => {
            this.checkQueue(ClinicTypeInput.EMERGENCY_FREE);
          }, 15000);
        } else {
          this.isInQueueEmergency();
        }
      });
    }
  }

  private isInQueueEmergency() {
    const cpf = this.userService.currentUserValue.prospect.documentNumber;
    if (cpf) {
      Spinner.spinner(document, true);
      this.clinicsService.queuePosition(ClinicTypeInput.EMERGENCY, cpf).subscribe((data: Queue) => {
        Spinner.spinner(document);
        if (data) {
          this.tipoDeClinica = ClinicTypeInput.EMERGENCY;
          this.fila = data;
          this.iframe = false;
          setTimeout(() => {
            this.checkQueue(ClinicTypeInput.EMERGENCY);
          }, 15000);
        }
      });
    }

  }


  ngOnInit() {
    this.loadTypeDevice();
    this.user.plano = this.userService.getPlanType().toString();
    this.userID = this.userService.currentUserValue.customer.id;
    this.loadUserDataSubscription();
    this.isInQueueEmergencyFree();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.userService.currentUserValue.user.firstLogin) { this.myModal.nativeElement.click(); }
    }, 500);
  }

  get isValid() {
    return this.birthDate && this.sexo && this.userID;
  }

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }
}
