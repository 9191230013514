import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, ɵROUTER_PROVIDERS } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CarouselModule as Ngx_carrocel } from 'ngx-owl-carousel-o';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlterarPlanoComponent } from './cliente/alterar-plano/alterar-plano.component';
import { DependentesComponent } from './cliente/dependentes/dependentes.component';
import { MeusDependentesComponent } from './cliente/dependentes/meus-dependentes/meus-dependentes.component';
import { MedicoOnlineComponent } from './cliente/medico-online/medico-online.component';
import { MagicLinkOpenComponent } from './cliente/medico-online/modals/magic-link-open/magic-link-open.component';
import { PrenchimentoAgendamentoComponent } from './cliente/medico-online/modals/prenchimento-agendamento/prenchimento-agendamento.component';
import { UpgradePlanoComponent } from './cliente/medico-online/modals/upgrade-plano/upgrade-plano.component';
import { MeusAgendamentosComponent } from './cliente/meus-agendamentos/meus-agendamentos.component';
import { MeusBeneficiosComponent } from './cliente/meus-beneficios/meus-beneficios.component';
import { AlterarSenhaComponent } from './cliente/perfil-cliente/alterar-senha/alterar-senha.component';
import { PerfilClienteComponent } from './cliente/perfil-cliente/perfil-cliente.component';
import { PlanoUpgradeComponent } from './cliente/plano-upgrade/plano-upgrade.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { EinsteinConectaComponent } from './home/einstein-conecta/einstein-conecta.component';
import { FaleConoscoComponent } from './home/fale-conosco/fale-conosco.component';
import { HomeComponent } from './home/home.component';
import { QuemSomosComponent } from './home/quem-somos/quem-somos.component';
import { RedeParceiroComponent } from './home/rede-parceiro/rede-parceiro.component';
import { RedeParceirosNewComponent } from './home/rede-parceiros-new/rede-parceiros-new.component';
import { ConfirmarEmailComponent } from './login/confirmar-email/confirmar-email.component';
import { LoginComponent } from './login/login.component';
import { RecuperarSenhaComponent } from './login/recuperar-senha/recuperar-senha.component';
import { SolicitarNovaSenhaComponent } from './login/solicitar-nova-senha/solicitar-nova-senha.component';
import { MenuComponent } from './menu/menu.component';
import { ConfirmModalComponent } from './modals/confirm/confirm-modal.component';
import { MenuLetarealPrestadorComponent } from './pestador/menu-letareal-prestador/menu-letareal-prestador.component';
import { PrestadorCheckinComponent } from './pestador/prestador-checkin/prestador-checkin.component';
import { PrestadorConfiguracoesComponent } from './pestador/prestador-configuracoes/prestador-configuracoes.component';
import { PrestadorHistoricoAgendamentosComponent } from './pestador/prestador-historico-agendamentos/prestador-historico-agendamentos.component';
import { TelPipe } from './pipes/phone-pipe';
import { SchedulesPeriodPipe } from './pipes/schedules-period-pipe';
import { SchedulesStatusPipe } from './pipes/schedules-status-pipe';
import { TimeOutComponent } from './time-out/time-out.component';
import { HttpErrorInterceptor } from './utils/http-error.interceptor';
import { ToggleMessageComponent } from './home/rede-parceiros-new/toggle-message/toggle-message.component';
import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { ConhecaTopComponent } from './cliente/medico-online/modals/conheca-top/conheca-top.component';
import { DigitAnimationComponent } from './home/rede-parceiros-new/digit-animation/digit-animation.component';
import { TestRedirectComponent } from './testRedirect/test-redirect/test-redirect.component';
import { UpdateUserDataComponent } from './modals/update-user-data/update-user-data.component';
import { FacebookReturnComponent } from './login/facebook-return/facebook-return.component';
import { PoliticaPrivacidadeComponent } from './home/politica-privacidade/politica-privacidade.component';
import { GoogleReturnComponent } from './login/google-return/google-return.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './login/reset-password-confirmation/reset-password-confirmation.component';
import { MenuLogadoComponent } from './cliente/menu-logado/menu-logado.component';
import { PlanoUpgradeSuccessComponent } from './cliente/pagamento-modulo/plano-upgrade-success/plano-upgrade-success.component';
import { Ios12loginComponent } from './login/ios12login/ios12login.component';
import { NgxHotjarModule, NgxHotjarRouterModule, NgxHotjarService } from 'ngx-hotjar';
import { environment } from 'src/environments/environment';
import {TabViewModule} from 'primeng/tabview';
import { StaticModalComponent } from './cliente/medico-online/modals/static-modal/static-modal.component';
import { PagamentoCartaoTokenComponent } from './cliente/pagamento-modulo/pagamento-cartao-token/pagamento-cartao-token.component';
import { PagamentoComponenteComponent } from './cliente/pagamento-modulo/pagamento-componente/pagamento-componente.component';
import { PagamentoRegraComponent } from './cliente/pagamento-modulo/pagamento-regra/pagamento-regra.component';
import { CardModule as NewCardModule } from 'ngx-card/ngx-card';
import { CarteirinhaPDFComponent } from './cliente/perfil-cliente/carteirinha-pdf/carteirinha-pdf.component';
import { CpfPipe } from './pipes/cpf-pipe';
import { AjudaComponent } from './footer/ajuda/ajuda.component';
import { LoginVerificaGratuidadeComponent } from './cliente/medico-online/modals/login-verifica-gratuidade/login-verifica-gratuidade.component';
import * as Sentry from "@sentry/angular";
import { CustomPaginationDirective } from './_components/pagination/custompagination.directive';
import { MatPaginatorModule } from '@angular/material';
import { ModalVerificaNomeComponent } from './cliente/medico-online/modals/modal-verifica-nome/modal-verifica-nome.component';
import { WebviewRedirectComponent } from './webview-redirect/webview-redirect.component';

registerLocaleData(localePt);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    StaticModalComponent,
    AppComponent,
    DashboardComponent,
    MenuComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    EinsteinConectaComponent,
    RedeParceiroComponent,
    MeusDependentesComponent,
    FaleConoscoComponent,
    AgendamentoComponent,
    MeusAgendamentosComponent,
    PrestadorCheckinComponent,
    MenuLetarealPrestadorComponent,
    PerfilClienteComponent,
    PrestadorHistoricoAgendamentosComponent,
    PrestadorConfiguracoesComponent,
    SolicitarNovaSenhaComponent,
    RecuperarSenhaComponent,
    DependentesComponent,
    TimeOutComponent,
    MedicoOnlineComponent,
    AlterarSenhaComponent,
    ConfirmModalComponent,
    QuemSomosComponent,
    MeusBeneficiosComponent,
    ConfirmarEmailComponent,
    RedeParceirosNewComponent,
    TelPipe,
    SchedulesStatusPipe,
    SchedulesPeriodPipe,
    MagicLinkOpenComponent,
    UpgradePlanoComponent,
    AlterarPlanoComponent,
    PlanoUpgradeComponent,
    PrenchimentoAgendamentoComponent,
    ToggleMessageComponent,
    ConhecaTopComponent,
    DigitAnimationComponent,
    TestRedirectComponent,
    UpdateUserDataComponent,
    FacebookReturnComponent,
    PoliticaPrivacidadeComponent,
    GoogleReturnComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    MenuLogadoComponent,
    PlanoUpgradeSuccessComponent,
    Ios12loginComponent,
    StaticModalComponent,
    PagamentoRegraComponent,
    PagamentoComponenteComponent,
    PagamentoCartaoTokenComponent,
    CarteirinhaPDFComponent,
    CpfPipe,
    AjudaComponent,
    LoginVerificaGratuidadeComponent,
    CustomPaginationDirective,
    ModalVerificaNomeComponent,
    WebviewRedirectComponent,
  ],
  imports: [
    MessageModule,
    DialogModule,
    ToastModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SidebarModule,
    InputMaskModule,
    TableModule,
    CarouselModule,
    AutoCompleteModule,
    CalendarModule,
    Ngx_carrocel,
    NgbModule,
    TabViewModule,
    NgxMaskModule.forRoot(),
    NgxHotjarModule.forRoot(environment.hotjar),
    NgxHotjarRouterModule,
    NewCardModule,
    MatPaginatorModule
  ],
  providers: [
    MessageService,
    ɵROUTER_PROVIDERS,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DatePipe,
    authInterceptorProviders,
    NgxHotjarService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [StaticModalComponent, CustomPaginationDirective],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmModalComponent,
    MagicLinkOpenComponent,
    UpgradePlanoComponent,
    PrenchimentoAgendamentoComponent,
    ConhecaTopComponent,
    UpdateUserDataComponent
  ]
})
export class AppModule { }
