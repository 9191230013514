import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schedulesStatus'
})

export class SchedulesStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let str = '';
    switch (value) {
      case 'CONFIRMED':
        str = 'Confirmado';
        break;
        case 'CANCELED':
        str = 'Cancelado';
        break;
      default:
        str = 'Pré-Agendamento';
        break;
    }
    return str;
  }
}
