import { Injectable } from '@angular/core';
import { Headers } from './../interfaces/headers';
import { environment } from './../../environments/environment';
import { StorageUtil } from '../utils/storage';
import { Schedules } from '../interfaces/schedules';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  constructor(private http: HttpClient) { }

  schedulePost(payload: Schedules) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}schedules/`, payload, requestOptions);
  }

  cancelSchedule(scheduleId) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.put(`${environment.urlBase}schedules/${scheduleId}/cancel`, null, requestOptions);
  }

}
