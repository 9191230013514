export enum UriKey {
  LEADS = 'clientes/leads/',
  LEADS_PJ = 'empresas/leads/',
  LEADS_EMPRESA = 'empresas/leads/',
  LEADS_CODE = 'code/',
  LEADS_PAGADORES = 'pagadores/',
  GRANT_CODE = 'grant-code',
  ACCES_TOKEN = 'access-token',
  RSEND_CODE = 'enviar/clientes/leads/',
  RSEND_CODE_PJ = 'enviar/empresas/leads/',
  MP_CARD_TOKEN = 'card_tokens',
  MP_PAYMENT_METHOD = 'payment_methods'
}
