import { OAuthService } from './../../o-auth.service';
import { UserService } from 'src/app/service/user.service';
import { SocialPayload } from './../../interfaces/social-payload';
import { FacebookUserdataCallback } from './../../interfaces/facebook-userdata-callback';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FacebookTokenCallback } from 'src/app/interfaces/facebook-token-callback';
import { FacebookService } from 'src/app/service/social/facebook.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { StorageUtil } from 'src/app/utils/storage';

@Component({
  selector: 'app-facebook-return',
  templateUrl: './facebook-return.component.html',
  styleUrls: ['./facebook-return.component.css']
})
export class FacebookReturnComponent implements OnInit {

  public subs: Subscription;

  constructor(
    private facebookService: FacebookService,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private oAuthService: OAuthService
  ) {
    console.log('facebook return component');
  }

  ngOnInit() {
    console.log('facebook return component');
    this.subs = this.route.queryParams.subscribe(params => {
      if (params.code == null) {
        this.router.navigate(['/login']);
      } else {
        this.getAccessToken(params.code);
      }
    });
  }

  getAccessToken(code: string) {
    Spinner.spinner(document, true);
    this.facebookService.getAccessToken(code).subscribe((r: FacebookTokenCallback) => {
      if (r.accessToken) {
        this.getUserData(r.accessToken);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  getUserData(accessToken: string) {
    Spinner.spinner(document, true);
    this.facebookService.getUserData(accessToken).subscribe((r: FacebookUserdataCallback) => {
      if (r) {
        this.socialLogin(accessToken, r);
      }
    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );
  }

  socialLogin(accessToken: string, user: FacebookUserdataCallback) {
    const payload: SocialPayload = {
      email: user.email,
      id: user.id,
      name: user.name,
      provider: 'FACEBOOK',
      token: accessToken
    };

    this.userService.socialLogin(payload).subscribe((r) => {
      Spinner.spinner(document);
      StorageUtil.setJwt(r.jwtToken);

      const tokenDecoded = this.oAuthService.getDecodedAccessToken(r.jwtToken);
      const userId = tokenDecoded['system-user'];

      this.userService.redirectAfterLogin(userId);

    }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro no login via Facebook. Por favor, tente novamente mais tarde.'),
      () => { Spinner.spinner(document); }
    );

  }

}
