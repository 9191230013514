export class Spinner {
    public static spinner(component, action?) {

            if (action) {
              component.querySelector('#overlayspinner')
                       .appendChild(component.createElement('img'))
                       .src = 'assets/img/overlayspinner.gif';

              component.querySelector('#overlayspinner')
                       .appendChild(component.createElement('div'));

              return;
              }

            const img = component.querySelector('#overlayspinner')
                                 .getElementsByTagName('img');
            const div = component.querySelector('#overlayspinner')
                                 .getElementsByTagName('div');

            if (img.length > 0) {
                img[0].parentNode.removeChild(img[0]);
               }

            if (div.length > 0) {
                div[0].parentNode.removeChild(div[0]);
               }
          }
}
