import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-magic-link-open',
  templateUrl: './magic-link-open.component.html'
})
export class MagicLinkOpenComponent {
  @Input()
  public uri: string;

  constructor(private ngbActiveModal: NgbActiveModal, protected ngbModal: NgbModal) { }

  public closeCurrentModal(eventoExecutadoComSucesso: boolean): void {
    this.ngbActiveModal.close(eventoExecutadoComSucesso);
  }
}
