import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
  public execution = false;

  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public subMessage: string = null;

  @Input()
  public warning: string = null;

  @Output()
  public close: EventEmitter<any> = new EventEmitter();

  @Output()
  public confirm: EventEmitter<boolean> = new EventEmitter();

  constructor(private ngbActiveModal: NgbActiveModal, protected ngbModal: NgbModal) { }

  public closeCurrentModal(eventoExecutadoComSucesso: boolean): void {
    this.ngbActiveModal.close(eventoExecutadoComSucesso);
    this.close.emit();
  }

  public confirmResponse(response: boolean): void {
    this.confirm.emit(response);
  }

  public showLoading(): void {
    this.execution = true;
  }

  public hideLoading(): void {
    this.execution = false;
  }
}
