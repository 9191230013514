import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from './utils/http-util';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './service/api.service';
import {MessageService} from 'primeng/api';
import jwt_decode from 'jwt-decode';
import { Login } from './interfaces/login';
import { Spinner } from './utils/stpiner';
import { map } from 'rxjs/operators';
import { StorageUtil } from './utils/storage';
import { Router } from '@angular/router';
import { UserService } from './service/user.service';
@Injectable({
  providedIn: 'root'
})
export class OAuthService {

  token;

  constructor(private http: HttpClient,
              private httputil: HttpUtil,
              public msgService: MessageService,
              public apiService: ApiService,
              public router: Router
              ) { }

  public generateGantCode(): Observable<any> {
    const request = { client_id: environment.client_id, redirect_uri: 'http://localhost' };
    return this.http.post<any>(this.httputil.buildUrlOauth('GRANT_CODE'), request, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public generateAccessToken(code: any): Observable<any> {
    const request = { grant_type: 'authorization_code', code };
    return this.http.post<any>(this.httputil.buildUrlOauth('ACCES_TOKEN'), request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' +  btoa(environment.client_id + ':' + environment.passWord)
      }
    });
  }

  public refresh(code: any): Observable<any> {
    const request = { grant_type: 'refresh_token', refresh_token: code };
    return this.http.post<any>(this.httputil.buildUrlOauth('ACCES_TOKEN'), request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' +  btoa(environment.client_id + ':' + environment.passWord)
      }
    });
  }

  public login(login: Login): Observable<any> {
    Spinner.spinner(document, true);
    return this.http.post<any>(this.httputil.buildUrl('LOGIN'), login, {
      headers: this.httputil.header(StorageUtil.getAccessToken())
    });
  }



  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

}
