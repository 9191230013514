import { PagamentoRegraComponent } from './cliente/pagamento-modulo/pagamento-regra/pagamento-regra.component';
import { Ios12loginComponent } from './login/ios12login/ios12login.component';
import { PlanoUpgradeSuccessComponent } from './cliente/pagamento-modulo/plano-upgrade-success/plano-upgrade-success.component';
import { ResetPasswordConfirmationComponent } from './login/reset-password-confirmation/reset-password-confirmation.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { RecuperarSenhaComponent } from './login/recuperar-senha/recuperar-senha.component';
import { GoogleReturnComponent } from './login/google-return/google-return.component';
import { PoliticaPrivacidadeComponent } from './home/politica-privacidade/politica-privacidade.component';
import { FacebookReturnComponent } from './login/facebook-return/facebook-return.component';
import { TestRedirectComponent } from './testRedirect/test-redirect/test-redirect.component';
import { AlterarSenhaComponent } from './cliente/perfil-cliente/alterar-senha/alterar-senha.component';
import { MedicoOnlineComponent } from './cliente/medico-online/medico-online.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AuthGuardPrestadorService } from './guards/auth-guard.service';
import { EinsteinConectaComponent } from './home/einstein-conecta/einstein-conecta.component';
import { RedeParceiroComponent } from './home/rede-parceiro/rede-parceiro.component';
import { FaleConoscoComponent } from './home/fale-conosco/fale-conosco.component';
import { LoginComponent } from './login/login.component';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { MeusAgendamentosComponent } from './cliente/meus-agendamentos/meus-agendamentos.component';
import { PrestadorCheckinComponent } from './pestador/prestador-checkin/prestador-checkin.component';
import { AuthGuardClienteService } from './guards/auth-guard-cliente';
import { PerfilClienteComponent } from './cliente/perfil-cliente/perfil-cliente.component';
import { PrestadorHistoricoAgendamentosComponent } from './pestador/prestador-historico-agendamentos/prestador-historico-agendamentos.component';
import { PrestadorConfiguracoesComponent } from './pestador/prestador-configuracoes/prestador-configuracoes.component';
import { DependentesComponent } from './cliente/dependentes/dependentes.component';
import { MeusDependentesComponent } from './cliente/dependentes/meus-dependentes/meus-dependentes.component';
import { QuemSomosComponent } from './home/quem-somos/quem-somos.component';
import { MeusBeneficiosComponent } from './cliente/meus-beneficios/meus-beneficios.component';
import { ConfirmarEmailComponent } from './login/confirmar-email/confirmar-email.component';
import { RedeParceirosNewComponent } from './home/rede-parceiros-new/rede-parceiros-new.component';
import { AlterarPlanoComponent } from './cliente/alterar-plano/alterar-plano.component';
import { WebviewRedirectComponent } from './webview-redirect/webview-redirect.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'medico-online', component: EinsteinConectaComponent},
  {path: 'rede-parceiros', component: RedeParceirosNewComponent},
  {path: 'contato', component: FaleConoscoComponent},
  {path: 'quem-somos', component: QuemSomosComponent},
  {path: 'email-confirmation', component: ConfirmarEmailComponent},
  {path: 'agendamentos', component: AgendamentoComponent, canActivate: [AuthGuardClienteService]},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'reset-password-confirmation', component: ResetPasswordConfirmationComponent},

  /* clientes */

  {path: 'clientes-meus-dependentes', component: MeusDependentesComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-dependentes', component: DependentesComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-dependentes/:idDependente', component: DependentesComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-meus-agendamentos', component: MeusAgendamentosComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-perfil', component: PerfilClienteComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-altera-senha', component: AlterarSenhaComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-medico-online', component: MedicoOnlineComponent, canActivate: [AuthGuardClienteService]},
  {path: 'clientes-meus-beneficios', component: MeusBeneficiosComponent, canActivate: [AuthGuardClienteService]},
  {path: 'alterar-plano', component: AlterarPlanoComponent, canActivate: [AuthGuardClienteService]},
  {path: 'plano-upgrade', component: PagamentoRegraComponent, canActivate: [AuthGuardClienteService]},
  {path: 'plano-upgrade-success', component: PlanoUpgradeSuccessComponent, canActivate: [AuthGuardClienteService]},


  /* prestador */

  {path: 'prestador-chekin', component: PrestadorCheckinComponent, canActivate: [AuthGuardPrestadorService]},
  {path: 'prestador-historico-agendamentos', component: PrestadorHistoricoAgendamentosComponent, canActivate: [AuthGuardPrestadorService]},
  {path: 'prestador-configuracoes', component: PrestadorConfiguracoesComponent, canActivate: [AuthGuardPrestadorService]},
  {path: 'login', component: LoginComponent},

  {path: 'testRedirect', component: TestRedirectComponent},

  /* Facebook Login */
  {path: 'returnfb', component: FacebookReturnComponent},

  /* Google Login */
  {path: 'returngoogle', component: GoogleReturnComponent},

  /* Apple Login - Somente ios12 */
  {path: 'appleios12', component: Ios12loginComponent},

  {path: 'politica-privacidade', component: PoliticaPrivacidadeComponent},

  {path: 'webview-redirect', component: WebviewRedirectComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, initialNavigation: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
