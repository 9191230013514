import { StorageUtil } from 'src/app/utils/storage';

export class Config {

    public static userRequest() {
        return { nome: '',
                 nome_usuario: '',
                 login: '',
                 telefone: '',
                 foto: '' };
    }

   public static payloadSenha( senha ): any {
      return {
         login: StorageUtil.getUser().login,
         senha,
         nome: StorageUtil.getUser().nome
      };
   }

   public static payloadCliente() {
      return {
         id: '',
         nome: '',
         email: '',
         telefones: Array<Telefone>(),
         plano_id : '',
         enderecos: Array(),
         data_nascimento: '',
         genero: '',
         cpf: '',
         plano: '',
         plano_validade: null,
         plano_valor: '',
         plano_nome: ''
      };
   }

}

export class Telefone {
   dataInclusao;
   ddd;
   id;
   numero;
}
