import { Component, OnInit, Input } from '@angular/core';
import { StorageUtil } from 'src/app/utils/storage';
import { Router } from '@angular/router';
import { LoadStyle } from '../loadStyle';

declare var $;

@Component({
  selector: 'app-menu-letareal-prestador',
  templateUrl: './menu-letareal-prestador.component.html',
  styleUrls: ['./menu-letareal-prestador.component.css']
})
export class MenuLetarealPrestadorComponent implements OnInit {

  @Input() active;

  online = StorageUtil.getUser() != null;
  agendamentos = '';
  historico = '';
  config = '';

  constructor(private router: Router) { }

  /* public openMobile( ){
     this.display == '' ? this.display = 'block' : this.display = '';
     this.display == '' ? $('.main-page').removeClass('mobile') : $('.main-page').addClass('mobile');
  } */

  public activeMenu() {
    switch (this.active) {

        case 'agend':
        this.agendamentos = 'active';
        break;

        case 'hist':
        this.historico = 'active';
        break;

        case 'config':
          this.config = 'active';
          break;
    }
  }

  public openDropDown() {
    $('.modalOpen').click();
  }

  public logout() {
    StorageUtil.clearSession();
    this.router.navigate(['']);
    StorageUtil.cleanAgendamento();
  }

  ngOnInit() {
    LoadStyle.setPerfil('PRESTADOR');
    this.activeMenu();
  }
}
