import { ErrorReturnUtil } from './../../utils/errorReturnUtil';
import { UserService } from './../../service/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MsgUtil } from 'src/app/utils/msgUtil';

@Component({
  selector: 'app-confirmar-email',
  templateUrl: './confirmar-email.component.html',
  styleUrls: ['./confirmar-email.component.css']
})
export class ConfirmarEmailComponent implements OnInit, OnDestroy {

  temporarytoken: string;
  public subs: Subscription;

  constructor(public route: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.subs = this.route.queryParams.subscribe(params => {
      this.temporarytoken = params.token;
      if (this.temporarytoken == null) {
        this.router.navigate(['/login']);
      } else {
        this.validateAccount(this.temporarytoken);
      }
    });

  }

  private validateAccount(token: string) {
    this.userService.validateEmail(token).subscribe(res => {
      console.log(res);
    }, err => {
      ErrorReturnUtil.buildError(err);
      this.router.navigate(['/login']);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
