import { UserService } from 'src/app/service/user.service';
import { OAuthService } from './../o-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { StorageUtil } from '../utils/storage';
import { Role } from '../interfaces/role';
import { MsgUtil } from '../utils/msgUtil';

@Component({
  selector: 'app-webview-redirect',
  templateUrl: './webview-redirect.component.html',
  styleUrls: ['./webview-redirect.component.css']
})
export class WebviewRedirectComponent implements OnInit {

  jwt: string;
  route: string;
  userId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private oAuthService: OAuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.checkParams();
  }

  checkParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.t || !params.r) {
        MsgUtil.msgErrorToast('Os parâmetros não foram informados.');
        this.router.navigate(['/login']);
        return;
      }
      this.jwt = params.t;
      this.route = params.r;
      this.checkJwt();
    });
  }

  checkJwt() {
    let decodedJwt = this.oAuthService.getDecodedAccessToken(this.jwt);
    if (!decodedJwt) {
      MsgUtil.msgErrorToast('O token é inválido.');
      this.router.navigate(['/login']);
      return;
    }
    let exp = new Date(decodedJwt.exp * 1000);
    let dateNow = new Date();
    if (dateNow > exp) {
      MsgUtil.msgErrorToast('O token está expirado.');
      this.router.navigate(['/login']);
      return;
    }
    this.userId = decodedJwt['system-user'];
    this.login();
  }

  login() {
    StorageUtil.setTipoLogin(Role.Cliente);
    StorageUtil.setJwt(this.jwt);
    this.userService.redirectAfterLogin(this.userId, this.route);
  }

}
