import { EpharmaService } from './../../service/epharma.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { zip } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { ChangePassword } from 'src/app/interfaces/change-password';
import { Customer } from 'src/app/interfaces/customer-interface';
import { Prospect } from 'src/app/interfaces/prospect-interface';
import { RegisterCustomer } from 'src/app/interfaces/register-customer';
import { RegisterProspect } from 'src/app/interfaces/register-prospect';
import { User } from 'src/app/interfaces/user-interface';
import { ApiService } from 'src/app/service/api.service';
import { OtpService } from 'src/app/service/otp.service';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { ConfirmModalComponent } from '../../modals/confirm/confirm-modal.component';
import { PlanOutput } from '../../service/outputs/plan.output';
import { SubscriptionResourceService } from '../../service/subscription-resource.service';
import { StorageUtil } from '../../utils/storage';
import { UserData } from './../../interfaces/userData-interface';
import { UserService } from './../../service/user.service';
import { Config } from './request';
import { MustMatch } from './validators';




declare var $;

@Component({
  selector: 'app-perfil-cliente',
  templateUrl: './perfil-cliente.component.html',
  styleUrls: ['./perfil-cliente.component.css']
})
export class PerfilClienteComponent implements OnInit {

  user = Config.payloadCliente();
  provider = true;

  profileForm: FormGroup;
  profileFormsubmitted = false;

  passwordForm: FormGroup;
  passwordFormsubmitted = false;

  customer$: Observable<any>;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  // Contact Number Validation
  contactNumberValidation = false;
  contactNumberValidated = false;
  contactNumberValidationToken;
  contactNumberCodeByUser;

  // PDF carteirinha
  enablePdfCarteirinha = false;
  carteirinhaCpf = this.userService.currentUserValue.prospect.documentNumber;
  carteirinhaNome = this.userService.currentUserValue.user.name;

  constructor(
    private service: ApiService,
    public router: Router,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private userService: UserService,
    private userDataModalService: UserDataModalService,
    private opt: OtpService,
    private epharmaService: EpharmaService
  ) { }

  ngOnInit() {
    this.customer$ = this.userService.getCurrentUser();
    console.log('customer$', this.customer$);
    this.loadForms();
    this.setValueForms();

    //  if (this.userService.isAuthorized()) {
    this.getPlan();
    //  }
    this.loadUserDataSubscription();
  }


  private getPlan() {
    const tipoPlan: PlanOutput = this.userService.getPlanType();
    const nomePlan = this.userService.currentUserValue.plan.name;
    const dt = new Date(this.userService.currentUserValue.subscription.startedAt);
    const date = dt.setMonth(dt.getMonth() + 6);

    console.log('tipo Plan', tipoPlan);
    switch (tipoPlan) {
      case PlanOutput.TOP:
        this.user.plano = 'TOP';
        this.user.plano_nome = nomePlan === 'Plano top' ? 'TOP' : nomePlan;
        this.user.plano_valor = `R$ ${this.userService.currentUserValue.plan.price.replace('.', ',')}`;
        this.user.plano_validade = date;
        break;

      case PlanOutput.BASICO:
        this.user.plano = 'BÁSICO';
        this.user.plano_nome = 'BÁSICO';
        this.user.plano_valor = 'GRÁTIS';
        break;
    }
  }

  loadForms(): void {

    // Profile Form
    this.profileForm = this.formBuilder.group({
      nome: [{ value: '', disabled: true }, Validators.required],
      data_nascimento: [{ value: '', disabled: true }, Validators.required],
      celular: ['', Validators.required],
      email: ['', Validators.required],
      cpf: [{ value: '', disabled: true }, Validators.required],
      genero: ['']
    }, {
      //  validator: MustMatch('password', 'confirmPassword')
    });

    // Password Form
    this.passwordForm = this.formBuilder.group({
      senha_atual: ['', Validators.required],
      nova_senha: ['', Validators.required],
      confirmacao_senha: ['', Validators.required]
    }, {
      validator: MustMatch('nova_senha', 'confirmacao_senha')
    });
  }

  contactNumberStartValidation() {
    Spinner.spinner(document, true);
    this.contactNumberValidation = true;
    this.profileFormControl.celular.disable();
    this.opt.challengeAsync(this.profileFormControl.celular.value).subscribe(token => {
      this.contactNumberValidationToken = token.token;
    }, err => MsgUtil.msgError('Houve um erro ao enviar para este número'),
      () => Spinner.spinner(document)
    );
  }

  contactNumberCheckValidation(e: string) {
    if (e.length === 4) {
      Spinner.spinner(document, true);
      this.opt.validateAsync(e, this.profileFormControl.celular.value, this.contactNumberValidationToken).subscribe(res => {
        if (res) {
          this.contactNumberValidated = true;
          MsgUtil.msgSuccessToast('Telefone validado!');
        } else {
          ErrorReturnUtil.buildError(null, 'Houve um erro ao validar!');
        }
      }, err => {
        console.log(err);
        ErrorReturnUtil.buildError(err, 'Houve um erro ao validar!');
      }, () => Spinner.spinner(document));
    }
  }

  setValueForms(): void {
    this.userService.getUserFull(this.userService.currentUserValue.user.id).subscribe((userData: UserData) => {

      if (userData.user.provider) {
        if (userData.user.provider !== 'LOCAL') { this.provider = false; }
      }

      const dataFormatada = this.datepipe.transform(userData.customer.birthDate, 'dd/MM/yyyy');
      let gender;
      switch (userData.customer.sex) {
        case 'MALE': {
          gender = 'Masculino';
          break;
        }
        case 'FEMALE': {
          gender = 'Feminino';
          break;
        }
      }


      // Profile Form
      this.profileForm.patchValue({
        nome: userData.user.name,
        data_nascimento: dataFormatada,
        celular: userData.customer.contactNumber,
        email: userData.prospect.email,
        cpf: userData.prospect.documentNumber,
        genero: gender
      });
    });
  }



  // convenience getter for easy access to form fields
  get profileFormControl() {
    return this.profileForm.controls;
  }

  get passwordFormControl() {
    return this.passwordForm.controls;
  }

  profileFormSubmit() {
    this.profileFormsubmitted = true;

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }

    // Phone verification
    if (!this.contactNumberValidated) {
      return;
    }

    console.log('form value', this.profileForm.getRawValue());

    this.userService.getUserFull(this.userService.currentUserValue.user.id).subscribe((userData: UserData) => {
      const customer = {
        contactNumber: this.profileForm.getRawValue().celular,
        email: this.profileForm.getRawValue().email,
      };

      Spinner.spinner(document, true);
      this.userService.registerCustomer(userData.customer.id, customer).subscribe((r) => {
        MsgUtil.msgSuccessToast('Dados atualizados com sucesso.');
        console.log('customer', r);
      }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro na atualização dos dados. Por favor, tente novamente mais tarde.'),
        () => { Spinner.spinner(document); }
      );
    });
  }

  reenviarCarteirinha() {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);
      this.userService.cardsResend(this.userService.currentUserValue.user.id).subscribe((r: Response) => {
        MsgUtil.msgSuccessToast('Enviamos a carteirinha via SMS para o número do seu celular.');
      }, (error: HttpErrorResponse) => {
        console.log(error);
        ErrorReturnUtil.buildError(error);
      }, () => Spinner.spinner(document));
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  pdfCarteirinha() {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);
      this.userService.getUserFull(this.userService.currentUserValue.user.id).subscribe((userData: UserData) => {
        this.carteirinhaCpf = userData.prospect.documentNumber;
        this.carteirinhaNome = userData.user.name;
        this.enablePdfCarteirinha = true;
      }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro na geração da carteirinha. Por favor, tente novamente mais tarde.'),
        () => { Spinner.spinner(document); }
      );
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  pdfCarteirinhaReturn() {
    this.enablePdfCarteirinha = false;
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  passwordFormSubmit() {
    this.passwordFormsubmitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    const payloadPassword: ChangePassword = {
      newPassword: this.passwordForm.value.nova_senha,
      oldPassword: this.passwordForm.value.senha_atual,
      passwordConfirmation: this.passwordForm.value.confirmacao_senha
    };

    // Patch Password
    this.userService.changePassword(payloadPassword).subscribe((user: User) => {
      if (user) {
        MsgUtil.msgSuccessToast('Senha alterada com sucesso.');
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
      MsgUtil.msgErrorToast('Ocorreu um erro na atualização dos dados. Por favor, tente novamente mais tarde.');
    });
  }


  private updateDataUserLogIn(): void {
    this.userService.updateSubject();
  }

  activeEpharma() {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);
      this.epharmaService.active(this.userService.currentUserValue.customer.id).subscribe(async (r: any) => {
        MsgUtil.msgSuccessToast('Você já pode utilizar o seu benefício em farmácias.');
        await this.userService.updateSubject();
      }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro ativação do seu benefício em farmácias. Por favor, tente novamente mais tarde.'),
        () => { Spinner.spinner(document); }
      );
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  /*  public loadUserById(): void {

      this.service.getById(StorageUtil.getUserPerfil(), 'CLIENTE')
        .subscribe((r: any) => {

          let telefone = new Telefone();

          if (r.telefones.length > 0) {
            this.telefone = r.telefones[0].ddd.toString() + r.telefones[0].numero.toString();

            telefone.dataInclusao = r.telefones[0].dataInclusao;
            telefone.ddd = r.telefones[0].ddd;
            telefone.id = r.telefones[0].id;
            telefone.numero = r.telefones[0].numero;
            this.objEdit.telefones = [telefone];
          }
          this.objEdit.nome = r.nome;
          this.id = r.id;
          this.objEdit.plano_id = r.plano.id;
          this.objEdit.email = r.email;
          this.objEdit.enderecos = r.enderecos;
          this.objEdit.data_nascimento = r.dataNascimento;
          console.log(this.objEdit);
          console.log(r);
        });
    } */

  public update() {
    /*
        if(this.telefone){
          this.objEdit.telefones[0].ddd = this.telefone.substring(0, 2);
          this.objEdit.telefones[0].numero = this.telefone.substring(2, 13);
        }

        this.service.path = this.id;
         this.service.put(this.objEdit, 'CLIENTE').subscribe((r) => {
           MsgUtil.msgSuccessToast('Perfil atualizado com sucesso')
         }); */
  }

  /* converter base64 */

  OnDestroy() {
    this.service.path = '';
  }
}
