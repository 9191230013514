import { DatePipe } from '@angular/common';

export class FormateDate {
    static formateData(data: any) {
        const dataPipe = new DatePipe('en-US');
        return dataPipe.transform(data, 'yyyy-MM-dd');
    }

    static formateDataRegister(data: any) {

        if (data.indexOf('-') > -1) {
            return data;
        }
        return FormateDate.parse(data) + 'T' ;
    }

    /* 2020-01-01T10:30:00 */

    static formateDataYMD(data: any) {
        const dataPipe = new DatePipe('en-US');
        return dataPipe.transform(data, 'yyyy/MM/dd');
    }

    static formateDateHours(data: any) {
      const dataPipe = new DatePipe('en-US');
      return dataPipe.transform(data, 'hh:mm a');
    }

    static formateDataDDMMAA(data: any) {
        const dataPipe = new DatePipe('en-US');
        return dataPipe.transform(data, 'dd/MM/yyyy');
    }

    static formateDataDDMMAAByString(date: string) {
        const dt = new Date(date);
        const dataPipe = new DatePipe('en-US');
        return dataPipe.transform(dt, 'dd/MM/yyyy');
    }

    static parse(date: string): any {

        date = date.replace(/\D+/g, '');

        const dd = date.substring(0, 2);
        const mm = date.substring(2, 4) + '-';
        const yy = date.substring(4, 9) + '-';
        const result = yy + mm + dd;

        return result;
    }

    public static invalidDate(date: any): boolean {

        if (date == null || date === '' || date === undefined) {
            return true;
        }

        let d: any;

        if (date.length !== 10) {
            return true;
        }

        if (date.indexOf('-') > -1) {

            d = date;
        } else {
            d = FormateDate.parse(date);
        }

        d = new Date(d);

        if (d === 'Invalid Date') {
            return true;
        }

        return false;
    }
}
