import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-carteirinha-pdf',
  templateUrl: './carteirinha-pdf.component.html',
  styleUrls: ['./carteirinha-pdf.component.css']
})
export class CarteirinhaPDFComponent implements OnInit {
  @ViewChild('carteirinha', { static: true }) carteirinha: ElementRef;

  @Input() nome;
  @Input() cpf;
  @Output() componenteFinalizado = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    this.pdfCarteirinha();
  }

  pdfCarteirinha() {
    const DATA = this.carteirinha.nativeElement;
    const doc: jsPDF = new jsPDF('p', 'pt', 'letter');
    doc.html(DATA, {
      callback: (returnPdfCallback) => {
        // doc.output("dataurl");
        returnPdfCallback.save('Carteirinha_Doutorpass_' + this.nome.replaceAll(' ', '_') + '.pdf');
        this.componenteFinalizado.emit(true);
      }
    });
  }

}
