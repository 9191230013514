import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { EpharmaService } from 'src/app/service/epharma.service';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { UserService } from 'src/app/service/user.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { Config } from './request';

declare var $;

@Component({
  selector: 'app-meus-beneficios',
  templateUrl: './meus-beneficios.component.html',
  styleUrls: ['./meus-beneficios.component.css']
})
export class MeusBeneficiosComponent implements OnInit {
  user = Config.payloadCliente();
  customer$: Observable<any>;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  constructor(
    private service: ApiService,
    public router: Router,
    private userService: UserService,
    private userDataModalService: UserDataModalService,
    private epharmaService: EpharmaService
  ) { }

  ngOnInit() {
    this.customer$ = this.userService.getCurrentUser();
    this.user.plano = this.userService.getPlanType();
    this.loadUserDataSubscription();
  }

  activeEpharma() {
    if (this.userDataIsComplete) {
      Spinner.spinner(document, true);
      this.epharmaService.active(this.userService.currentUserValue.customer.id).subscribe(async (r: any) => {
        MsgUtil.msgSuccessToast('Você já pode utilizar o seu benefício em farmácias.');
        await this.userService.updateSubject();
      }, err => ErrorReturnUtil.buildError(err, 'Ocorreu um erro ativação do seu benefício em farmácias. Por favor, tente novamente mais tarde.'),
        () => { Spinner.spinner(document); }
      );
    } else {
      this.userDataModalService.checkUserDataInitial(true);
    }
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
    });
    this.userDataModalService.checkUserDataInitial(false);
  }

  OnDestroy() {
    this.service.path = '';
  }
}
