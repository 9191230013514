import { SocialPayload } from './../interfaces/social-payload';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChangePassword } from '../interfaces/change-password';
import { RegisterCustomer } from '../interfaces/register-customer';
import { RegisterCustomerFull } from '../interfaces/register-customer-full';
import { RegisterProspect } from '../interfaces/register-prospect';
import { Role } from '../interfaces/role';
import { UserData } from '../interfaces/userData-interface';
import { OAuthService } from '../o-auth.service';
import { HttpUtil } from '../utils/http-util';
import { MsgUtil } from '../utils/msgUtil';
import { Spinner } from '../utils/stpiner';
import { environment } from './../../environments/environment';
import { Headers } from './../interfaces/headers';
import { StorageUtil } from './../utils/storage';
import { PlanOutput } from './outputs/plan.output';

declare var $;

@Injectable({
  providedIn: 'root'
})

export class UserService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  public currentUserSubject = new BehaviorSubject<UserData>(null);


  // Login
  userLogin: UserData;

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private httputil: HttpUtil,
    private router: Router
  ) {
    if (StorageUtil.getUser()) {
      this.currentUserSubject.next(StorageUtil.getUser());
      this.updateSubject();
    }

  }




  getUser(id): Observable<any> {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    const user = this.http.get(`${environment.urlBase}users/${id}`, requestOptions);
    return user;
  }

  patchUser(userInfo, id) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.patch(`${environment.urlBase}customers/${id}`, userInfo, requestOptions).pipe();
  }

  registerCustomerFull(registerCustomer: RegisterCustomerFull) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}open/register-customer/`, registerCustomer, requestOptions);
  }

  registerCustomer(idCustomer: string, data: RegisterCustomer) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.patch(`${environment.urlBase}customers/` + idCustomer, data, requestOptions);
  }

  registerProspect(idProspect: string, data: RegisterProspect) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.put(`${environment.urlBase}prospects/` + idProspect, data, requestOptions);
  }

  changePassword(payload: ChangePassword) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.patch(`${environment.urlBase}users/change-password`, payload, requestOptions);
  }

  getCurrentUser(): Observable<any> {
    console.log('getCurrentUser', this.currentUserSubject.value);
    return this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserData {
    return this.currentUserSubject.value;
  }

  public isAuthorized() {
    if (StorageUtil.getJwt()) {
      if (this.currentUserSubject.value) {
        return true;
      } else {
        this.updateSubject();
      }
    } else { this.logout(); }
  }

  async updateSubject(): Promise<void> {
    return await new Promise(async (resolve) => {
      Spinner.spinner(document, true);
      const tokenDecoded = this.oAuthService.getDecodedAccessToken(StorageUtil.getJwt());
      const userId = tokenDecoded['system-user'];
      const userData = await this.getUserFull(userId).toPromise();
      console.log('here', userData);
      this.currentUserSubject.next(userData as (UserData));
      StorageUtil.setUser(userData);
      console.log('currentUserValue - updateSubject', this.currentUserValue);
      // return true;
      Spinner.spinner(document);
      resolve();
    });
  }

  hasRole(role: Role) {
    return this.isAuthorized() && this.currentUserValue.user.profileNames.find(papel => papel.toLocaleLowerCase() === role.toLocaleLowerCase());
  }

  getUserFull(id: string) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}open/customer-data/${id}`, requestOptions);
  }

  socialLogin(data: SocialPayload): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}social/login`, data, requestOptions);
  }

  redirectAfterLogin(userId, route?) {
    // Get user Info
    this.getUserFull(userId).subscribe(

      (userData: UserData) => {
        console.log('HERE', userData);
        this.userLogin = userData;
        this.currentUserSubject.next(this.userLogin);
        StorageUtil.setShowTopIncentive(userData.plan.topIncentive);
        if (this.userLogin.user.firstLogin) {
          $('.modalTermos').click();
        } else {
          this.validatePerfil(route);
        }
      },
      err => {
      }
    );
  }

  validatePerfil(defaultRoute?): void {
    // check if the profile is valid
    const profileIsValid = this.profileIsValid(this.userLogin.user.profileNames);
    if (!profileIsValid) {
      MsgUtil.msgErrorToast('Verifique se você tem permissão para logar no sistema.');
      return;
    }

    let r = !defaultRoute ? 'clientes-medico-online' : defaultRoute;

    StorageUtil.setUser(this.userLogin);
    if (StorageUtil.getAgendamento()) {
      this.router.navigate(['/agendamentos/']);
    } else {
      this.router.navigate([r]);
    }

  }

  profileIsValid(profileNames) {
    const profiles = profileNames;
    if (profiles.find((element: string) => element.toLocaleLowerCase() === 'user')) {
      return true;
    } else {
      return false;
    }
  }

  gopage() {
    $('.modalTermos').click();
    setTimeout(() => {
      this.validatePerfil();
    }, 400);
  }

  validateEmail(token: string) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}users/email-confirmation?token=${token}`, null, requestOptions);
  }


  getPlan() {
    return this.currentUserValue.plan;
  }

  getPlanType(): PlanOutput {
    if (this.currentUserValue.plan.top) {
      return PlanOutput.TOP;
    } else {
      return PlanOutput.BASICO;
    }
  }

  getSchedulesByCustomer(customerId) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.get(`${environment.urlBase}schedules/customer/${customerId}`, requestOptions);
  }

  cardsResend(userId) {
    this.headers.Authorization = 'Bearer ' + StorageUtil.getJwt();
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}cards/users/${userId}/resend`, null, requestOptions);
  }

  resetPassword(data) {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}users/reset-password`, data, requestOptions);
  }

  resetPasswordConfirmation(token, data): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers as any),
    };
    return this.http.post(`${environment.urlBase}users/reset-password-confirmation?token=${token}`, data, requestOptions);
  }


  clearStorage() {
    console.log('CLEAR STORAGE HERE');
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
    StorageUtil.clearJwt();
    this.currentUserSubject.next(null);
  }

  public logout() {
    this.clearStorage();
    window.location.href = environment.externalHome;
  }

}
