import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StorageUtil } from '../utils/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardPrestadorService implements CanActivate {

  constructor( private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (StorageUtil.getUser()) {
      const perfil = StorageUtil.getUser().perfis.find((option) => option.nome === 'Prestador');
      if (perfil) {
        return true;
      }

      }

    this.router.navigate(['/login']);

    return false;

    }
}
