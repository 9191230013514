import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { Headers } from '../interfaces/headers';
import { UserData } from '../interfaces/userData-interface';
import { UpdateUserDataComponent } from '../modals/update-user-data/update-user-data.component';
import { StorageUtil } from '../utils/storage';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataModalService {

  headers: Headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken()
  };

  userDataChanged = new Subject<boolean>();
  userDataComplete = false;

  constructor(private ngbModal: NgbModal, private userService: UserService
  ) { }

  _MOCKUSERDATA() { // REMOVE
    const mock: UserData = {
      customer: {
        birthDate: '',
        contactNumber: '',
        id: '01EZCHTWWXXTR91RR8MNDZ1MTA',
        sex: 'MALE',
        prospectId: 'DASHUREWY89W9DSFHI',
        userId: 'EF89HWH98DJPDEWD90W'
      },
      plan: {
        description: 'Plano top doutorpass',
        id: '01EYZWAXBGV8FS11K4ZZN50ZBJ',
        iuguPlanIndentifier: 'DRPASS_PLANO_TOP',
        name: 'Plano top',
        payableWith: 'all',
        price: '29.90',
        recurrence: 'MONTHLY'
      },
      prospect: {
        documentNumber: '543543543',
        email: 'marcosbruno.mb@gmail.com',
        id: '01EZCHTWS0NSZA0G2PZ18T0ZXZ',
      },
      subscription: {
        customerId: '01EZCHTWWXXTR91RR8MNDZ1MTA',
        id: '01F10Y5KDXD6WV0SAQA7RA1TB4',
        iuguSubscriptionId: '7609C53326CF4DEB889B210E5B19FBEB',
        planId: '01EYZWAXBGV8FS11K4ZZN50ZBJ',
        startedAt: new Date('2014-04-03')
      },
      user: {
        firstLogin: false,
        id: '01EZCHTWW1DMQM1H519KRB1SD3',
        lastLoginAt: '2021-03-26T11:35:08.868933',
        name: 'Marcos Bruno Santos Barroso',
        profileNames: ['user'],
        userName: 'marcosbruno.mb@gmail.com'
      }
    };
    // return of(mock);
    return mock;
  }

  checkUserDataInitial(openModal: boolean) {
    // const userData = this._MOCKUSERDATA();
    const userData = this.userService.currentUserValue;

    const fields = [];
    if (!userData.prospect.documentNumber) { fields.push({ field: 'documentNumber' }); }
    if (!userData.customer.birthDate) { fields.push({ field: 'birthDate' }); }
    if (!userData.customer.contactNumber) { fields.push({ field: 'contactNumber' }); }
    if (!userData.customer.sex) { fields.push({ field: 'sex' }); }

    if (fields.length === 0) {
        this.userDataComplete = true;
        this.userDataChanged.next(this.userDataComplete);
      } else {
        if (openModal) { this.modalUserData(fields, userData.customer.id); }
      }

  }

  modalUserData(fields, customerId) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const userDataModal: NgbModalRef = this.ngbModal.open(UpdateUserDataComponent, ngbModalOptions);
    userDataModal.componentInstance.fields = fields;
    userDataModal.componentInstance.customerId = customerId;
    userDataModal.componentInstance.saveEvent.subscribe((response: boolean) => {
      this.userDataComplete = response;
      this.userDataChanged.next(this.userDataComplete);
    });
  }

  userDataIsComplete() {
    return this.userDataComplete;
  }


}
