import { UserService } from './../../service/user.service';
import { OtpService } from './../../service/otp.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormateDate } from 'src/app/utils/formateDate';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Spinner } from 'src/app/utils/stpiner';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';

@Component({
  selector: 'app-update-user-data',
  templateUrl: './update-user-data.component.html',
  styleUrls: ['./update-user-data.component.css']
})
export class UpdateUserDataComponent implements OnInit {

  @Input() public fields: { field: string }[] = Array();
  @Input() public customerId: string;
  @Output() public saveEvent: EventEmitter<boolean> = new EventEmitter();

  userDataForm: FormGroup;
  userDataFormSubmitted = false;

  // Contact Number Validation
  contactNumberValidation = false;
  contactNumberValidated = false;
  contactNumberValidationToken;
  contactNumberCodeByUser;

  constructor(
    private formBuilder: FormBuilder,
    private ngbActiveModal: NgbActiveModal,
    private opt: OtpService,
    private userService: UserService
  ) {
    this.userDataForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.checkFields();
  }

  checkFields() {
    if (this.fields.find(f => f.field === 'documentNumber')) { this.userDataForm.addControl('documentNumber', new FormControl('', [Validators.required, validateCpf])); }
    if (this.fields.find(f => f.field === 'birthDate')) { this.userDataForm.addControl('birthDate', new FormControl('', [Validators.required, ValidateFutureDate])); }
    if (this.fields.find(f => f.field === 'contactNumber')) { this.userDataForm.addControl('contactNumber', new FormControl('', [Validators.required, ValidateContactNumber])); }
    if (this.fields.find(f => f.field === 'sex')) { this.userDataForm.addControl('sex', new FormControl('', Validators.required)); }
  }

  get userDataFormControls() {
    return this.userDataForm.controls;
  }

  cancel() {
    this.saveEvent.emit(false);
    this.ngbActiveModal.close();
  }

  userDataFormSubmit() {
    this.userDataFormSubmitted = true;

    // stop here if form is invalid
    if (this.userDataForm.invalid) {
      return;
    }

    // Phone verification
  /*  if (this.userDataFormControls.contactNumber) {
      if (!this.contactNumberValidated) {
        return;
      }
    } */

    console.log('form value', this.userDataForm.getRawValue());

    this.userService.registerCustomer(this.customerId, this.userDataForm.getRawValue()).subscribe((r) => {
      MsgUtil.msgSuccessToast('Dados salvos com sucesso!');
      this.saveEvent.emit(true);
      this.ngbActiveModal.close();
    },
      err => ErrorReturnUtil.buildError(err),
      () => Spinner.spinner(document)
    );
  }


  contactNumberStartValidation() {
    Spinner.spinner(document, true);
    this.contactNumberValidation = true;
    this.userDataFormControls.contactNumber.disable();
    this.opt.challengeAsync(this.userDataFormControls.contactNumber.value).subscribe(token => {
      this.contactNumberValidationToken = token.token;
    }, err => MsgUtil.msgError('Houve um erro ao enviar para este número'),
      () => Spinner.spinner(document)
    );
  }

  contactNumberCheckValidation(e: string) {
    if (e.length === 4) {
      Spinner.spinner(document, true);
      this.opt.validateAsync(e, this.userDataFormControls.contactNumber.value, this.contactNumberValidationToken).subscribe(res => {
        if (res) {
          this.contactNumberValidated = true;
          MsgUtil.msgSuccessToast('Telefone validado!');
        } else {
          ErrorReturnUtil.buildError(null, 'Houve um erro ao validar!');
        }
      }, err => {
        console.log(err);
        ErrorReturnUtil.buildError(err, 'Houve um erro ao validar!');
      }, () => Spinner.spinner(document));
    }
  }



}

// Funções de validação

function ValidateFutureDate(control: AbstractControl): { [key: string]: any } | null {
  if (FormateDate.formateData(control.value) > FormateDate.formateData(Date.now())) {
    return { futureDateInvalid: true };
  }
  return null;
}

function validateCpf(control: AbstractControl): { [key: string]: any } | null {
  let cpf = control.value;
  cpf = cpf.replace(/[^\d]+/g, '');

  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return { cpfInvalid: true };
  }
  // Valida 1o digito
  let add = 0;
  let i;
  let rev;
  for (i = 0; i < 9; i++) { add += Number(cpf.charAt(i)) * (10 - i); }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== Number(cpf.charAt(9))) {
    return { cpfInvalid: true };
  }
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) { add += Number(cpf.charAt(i)) * (11 - i); }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== Number(cpf.charAt(10))) {
    return { cpfInvalid: true };
  }
  return null;
}

function ValidateContactNumber(control: AbstractControl): { [key: string]: any } | null {
  if (!control.value || control.value.length < 10) {
    return { digitsInvalid: true };
  }
  return null;
}




