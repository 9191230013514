import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() noFooter;
  url = environment.url_payment;
  constructor(public router: Router) { }

  goUp(value) {

    this.router.navigate([`/${value}`]);

    if (window.location.href.indexOf(value) > -1) {
      setTimeout(() => {
        $('html, body').animate(
          { scrollTop: $('#top').position().top },
          'slow'
        );
      }, 300);
    }
  }

  goExternalHome() {
    window.location.href = environment.externalHome;
  }

  ngOnInit() {
  }
}
