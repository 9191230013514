import { Component, OnInit, Input } from '@angular/core';
import { StorageUtil } from '../utils/storage';
import { Router } from '@angular/router';
import { LoadStyle } from '../pestador/loadStyle';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() active;

  online = StorageUtil.getUser() != null;
  conect = '';
  rede = '';
  falseconosco = '';
  inicio = '';
  qSomos = '';
  areaLogin;
  url = environment.url_payment;
  linkMedicoOnline = '/medico-online';

  constructor(private router: Router) { }

  public activeMenu() {
    switch (this.active) {

      case 'inicio':
        this.inicio = 'active';
        break;

      case 'conect':
        this.conect = 'active';
        break;

      case 'rede':
        this.rede = 'active';
        break;

      case 'qSomos':
        this.qSomos = 'active';
        break;

      case 'falseconosco':
        this.falseconosco = 'active';
        break;
    }
  }

  public openDropDown() {
    $('#navbarDropdownMenuLink-55').click();
  }

  public logout() {
    this.router.navigate(['']);
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
  }

  private loadBtnAreaLogin(): void {

    if (StorageUtil.getUser()) {

      this.linkMedicoOnline = '/clientes-medico-online';

      if (!StorageUtil.getUser().user.name) {
        this.areaLogin = StorageUtil.getUser().user.userName;
      } else {
        this.areaLogin = StorageUtil.getUser().user.name;
      }





    } else {
      this.areaLogin = 'Área de login';
    }
  }

  goToHome() {
    window.location.href = environment.externalHome;
  }

  ngOnInit() {
    this.loadBtnAreaLogin();
    LoadStyle.setPerfil('');
    this.activeMenu();
  }
}
