import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plano-upgrade-success',
  templateUrl: './plano-upgrade-success.component.html',
  styleUrls: ['./plano-upgrade-success.component.css']
})
export class PlanoUpgradeSuccessComponent implements OnInit {

  tipoPagamento;
  bankSlipUrl;
  bankSlipBarCode;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.tipoPagamento = params.tipoPagamento;
      this.bankSlipUrl = params.bankSlipUrl;
      this.bankSlipBarCode = params.bankSlipBarCode;
    });
  }

  ngOnInit() {
    console.log(this.tipoPagamento);
    console.log(this.bankSlipUrl);
    console.log(this.bankSlipBarCode);
  }

  goPerfil() {
    window.location.href = '/clientes-perfil';
  }

}
