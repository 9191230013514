import { FaqCallback } from './../../interfaces/faq-callback';
import { Faq } from './../../interfaces/faq';
import { FaqService } from './../../service/faq.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StorageUtil } from 'src/app/utils/storage';
import { Spinner } from 'src/app/utils/stpiner';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';

@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.css']
})
export class AjudaComponent implements OnInit {

  enableModal = false;
  respostaAtual: Faq = { question: '', response: '' };

  searchTimeout;
  pesquisa;
  perguntas: Faq[];

  constructor(private faqService: FaqService) { }

  ngOnInit() {
  }

  modal() {
    this.loadPerguntas();
    this.enableModal = true;
  }

  loadPerguntas() {
    this.perguntas = [];
    Spinner.spinner(document, true);
    this.faqService.getFaq(this.pesquisa).subscribe((data: FaqCallback) => {
      this.perguntas = data.content;
    }, (error: HttpErrorResponse) => {
      ErrorReturnUtil.buildError(error);
    }, () => Spinner.spinner(document));
  }

  filtrar(event) {
    window.clearTimeout(this.searchTimeout);
    this.searchTimeout = window.setTimeout(() => {
      this.loadPerguntas();
    }, 600);
  }

  verResposta(resposta: Faq) {
    this.respostaAtual = resposta;
  }

  retornarPerguntas() {
    this.respostaAtual = { question: '', response: '' };
  }

  fecharModal() {
    this.perguntas = [];
    this.pesquisa = '';
    this.respostaAtual = { question: '', response: '' };
    this.enableModal = false;
  }
}
