import { UriKey } from 'src/app/utils/uri-key.service';
import { Card } from 'src/app/utils/card';
import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, ɵConsole } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormateDate } from 'src/app/utils/formateDate';
import { StorageUtil } from 'src/app/utils/storage';
import { NgZone } from '@angular/core';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UserCallback } from 'src/app/interfaces/user-callback';
import { PlanService } from 'src/app/service/plan.service';
import { SubscriptionPurchase } from 'src/app/interfaces/subscription-purchase';
import { Plan } from 'src/app/interfaces/plan';
import { UserService } from 'src/app/service/user.service';
import { Spinner } from 'src/app/utils/stpiner';
import { OnDestroy } from '@angular/core';
import { UserDataModalService } from 'src/app/service/user-data-modal.service';
import { SubscriptionBarcodeInterface } from 'src/app/interfaces/subscription-barcode';


declare var cardValidator;
declare var dataLayerPushPayment;
declare var $;
declare var Iugu;
const valorVIda = 49.9;

@Component({
  selector: 'app-plano-upgrade',
  templateUrl: './plano-upgrade.component.html',
  styleUrls: ['./plano-upgrade.component.css']
})

export class PlanoUpgradeComponent implements OnInit, OnDestroy {

  payload = Card.loadpayloadCard();
  card = Card.inputCard();

  data: UserCallback;
  plano: Plan;
  pagador = [this.payload];
  readonly = false;
  btnFinalizar = 'CARD';
  dataVencimento;
  cardValid;
  imgCard: string;
  typeCard: any = '';
  pf = true;
  paymentEvent$ = new EventEmitter<any>();
  paymentEventErro$ = new EventEmitter<any>();
  Subscription = new Subscription();

  valorVida = 29.9;

  // Check User Data is Complete
  userDataSubscription: Subscription;
  userDataIsComplete: boolean = this.userDataModalService.userDataIsComplete();

  constructor(private service: ApiService, private router: Router, private ngZone: NgZone, private planService: PlanService, private userService: UserService, private userDataModalService: UserDataModalService) {
  }

  loadUserDataSubscription() {
    this.userDataSubscription = this.userDataModalService.userDataChanged.subscribe((userDataIsComplete: boolean) => {
      this.userDataIsComplete = userDataIsComplete;
      console.log('userDataIsComplete', this.userDataIsComplete);
      if (!userDataIsComplete) {
        this.ngZone.run(() => {
          MsgUtil.msgErrorToast('Para prosseguir com o pagamento, é obrigatório preencher os campos.');
          this.router.navigate(['/alterar-plano']);
        });
        return;
      }
    });
    this.userDataModalService.checkUserDataInitial(true);
  }

  public tipoPagamento(value) {
    this.btnFinalizar = value;
  }

  public registrarPagador() {

    this.invalidPayload();

    if (this.payload.tipo_pagamento === 'BOLETO') {
      this.boleto();
    } else {
      this.pagamentoMensal();
    }
  }

  private pagamentoMensal() {
    this.generateTokenIugu();
  }

  public setTipoPagamento(event): void {
    this.payload.tipo_pagamento = event;
  }

  public validCard(event) {
    event.target.value = event.target.value.replace(/\D+/g, '');
    const card = cardValidator(event.target.value);
    if (card) {
      const r = Card.getTypeCardId(event.target.value);

      if (r) {
        const x = this.cardValid.find((option) => option.id === r);
        this.imgCard = x.secure_thumbnail;
        this.typeCard = x.id;
      }
    } else {
      MsgUtil.msgErrorToast2('Cartão inválido');
      this.imgCard = '';
      this.typeCard = '';
    }

    if (event.target.value.length < 16) {
      this.imgCard = '';
      this.typeCard = '';
    }
  }

  public generateTokenIugu() {
    $('#submitPagamentoMensal').click();
  }

  private async finalizarPagamento(token) {
    Spinner.spinner(document, true);
    const payload: SubscriptionPurchase = {
      cardToken: token,
      customerId: this.data.customer.id,
      paymentMethod: 'CREDIT_CARD',
      planId: this.plano.id
    };

    const setPlan = await this.planService.setPlanToCustomer(payload).toPromise();
    await this.userService.updateSubject();
    Spinner.spinner(document);
    this.navigateSuccess();
  }

  private invalidPayload() {
    const msg = 'Todos os campos são obrigatórios';
    this.setValueCard();

    if (this.payload.tipo_pagamento !== 'BOLETO') {
      if (this.card.codgo_seguranca_cartao === '') {
        throw EvalError(MsgUtil.msgErrorToast2(msg));
      }

      if (this.card.nome_cartao === '') {
        throw EvalError(MsgUtil.msgErrorToast2(msg));
      }

      if (this.card.numero_cartao === '') {
        throw EvalError(MsgUtil.msgErrorToast2(msg));
      }

      if (this.card.vencimento_cartao === '') {
        throw EvalError(MsgUtil.msgErrorToast2(msg));
      }

      this.payload.parcelas = this.card.parcelas;
    }
  }

  private boleto() {
    let uri = '';
    this.payload.parcelas = '0';
    uri = UriKey.LEADS;
    dataLayerPushPayment('PF', 'Boleto', this.valorVida, 1);
    this.service.boletoSuccess();

    const payload: SubscriptionPurchase = {
      customerId: this.data.customer.id,
      paymentMethod: 'BANK_SLIP',
      planId: this.plano.id
    };

    this.planService.setPlanToCustomer(payload).subscribe(async (r: any) => {
      await this.userService.updateSubject();
      if (r.subscription.pdf) {
        this.planService.getSubscriptionBarcode(r.subscription.id).subscribe((s: SubscriptionBarcodeInterface) => {
          this.navigateSuccess(r.subscription.pdf, s.bankSlip.barcode);
        }, () => {
          this.navigateSuccess(r.subscription.pdf, null);
        });
      } else {
        this.navigateSuccess();
      }
    }, () => {
      $('#erroBtn').click();
    });
  }

  private navigateSuccess(bankSlipUrl?, bankSlipBarCode?) {
    console.log('this.payload.tipo_pagamento', this.payload.tipo_pagamento);
    this.ngZone.run(() => {
      this.router.navigate(['/plano-upgrade-success'], { queryParams: { tipoPagamento: this.payload.tipo_pagamento, bankSlipUrl, bankSlipBarCode } });
    });
  }



  private loadpayment_methods() {
    this.service.loadpayment_methods().subscribe((r) => {
      this.cardValid = r.filter((option) => option.status === 'active');
    });
  }

  private loadFinalizarPagamentoMensal() {
    this.paymentEvent$.subscribe((token) => {
      this.finalizarPagamento(token);
    });
  }

  private loadFinalizarPagamentoMensalError() {
    this.paymentEventErro$.subscribe((error) => {
      this.mapErroIugu(error);
    });
  }

  private mapErroIugu(erro: string) {
    if (erro.indexOf('last_name') > -1) {
      return MsgUtil.msgErrorToast2('Sobrenome é obrigatório');
    }
    if (erro.indexOf('first_name') > -1) {
      return MsgUtil.msgErrorToast2('Verifique se há espaços antes do nome');
    }
    if (erro.indexOf('number') > -1) {
      return MsgUtil.msgErrorToast2('Cartão inválido');
    }
    if (erro.indexOf('expiration') > -1) {
      return MsgUtil.msgErrorToast2('Data inválida');
    }
    if (erro.indexOf('verification_value') > -1) {
      return MsgUtil.msgErrorToast2('CVV inválido');
    }
  }

  private setValueCard() {
    this.card.nome_cartao = $('#nomeCartao').val();
    this.card.codgo_seguranca_cartao = $('#segCode').val();
    this.card.numero_cartao = $('#numeroCartao').val();
    this.card.parcelas = '0';
    this.card.vencimento_cartao = $('#expiration').val();
  }

  private loadPaymentForm() {
    const paymentEvent$ = this.paymentEvent$;
    let token = '';

    Iugu.setAccountID(environment.iugu_account_Id);

    if (!environment.production) {
      Iugu.setTestMode(true);
    }

    const paymentEventErro$ = this.paymentEventErro$;

    $('#payment-form').submit(function(evt) {
      const tokenResponseHandler = function(data) {
        if (data.errors) {
          paymentEventErro$.emit(JSON.stringify(data.errors));
          return;
        }

        token = data.id;
        paymentEvent$.emit(token);
      };
      Iugu.createPaymentToken(this, tokenResponseHandler);
    });
  }

  private loadDateExpiration() {
    const today = new Date();
    const date = today.setDate(today.getDate() + 3);
    this.dataVencimento = FormateDate.formateData(date);
  }

  private loadData() {
    // this.data = StepsUtil.getSteps().userCallback;
    // this.plano = StepsUtil.getSteps().step1.plano;

    this.userService.getCurrentUser().subscribe((r) => {
      this.data = r;
    });

    this.planService.getPlan('Plano top').subscribe((r: Plan) => {
      if (r) {
        this.plano = r;
      }
    });
  }

  ngOnInit() {
    this.loadUserDataSubscription();
    this.loadData();
    this.loadPaymentForm();
    this.loadpayment_methods();
    this.loadFinalizarPagamentoMensal();
    this.loadFinalizarPagamentoMensalError();
    this.loadDateExpiration();
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
  }

}
