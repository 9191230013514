import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/interfaces/faq';
import { FaqCallback } from 'src/app/interfaces/faq-callback';
import { FaqService } from 'src/app/service/faq.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { StorageUtil } from 'src/app/utils/storage';
import { Spinner } from 'src/app/utils/stpiner';

@Component({
  selector: 'app-login-verifica-gratuidade',
  templateUrl: './login-verifica-gratuidade.component.html',
  styleUrls: ['./login-verifica-gratuidade.component.css']
})
export class LoginVerificaGratuidadeComponent implements OnInit {

  usuarioNome: string;
  showModal = false;

  constructor(private faqService: FaqService) { }

  ngOnInit() {
    this.loadUsuarioLogado();
    this.ruleShowModal();
  }

  loadUsuarioLogado() {
    if (!StorageUtil.getUser().user.name) {
      this.usuarioNome = StorageUtil.getUser().user.userName;
    } else {
      this.usuarioNome = StorageUtil.getUser().user.name;
    }
  }

  ruleShowModal() {
    if (StorageUtil.getShowTopIncentive() === 'true') {
      this.showModal = true;
      StorageUtil.setShowTopIncentive('false');
    }
  }

  fecharModal() {
    this.showModal = false;
  }

}
