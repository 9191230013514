import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { StorageUtil } from 'src/app/utils/storage';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

declare var $;

const OPTIONSUF = [
  { id: '3463930', key: 'ES', value: 'Espirito Santo', status: true },
  { id: '3395443', key: 'MA', value: 'Maranhão', status: false },
  { id: '3392213', key: 'PI', value: 'Piauí', status: false },
  { id: '3455077', key: 'PR', value: 'Paraná', status: false },
];

const OPTIONS = [
  { key: 'CONSULTA', value: 'Consultas', status: false },
  { key: 'EXAME', value: 'Exame', status: false },
  { key: 'HOSPITAIS', value: 'Hospitais', status: false },
  { key: 'FARMACIAS', value: 'Farmácias', status: false },
  { key: 'ACADEMIAS', value: 'Academias', status: false },
  { key: 'OUTROS_PARCEIROS', value: 'Outros', status: false },
];

@Component({
  selector: 'app-rede-parceiro',
  templateUrl: './rede-parceiro.component.html',
  styleUrls: ['./rede-parceiro.component.css'],
})
export class RedeParceiroComponent implements OnInit {
  online = StorageUtil.getUser() != null;

  cidadeEscolhida = '';
  listaEspecialidades = [];
  filteredBrands2;
  tamanhoListaPrestador;
  inputBlock;
  buscaEfetuada;
  nomeEspecialidade = null;
  listaCidades = [];
  filterCidades = [];
  listaPrestadores = [];
  especialidadeEscolhida;
  pagination = { size: 10, page: 0 };
  options = OPTIONS;
  consultaRapida = null;
  optionsUf = OPTIONSUF;
  estadoId;
  smartfitShow = false;
  estados = new Array();
  page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  user = { plano: '' };

  constructor(private service: ApiService, private router: Router) {}

  private loadEspecialidades() {
    this.service.get('ESPECIALIDADE').subscribe((r: any) => {
      this.listaEspecialidades = r.especialidades;
    });
  }

  public clearFilter() {
    this.options.forEach((action: any) => {
      action.status = false;
    });
    this.optionsUf.forEach((action: any) => {
      if (action.id === '3463930') {
        action.status = true;
      } else {
        action.status = false;
      }
    });

    $('#AutoC').val('');
    $('#city').val('');
    $('#consulta_rapida').val('');
    this.cidadeEscolhida = null;
    this.consultaRapida = null;
    this.nomeEspecialidade = null;
    this.resetPagination();
    this.getPrestador();
  }

  public getPrestador(): void {
    this.service.params = this.buildParametrosPrestador();

    this.service.get('PRESTADOR').subscribe((r: any) => {
      this.page = r.page;
      this.pagination.page = this.page.number;
      this.pagination.size = this.page.size;
      this.buildPrestadores(r);
    });
  }

  public showValueById(id) {
    const i = this.listaPrestadores.findIndex((option: any) => option.id === id);
    this.listaPrestadores[i].showValue = true;
    this.listaPrestadores.forEach((action: any) => {
      if (action.id !== id) {
        action.showValue = false;
      }
    });
  }

  /* HARDCODE */

  private buildV1HardCode(): any {
    if (this.estadoId === 'ES' && this.options[5].status) {
      return {
        nomeEspecialidade:
          'Cliente Doutorpass, agora tem descontos na V1 Aplicativo de Transporte!',
        img: 'assets/img/v1.png',
        subtitle: 'Com motoristas profissionais e frota própria.',
        tipoOperacao:
          'Utilize o código DOUTORPASSV1 quando for solicitar uma viagem no aplicativo e ganhe 25% de desconto.',
      };
    }

    return false;
  }

  private buildMedicoOnlineHardCode(): any {
    if (this.options[0].status) {
      return {
        nomeEspecialidade: 'Médico online',
        img: 'assets/img/medicoonline.png',
        subtitle:
          'Médico On-line é uma plataforma de telemedicina no qual o cliente terá acesso a médicos altamente qualificados em duas modalidades de serviços.',
        tipoOperacao: '',
        medicoOnline: true,
      };
    }

    return false;
  }

  /* end */

  public activeOptionsUf(value) {
    $('#city').val('');
    const i = this.optionsUf.findIndex((option: any) => option.value === value);
    this.optionsUf[i].status = true;
    this.optionsUf.forEach((action: any) => {
      if (action.value !== value) {
        action.status = false;
      }
    });

    this.cidadeEscolhida = null;
    this.resetPagination();
    this.getPrestador();
    this.estadoId = this.optionsUf[i].key;
    this.loadCCidades();
    this.buildV1HardCode();
  }

  public selectCityByUf(event) {
    $('#city').val('');
    this.cidadeEscolhida = null;
    this.estadoId = event.target.value;
    this.loadCCidades();
    this.getPrestador();
  }

  public activeOptions(value) {
    console.log(value);
    const i = this.options.findIndex((option: any) => option.value === value);
    if (this.options[i].status) {
      this.options[i].status = false;
    } else {
      this.options[i].status = true;
    }
    this.smartfit(value);
    this.verifyOptionsNoSelect();

    this.resetPagination();
    this.getPrestador();
    this.buildV1HardCode();
    this.buildMedicoOnlineHardCode();
  }

  private smartfit(value) {
    if (
      this.options.find(
        (opt) =>
          (opt.value === value &&
            opt.key === 'ACADEMIAS' &&
            opt.status === true) ||
          (opt.key === 'ACADEMIAS' && opt.status === true)
      )
    ) {
      this.smartfitShow = true;
    } else {
      this.smartfitShow = false;
    }
  }

  private buildParametrosPrestador() {
    let tipoOperacao: string = null;
    this.options.forEach((action: any) => {
      if (action.status) {
        if (tipoOperacao == null) {
          tipoOperacao = '';
        } else {
          tipoOperacao = tipoOperacao + ',';
        }
        tipoOperacao = tipoOperacao + action.key + ',';
        const i = tipoOperacao.lastIndexOf(',');
        if (i > -1) {
          tipoOperacao = tipoOperacao.substring(0, tipoOperacao.length - 1);
        }
      }
    });

    /* let uf = this.optionsUf.find((options: any) => options.status); */

    return {
      uf: this.estadoId,
      tipoOperacao,
      nomeEspecialidade: this.nomeEspecialidade,
      cidade: this.cidadeEscolhida,
      consultaRapida: this.consultaRapida,
      page: this.pagination.page,
      size: this.pagination.size,
    };
  }

  public setCidate(event?) {
    if (event) {
      this.cidadeEscolhida = event;
    } else {
      this.cidadeEscolhida = null;
    }
    this.resetPagination();
    this.getPrestador();
  }

  public filterBrands2(event) {
    this.filteredBrands2 = [];
    for (const especialidade of this.listaEspecialidades) {
      let brand: any = especialidade.nome;
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        brand = {
          id: especialidade.id,
          nome: especialidade.nome,
          detalhe: especialidade.detalhe,
        };
        this.filteredBrands2.push(brand);
      }
    }
  }

   public filtercity(event) {
    this.filterCidades = [];
    for (const cidade of this.listaCidades) {
      const brand: any = cidade.municipio.nome;
      if (brand !== undefined) {
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
          const c = this.filterCidades.findIndex((option) => option === brand);
          if (c < 0) {
            this.filterCidades.push(brand);
          }
        }
      }
    }
  }




  private loadEstados() {
    this.service.getGeonames('ESTADO').subscribe((r) => {
      $('#city').val('');
      this.estados = r.geonames.sort(function(a, b) {
        return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
      });
    });
  }

  public loadCCidades() {
    this.filterCidades = [];
    this.listaCidades = [];
    this.service
      .getGeonames('ESTADO', `${this.estadoId}/${'cidades'}`)
      .subscribe((r) => {
        this.listaCidades = r.geonames;
        for (const cidade of this.listaCidades) {
          const brand: any = cidade.municipio.nome;
          if (brand !== undefined) {
            this.filterCidades.push(brand);
          }
        }
      });
  }

  public setUserDestination2(value: any): any {
    setTimeout(() => {
      $('#AutoC').val('');
      $('#AutoC').val(value.nome);
      this.nomeEspecialidade = value.nome;
      this.resetPagination();
      this.getPrestador();
    }, 1);
  }

  private resetPagination() {
    this.pagination.page = 0;
    this.pagination.size = 10;
  }

  public resetEspecialidade(event) {
    if (event.target.value.length === 0) {
      this.nomeEspecialidade = null;
      this.resetPagination();
      this.getPrestador();
    }
  }

  public onConsultaRapida() {
    const searchBox = document.getElementById('search');
    const keyup$ = fromEvent(searchBox, 'keyup');

    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe((r) => {
        this.consultaRapida = r;
        this.service.params = this.buildParametrosPrestador();
        this.service.consultFilter('PRESTADOR').subscribe((prestadores) => {
          this.page = prestadores.page;
          this.buildPrestadores(prestadores);
        });
      });
  }

  private buildPrestadores(prestador: any): void {
    const list = [];

    this.setMedicoOnlineHardCode(list);

    for (const item of prestador.prestadores) {
      /* Tratamento para tipo opração == null */

      if (item.especialidades.length > 0) {
        item.especialidades.forEach((element) => {
          if (element.tipoOperacao === 'FARMACIAS') {
            item.tipoOperacao = element.tipoOperacao;
            return;
          }
        });
      }

      /* END */

      let especialide = '';

      if (item.especialidades.length > 0) {
        item.especialidades.forEach((element: any) => {
          especialide = especialide + element.nome + ',  ';
        });
      }

      especialide = especialide.substring(0, especialide.length - 3) + '.';
      list.push({
        nomeEspecialidade: item.nomeEspecialidade,
        id: item.id,
        logradouro: item.endereco.logradouro,
        cidade: item.endereco.cidade,
        uf: item.endereco.uf,
        cep: item.endereco.cep,
        contato: item.contato,
        showValue: false,
        formaPagamento: item.formaPagamento,
        tipoOperacao: item.tipoOperacao,
        nome: item.nome,
        especialidades: especialide,
        valor:
          item.valor != null
            ? item.valor
                .toString()
                .substring(0, item.valor.toString().length - 2)
            : '',
      });
    }

    this.listaPrestadores = list;

    this.setHardcodV1();
  }

  public goAgendar(idPrestador: any, tipoOperacao: any): void {
    const idEspecialidae = this.listaEspecialidades.find(
      (option: any) => option.nome === this.nomeEspecialidade
    ).id;
    const prestador = this.listaPrestadores.find(
      (option: any) =>
        option.id === idPrestador && option.tipoOperacao === tipoOperacao
    );

    StorageUtil.goAgendamento({
      prestador: idPrestador,
      especialidade: idEspecialidae,
      valor: prestador.valor,
      tipo: prestador.tipoOperacao,
      pagamento: prestador.formaPagamento,
    });

    this.router.navigate(['/agendamentos/' + idPrestador]);
  }

  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.getPrestador();
    }
  }

  public firstPage(): void {
    this.pagination.page = 0;
    this.getPrestador();
  }

  public lastPage(): void {
    this.pagination.page = this.page.totalPages - 1;
    this.getPrestador();
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.getPrestador();
    }
  }

  private setMedicoOnlineHardCode(list: any[]) {
    if (this.buildMedicoOnlineHardCode()) {
      list.push(this.buildMedicoOnlineHardCode());
    }
  }

  private setHardcodV1() {
    if (this.buildV1HardCode()) {
      this.listaPrestadores.push(this.buildV1HardCode());
    }
  }

  private verifyOptionsNoSelect(): void {
    const list = [];
    this.options.forEach((element) => {
      list.push(element.status);
    });

    const verifyList = list.filter((option) => option === true);

    if (verifyList.length === 0) {
      throw new EvalError();
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  call() {
    window.open('tel:+551140001383');
  }

  ngOnInit() {
    this.loadEspecialidades();
    this.loadEstados();
    this.onConsultaRapida();

    // this.user.plano = "TOP";
    this.user.plano = 'BASICO';

  }

  OnDestroy() {}
}
