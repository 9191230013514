import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageUtil } from '../utils/storage';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionResourceService {
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    client_id: environment.client_id,
    access_token: StorageUtil.getAccessToken(),
    Authorization: `Bearer ${StorageUtil.getJwt()}`
  };

  constructor(private http: HttpClient) { }

  public async downgradeAsync(subscriptionId: string): Promise<boolean> {
    const requestOptions = {
      headers: new HttpHeaders(this.headers)
    };

    const request: Promise<boolean> = this.http.post(`${environment.urlBase}subscriptions/${subscriptionId}/downgrade`, null, requestOptions)
      .pipe(map((data: any) => {
        const valid: boolean = data.id != null;
        return valid;
      })).toPromise();

    return await request;
  }
}
