import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { StorageUtil } from '../utils/storage';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.css']
})
export class TimeOutComponent implements OnInit {

  timeOutPopUp: boolean;
  @Input() timeOutMin;
  subscription: Subscription;
  constructor(private router: Router) {}

  public resetTimer(): void {
    this.subscription.unsubscribe();
    this.start();
    this.timeOutPopUp = false;
  }

   start(): void {
    const numbers = timer(1000, 1000);
    this.subscription = new Subscription();

    this.subscription = numbers.subscribe((x) => {

        const cincoMin = 300;

        if (x === cincoMin) { this.timeOutPopUp = true; }
        this.stop( x );
       });

    this.subscription.add(this.subscription);
  }

  private stop( result ): void {
    if (result / 60 === Number(this.timeOutMin)) {
      this.subscription.unsubscribe();
      this.timeOutPopUp = false;
      this.logout();
    }
  }

  public logout() {
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
    this.router.navigate(['']);
  }

  ngOnInit() {
   /*  this.start(); */
  }

  OnDestroy() {
    this.subscription.unsubscribe();
  }
}
