import { FormateDate } from 'src/app/utils/formateDate';

export class DependenteRequest {
    public static payload(data: any, endereco: any): any {
        const telefone = data.telefone.replace(/\D+/g, '');
        return {
            nome: data.nome,
            email: data.email,
            documento: data.documento.replace(/\D+/g, ''),
            sexo: data.sexo,
            parentesco: data.parentesco,
            telefones: [
                {
                    ddd: telefone.substring(0, 2),
                    numero: telefone.substring(2, telefone.length),
                    tipoTelefone: '1'
                }
            ],
            endereco: {
                cep: endereco.cep,
                logradouro: endereco.logradouro,
                numero: endereco.numero,
                complemento: endereco.complemento === undefined ? '' : endereco.complement,
                bairro: endereco.bairro,
                cidade: endereco.cidade,
                uf: endereco.uf,
                pais: endereco.pais
            },
            dataNascimento: FormateDate.parse(data.dataNascimento)
        };
    }

    public static payloadUpdate(data: any, tel: any): any {

        const telefone = tel.replace(/\D+/g, '');

        return {
            nome: data.nome,
            email: data.email,
            documento: data.documento.replace(/\D+/g, ''),
            telefones: [
                {
                    id: data.telefones[0].id,
                    ddd: telefone.substring(0, 2),
                    numero: telefone.substring(2, telefone.length),
                    tipoTelefone: 1
                }
            ],
            endereco: {
                id: data.enderecos[0].id,
                cep: data.enderecos[0].cep,
                logradouro: data.enderecos[0].logradouro,
                numero: data.enderecos[0].numero,
                bairro: data.enderecos[0].bairro,
                cidade: data.enderecos[0].cidade,
                uf: data.enderecos[0].uf,
                pais: data.enderecos[0].pais
            }
        };
    }

    public static dependenteResponse(): any {
        return {
            id: '',
            nome: '',
            email: '',
            documento: '',
            ativo: '',
            sexo: '',
            usuario: {
                id: '',
                login: '',
                nome: '',
                ativo: '',
                perfis: [
                    {
                        id: '',
                        nome: '',
                        descricao: ''
                    }
                ],
                dataInclusao: ''
            },
            data_inclusao: '',
            telefones: [
                {
                    id: '',
                    ddd: '',
                    numero: '',
                    dataInclusao: '',
                    tipoTelefone: {
                        id: 1,
                        nome: '',
                        descricao: ''
                    }
                }
            ],
            enderecos: [
                {
                    id: '',
                    cep: '',
                    logradouro: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    uf: '',
                    pais: '',
                    dataInclusao: ''
                }
            ],
            dataNascimento: ''
        };
    }
}
