import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schedulesPeriod'
})

export class SchedulesPeriodPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let str = '';
    switch (value) {
      case 'MORNING':
        str = 'Manhã';
        break;

      case 'AFTERNOON':
        str = 'Tarde';
        break;

      case 'ALL_DAY':
        str = 'Dia todo';
        break;
    }
    return str;
  }
}
