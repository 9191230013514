import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpUtil } from '../utils/http-util';
import { Spinner } from '../utils/stpiner';
import { map } from 'rxjs/operators';
import { MsgUtil } from '../utils/msgUtil';
import { UriKey } from '../utils/uri-key.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token;
  key = '';
  params = {};
  path = '';
  boleto: BehaviorSubject<string>;


  constructor(private http: HttpClient, private httputil: HttpUtil) { }

  public post(request: any, key?): Observable<any> {
    Spinner.spinner(document, true);
    return this.http.post<any>(this.httputil.buildUrl(key === undefined ? this.key : key) + this.path, request, {
      headers: this.httputil.header(this.token)

    }).pipe(map((response: any) => {
      Spinner.spinner(document);
      this.path = '';
      return response;
    }));
  }

  public put(request: any, key?): Observable<any> {
    Spinner.spinner(document, true);
    return this.http.put<any>(this.httputil.buildUrl(key === undefined ? this.key : key) + this.path, request, {
      headers: this.httputil.header(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      this.path = '';

      return response;
    }));
  }

  public get(key?): Observable<any> {
    Spinner.spinner(document, true);

    return this.http.get<any>(this.httputil.buildUrl(key === undefined ? this.key : key) + this.path, {
      headers: this.httputil.header(this.token),
      params: this.params
    }).pipe(map((response: any) => {
      Spinner.spinner(document);

      this.path = '';
      this.params = {};

      return response;
    }));
  }

  public getLocation(key: any, path: any): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(this.httputil.buildLocation(path), {
      headers: this.httputil.header(this.token),
      params: this.params
    }).pipe(map((response: any) => {
      Spinner.spinner(document);

      this.params = {};

      return response;
    }));
  }

  public getById(id: any, key?): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(this.httputil.buildUrl(key === undefined ? this.key : key) + this.path + id, {
      headers: this.httputil.header(this.token),
      params: this.params
    }).pipe(map((response: any) => {

      this.path = '';
      Spinner.spinner(document);

      return response;
    }));
  }

  public consultFilter(key?): Observable<any> {

    return this.http.get<any>(this.httputil.buildUrl(key === undefined ? this.key : key) + this.path, {
      headers: this.httputil.header(this.token),
      params: this.params
    }).pipe(map((response: any) => {

      this.path = '';
      this.params = {};

      return response;
    }));
  }


  public getRecuperarSenha(login): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(this.httputil.buildUrl('RECUPERAR_SENHA'), {
      headers: this.httputil.headerRecuperarSenha(this.token, login),
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      return response;
    }));
  }

  public sendEmail(data): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.post('https://api-athenasaude.sensedia.com/email/v1/send', data, {
      headers: this.httputil.headerEmail(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);
      return response;
    }));
  }

  public getGeonames(key, path?): Observable<any> {

    return this.http.get<any>(this.httputil.buildUrlGeonames(key, path), {
      headers: this.httputil.header(this.token),
    }).pipe(map((response: any) => {
      return response;
    }));
  }

  public sendSMS(key): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.post<any>(this.httputil.buildSMS(key) + this.path, null, {
      headers: this.httputil.header(this.token),
    }).pipe(map((response: any) => {

      Spinner.spinner(document);
      this.path = '';
      return response;
    }));
  }

  public putAdmin(request: any, key?): Observable<any> {
    Spinner.spinner(document, true);
    return this.http.put<any>(this.httputil.buildUrlAdmin(key === undefined ? this.key : key) + this.path, request, {
      headers: this.httputil.header(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      this.path = '';

      return response;
    }));
  }

  public iniciarAtendimentoTlMedicina(key, tipo): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(this.httputil.buildUrl(key) + this.path, {
      headers: this.httputil.headerTLMedicina(this.token, tipo),
      params: this.params
    }).pipe(map((response: any) => {
      Spinner.spinner(document);

      this.path = '';
      this.params = {};

      return response;
    }));
  }

  public getUser(id): Observable<any> {

    return this.http.get<any>(environment.service + id, {
      headers: this.httputil.header(this.token),
    }).pipe(map((response: any) => {
      return response;
    }));
  }

  public getCep(cep): Observable<any> {

    Spinner.spinner(document, true);

    return this.http.get<any>(`${environment.cep}${cep}`, {
      headers: this.httputil.header(this.token)
    }).pipe(map((response: any) => {

      Spinner.spinner(document);

      if (response.erro) {
        MsgUtil.msgErrorToast('CEP não encontrado');
      }

      return response;
    }));
  }

  public boletoSuccess() {
    this.boleto = new BehaviorSubject('boleto');
  }

  public loadpayment_methods() {

    return this.http.get<any>(`${environment.urlMP}${UriKey.MP_PAYMENT_METHOD}`, {
      params: {
        public_key: environment.public_key
      }
    });
  }

}
