import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { FormateDate } from '../utils/formateDate';
import { MsgUtil } from '../utils/msgUtil';
import { HttpErrorResponse } from '@angular/common/http';
import { AgendamentoUtil } from '../agendamento/agendamentoUtil';

declare var $;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  START_STATUS = 'PRE_AGENDADO';
  agendamentos: any[] = [];
  page = { size: 0, totalElements: 0, totalPages: 0, number: 0 };
  pagination = { page: 0, size: 3 };
  nomeMedico: any = '';
  date: any = '';
  hora: any = '';
  idAgendamento: any;

  constructor(private service: ApiService) {
    this.service.key = 'AGENDAMENTO';
  }

  public nextPage(): void {
    if (this.pagination.page + 1 < this.page.totalPages) {
      this.pagination.page++;
      this.loadAgendamentos();
    }
  }

  public backPage(): void {
    if (this.pagination.page > 0) {
      this.pagination.page--;
      this.loadAgendamentos();
    }
  }

  public getBySatus(status) {
    this.START_STATUS = status;
    this.agendamentos = [];
    this.service.path = 'status/';
    this.service.getById(status).subscribe((r: any) => {
      if (r.length === 0) {return; }
      this.agendamentos = r.agendamentos;
      this.page = r.page;
    });
  }

  private loadAgendamentos() {
    this.agendamentos = [];
    this.service.params = this.pagination;
    this.service.path = 'status/';
    this.service.getById(this.START_STATUS).subscribe((r: any) => {
      if (r.length === 0) {return; }
      this.agendamentos = r.agendamentos;
      this.page = r.page;
    });
  }

  public getDate(event): void {
    setTimeout(() => {
      this.date = FormateDate.parse(event.target.value);
    }, 300);
  }

  public getH(event): void {
    if (event.target.value !== undefined) {
      this.hora = event.target.value.replace('.', ':') + ':00';
    }
  }

  private setHora(): void {
    $('#timepicker').click();
  }

  private invalid(): boolean {
    if (this.date === '') {
      MsgUtil.msgErrorToast('Selecione uma data para continuar');
      return true;
    }

    if (this.hora === ':00' || this.hora === undefined) {
      MsgUtil.msgErrorToast('Selecione uma hora para continuar');
      return true;
    }

    if (this.nomeMedico === '') {
      MsgUtil.msgErrorToast('Selecione o médico para continuar');
      return true;
    }
    return false;
  }

  public confirmarAgendamento(): void {

    this.setHora();

    if (this.invalid()) {
      return;
    }

    $('.confirmar').click();
    this.service.path = this.idAgendamento + '/confirmacoes';
    this.service.put({ data_confirmacao: this.date = this.date + 'T' + this.hora, nome_medico: this.nomeMedico }).subscribe((r: any) => {
      this.ngOnInit();
    });
  }

  public getId(id): void {
   this.idAgendamento = id;
  }

   public checkInAgendamento(): void {
    this.service.path = this.idAgendamento + '/checkin';
    this.service.put(null).subscribe((r: any) => {
      this.ngOnInit();
    });
  }

  public cancelarAgendamento(): void {
    this.service.path = this.idAgendamento + '/cancelamentos';
    this.service.put(null).subscribe((r: any) => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.loadAgendamentos();
    setTimeout(() => {
      AgendamentoUtil.agendamentoJquey();
    }, 600);
  }

  OnDestroy() {
    this.service.path = '';
    this.service.key = '';
  }
}
