import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StorageUtil } from '../utils/storage';
import { Role } from '../interfaces/role';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardClienteService implements CanActivate {

  constructor( private router: Router) { }

  role = Role;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (StorageUtil.getUser()) {
      if (StorageUtil.getUser().user.profileNames.find((option) => option === this.role.Cliente) || StorageUtil.getUser().user.profileNames.find((option) => option === this.role.SuperAdmin)) {
        return true;
      }
    }

    this.router.navigate(['/login']);

    return false;
  }
}
