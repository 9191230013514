import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UriService {

  constructor() { }

  public urlValue(key: string) {
    return URIS.find((option: any) => option.key === key.toUpperCase()).value;
  }
}

const URIS: any[] = [

  { key: 'ESPECIALIDADE', value: 'prestadores/especialidades/' },
  { key: 'ESPECIALIDADE_CIDADES', value: 'prestadores/estados/' },
  { key: 'PRESTADOR', value: 'prestadores/' },
  { key: 'EMPRESA', value: 'empresas/' },
  { key: 'ADMIN', value: 'admins/' },
  { key: 'AGENDAMENTO', value: 'agendamentos/' },
  { key: 'LOGIN', value: 'login' },
  { key: 'CLIENTE', value: 'clientes/' },
  { key: 'USUARIO', value: 'usuarios/' },
  { key: 'RECUPERAR_SENHA', value: 'usuarios/senha' },
  { key: 'TITULAR', value: 'titulares/' },
  { key: 'ESTADO', value: 'estados/' },
  { key: 'DEPENDENTE', value: 'dependentes/' },
  {key: 'CARTEIRINHA', value: 'reenviar/carteirinha/clientes/'},
  {key: 'PLANO_P', value: 'plano-pagamento/'},
  {key: 'EV_AGENDAMENTO', value: 'enviar/agendamentos/'},
  {key: 'TL_MEDICINA', value: 'patients/generate-magiclink-access-app/'},
  {key: 'USER', value: 'users/'},

  /* Gateway */

  { key: 'GRANT_CODE', value: 'grant-code' },
  { key: 'ACCES_TOKEN', value: 'access-token' },
];
