import { StorageUtil } from '../utils/storage';

export class LoadStyle {

    public static setPerfil(key): any {
        switch (key) {
            case 'CLIENTE':
                /* LoadStyle.setHeader('default.css'); */
                break;

                case 'PRESTADOR':
                   /*  LoadStyle.setHeader('default-prestador.css') */
                    break;

            default:
               /*  LoadStyle.setHeader('default.css'); */
                break;
        }
    }

    private static setHeader(header) {
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.id = '';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'assets/css/' + header;
        link.media = 'all';
        head.appendChild(link);
    }
}
