import { CalendarUtil } from './calendar-utils';

export class CalendarConfig {

    private cal1 = { arrow: null, month: null };
    private cal2 = { arrow: null, month: null };
    private cal3 = { arrow: null, month: null };
    intupStyle = 'agendamento form-control';

    public calendar: any = CalendarUtil.calendarContent();
    public invalidDates: Date[] = [];

    public loadCalendarConfig(key?) {
        console.log(key);
        switch (key) {
            case 1:
                this.loadCal1();
                break;

            case 2:
                this.loadCal2();
                break;

            case 3:
                this.loadCal3();
                break;
        }
    }

    public invalidDate() {

        const today = new Date();
        let i = new Date(today.setDate(today.getDate())).getDate();
        while (i >= 1) {
            const td = today.getDate() - i;
            const invalidDate = new Date();
            invalidDate.setDate(td);
            this.invalidDates.push(invalidDate);
            i = i - 1;
        }
    }

    public onMonthChangecal1(event) {
        console.log(event);
        const month = CalendarUtil.calendarContent().monthNames[event.month - 1];
        console.log(month, this.cal1.month);
        if (this.cal1.month.textContent === month && event.year === new Date().getUTCFullYear()) {
            this.loadCal1();
        }
    }

    public onMonthChangecal2(event) {
        const month = CalendarUtil.calendarContent().monthNames[event.month - 1];
        if (this.cal1.month.textContent === month && event.year === new Date().getUTCFullYear()) {
            this.loadCal2();
        }
    }

    public onMonthChangecal3(event) {
        const month = CalendarUtil.calendarContent().monthNames[event.month - 1];
        if (this.cal1.month.textContent === month && event.year === new Date().getUTCFullYear()) {
            this.loadCal3();
        }
    }

    private loadCal1() {
        setTimeout(() => {
            console.log( this.cal1.arrow = document.getElementById('cal1').getElementsByClassName('ui-datepicker-prev'));
            this.cal1.arrow = document.getElementById('cal1').getElementsByClassName('ui-datepicker-prev')[0];
            this.cal1.month = document.getElementById('cal1').getElementsByClassName('ui-datepicker-month')[0];
            this.cal1.arrow.setAttribute('style', 'display:none');
        }, 1);
    }

    private loadCal2() {
        setTimeout(() => {
            this.cal2.arrow = document.getElementById('cal2').getElementsByClassName('ui-datepicker-prev')[0];
            this.cal2.month = document.getElementById('cal2').getElementsByClassName('ui-datepicker-month')[0];
            this.cal2.arrow.setAttribute('style', 'display:none');
        }, 1);
    }

    private loadCal3() {
        setTimeout(() => {
            this.cal3.arrow = document.getElementById('cal3').getElementsByClassName('ui-datepicker-prev')[0];
            this.cal3.month = document.getElementById('cal3').getElementsByClassName('ui-datepicker-month')[0];
            this.cal3.arrow.setAttribute('style', 'display:none');
        }, 1);
    }
}
