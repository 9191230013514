import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { UserService } from '../service/user.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private injector: Injector, private ngZone: NgZone) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
                console.log('401 - 403');
                const userService = this.injector.get(UserService);
                userService.clearStorage();
                this.ngZone.run(() => {
                  this.router.navigate(['/login']);
                });
            }
            // return the error back to the caller
            return throwError(err);
          }
        }),
        finalize(() => {
          // any cleanup or final activities
        })
      );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
