import { UserService } from 'src/app/service/user.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-logado',
  templateUrl: './menu-logado.component.html',
  styleUrls: ['./menu-logado.component.css']
})
export class MenuLogadoComponent implements OnInit {

  @Input() pageName: string;
  @Input() parentName: string;
  submenu = { key: '' };

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.submenu.key = this.parentName;
  }

  openSubmenu(submenu) {
    if (this.submenu.key === submenu) {
      this.submenu.key = '';
    } else {
      this.submenu.key = submenu;
    }
  }

  public logout() {
    this.userService.logout();
  }

}
