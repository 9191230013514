import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { StorageUtil } from 'src/app/utils/storage';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../perfil-cliente/request';
import { DependenteRequest } from './requestUtil';
import { Util } from 'src/app/utils/util';
import { ValidateUtil } from 'src/app/utils/validateUtil';
import { FormateDate } from 'src/app/utils/formateDate';
import { FloatInput } from 'src/app/float-input';

declare var $;

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.css']
})
export class DependentesComponent extends FloatInput implements OnInit {

  placeHolder = 'CPF';
  labelCpf = 'hidden';
  registerFormGroup: FormGroup;
  dataNascimento;
  objEdit = DependenteRequest.dependenteResponse();
  sexo = SEXO;
  formControl = 'form-control';
  parentescos = PARENTESCO;
  endereco: any;
  editar;
  title = 'Cadastrar';
  telefone;

  constructor(private service: ApiService,
              private formBuilder: FormBuilder,
              public router: Router,
              private activateRoute: ActivatedRoute, ) {
      super();
      this.loadComponent();
    }

  private loadFromGroup(): void {
    this.registerFormGroup = this.formBuilder.group({
      nome: ['', [Validators.required]],
      documento: ['', [Validators.required]],
      parentesco: ['', [Validators.required]],
      sexo: ['', [Validators.required]],
      dataNascimento: ['', Validators.required],
      telefone: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  private loadComponent(): void {
    this.activateRoute
        .params.subscribe((params: any) => {
        if ( params.idDependente === undefined ) { return; }

        this.service.getById( params.idDependente, 'CLIENTE' )
                    .subscribe((r) => {

                      this.objEdit = r;

                      if (  this.objEdit.telefones.length > 0) {

                        this.telefone = this.objEdit.telefones[0].ddd.toString() + this.objEdit.telefones[0].numero.toString();

                        setTimeout(() => {
                          $('#tel').val( this.telefone );
                        }, 100);
                      }

                      if ( r.dataNascimento ) {
                          this.objEdit.dataNascimento = FormateDate.formateDataDDMMAAByString( this.objEdit.dataNascimento );
                        }

                    });
        this.editar = true;
        this.title = 'Editar';
      });
  }

  public register(): void {

    switch (this.title) {
      case 'Editar':
        this.put();
        break;

      default: this.post();
               break;
    }
  }

  public post() {

    if (this.invalidPayload()) {
      return;
    }

    this.service.path = StorageUtil.getUserPerfil() + '/dependentes';
    this.service.post( DependenteRequest.payload(this.registerFormGroup.value, this.endereco ), 'TITULAR' )
                .subscribe(() => {
                   this.service.path = '';
                   MsgUtil.msgSuccessToast('dependente cadastrado');
                   this.registerFormGroup.reset();
                }, (error: HttpErrorResponse) => {
                  MsgUtil.msgErrorToast(error.error.messages[0].message);
                });
  }

  public put() {

    this.service.path = this.objEdit.id;
    this.service.put( DependenteRequest.payloadUpdate( this.objEdit, this.telefone ), 'CLIENTE' )
                .subscribe(() => {
                   this.service.path = '';
                   MsgUtil.msgSuccessToast('dependente Editado');

                   this.ngOnInit();

                }, (error: HttpErrorResponse) => {
                  MsgUtil.msgErrorToast(error.error.messages[0].message);
                });
  }

  public invalidPayload(): boolean {

    if (!ValidateUtil.validarCPF(this.registerFormGroup.value.documento) ) {
      MsgUtil.msgErrorToast('Documento inválido');
      return true;
    }

    if (this.registerFormGroup.invalid) {
      MsgUtil.msgErrorToast('Todos os campos são obrigatórios');
      return true;
    }

    if (this.registerFormGroup.value.sexo === '') {
      MsgUtil.msgErrorToast('Todos os campos são obrigatórios');
      return true;
    }

    if (Util.defaulValueSelect(this.registerFormGroup.value.parentesco)) {
      MsgUtil.msgErrorToast('Todos os campos são obrigatórios');
      return true;
    }

    if (Util.defaulValueSelect(this.registerFormGroup.value.sexo)) {
      MsgUtil.msgErrorToast('Todos os campos são obrigatórios');
      return true;
    }

    return false;
  }

  public validateDoc(): any {
    if (!ValidateUtil.validarCPF(this.registerFormGroup.value.documento)  ) {
      MsgUtil.msgErrorToast('Documento inválido');
      return true;
    }
  }

  public loadEndereco(): void {

    this.service.getById( StorageUtil.getUserPerfil(), 'CLIENTE' )
                .subscribe((r: any) => {
                   this.endereco = r.enderecos[0];
                });
  }

  public logout() {
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.loadEndereco();
    this.loadFromGroup();
  }

  OnDestroy() {
  }

}

const PARENTESCO = [
  'Selecionar parentesco',
  'Pai/Mãe',
  'Cônjuge',
  'Filho'
];

const SEXO = [
  'Selecionar sexo',
  'Masculino',
  'Feminino',
];
