import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpClient,
    HttpResponse
   } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpUtil } from './http-util';
import { Spinner } from './stpiner';
import { ApiService } from '../service/api.service';

export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private service: ApiService) {}


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {

            Spinner.spinner(document);
            this.service.path = '';
            return throwError(error);
          })
        );
    }
   }
