export class Card {

    public static getTypeCardId(cardnumber2) {
        const cardnumber = cardnumber2.replace(/[^0-9]+/g, '');

        const cards = {
            visa: /^4[0-9]{12}(?:[0-9]{3})/,
            master: /^(5|(2(221|222|223|224|225|226|227|228|229|23|24|25|26|27|28|29|3|4|5|6|70|71|720)))/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            debmaster: /^(502121)/,
            amex: /^3[47][0-9]{13}/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        };

        for (const flag in cards) {
            if (cards[flag].test(cardnumber)) {
                return flag;
            }
        }

        return false;
    }

    public static payloadToken(card, date, doc) {
        return {
            card_number: card.numero_cartao,
            security_code: card.codgo_seguranca_cartao,
            expiration_month: date.substring(4, date.length),
            expiration_year: date.substring(0, 4),
            cardholder: {
                name: card.nome_cartao,
                identification: {
                    number: doc.replace(/\D+/g, ''),
                    type: 'CPF'
                }
            }
        };
    }

    public static payloadCard(data) {
        return {
            nome: data.nome + ' ' + data.sobrenome,
            documento: data.documento.replace(/\D+/g, ''),
            celular: data.celular,
            email: data.email,
            tipo_pagamento: data.tipo_pagamento,
            parcelas: data.parcelas,
            card_token: data.card_token,
            payment_method_id: data.payment_method_id,
        };
    }

    public static loadpayloadCard() {
        return {
            nome: '',
            sobrenome: '',
            documento: '',
            celular: '',
            email: '',
            tipo_pagamento: 'CARTAO',
            parcelas: '',
            card_token: '',
            payment_method_id: '',
        };
    }

    public static inputCard() {
        return {
            numero_cartao: '',
            nome_cartao: '',
            vencimento_cartao: '',
            codgo_seguranca_cartao: '',
            parcelas: ''
        };
    }

}
