import { Component, HostListener, OnInit } from '@angular/core';
import { AppleService } from 'src/app/service/social/apple.service';
import { UserService } from 'src/app/service/user.service';
import { ErrorReturnUtil } from 'src/app/utils/errorReturnUtil';
import { StorageUtil } from 'src/app/utils/storage';
import { Spinner } from 'src/app/utils/stpiner';
import { environment } from 'src/environments/environment';

declare var $;

@Component({
  selector: 'app-ios12login',
  templateUrl: './ios12login.component.html',
  styleUrls: ['./ios12login.component.css']
})
export class Ios12loginComponent implements OnInit {

  constructor(private appleService: AppleService, private userService: UserService) {
  }

  ngOnInit() {
  }

  socialLogin(provider) {
    switch (provider) {
      case 'APPLE':
        this.appleService.loadApple();
        $('#appleid-signin').click();
        break;
    }
  }

  // Getting information from apple pop up
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin === 'https://appleid.apple.com') {
      const payload = this.appleService.responseApple(event);
      if (payload) {
        const paramName = payload.name ? 'name=' + payload.name + '&' : '';
        const paramEmail = payload.email ? 'email=' + payload.email + '&' : '';
        const paramToken = payload.token ? 'token=' + payload.token + '&' : '';
        const paramId = payload.id ? 'id=' + payload.id + '&' : '';
        window.location.href = environment.apple_redirect_ios12 + '?' + paramName + paramEmail + paramToken + paramId;
      } else {
        ErrorReturnUtil.buildError(null, 'Ocorreu um erro no login via Apple. Por favor, tente novamente mais tarde.');
      }
    }
  }

}
