import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { StorageUtil } from 'src/app/utils/storage';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MsgUtil } from 'src/app/utils/msgUtil';
import { Config } from '../request';

declare var $;


@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {

  registerFormGroup: FormGroup;

  objEdit = Config.userRequest();
  senhaAtual: any = '';
  novaSenha = '';
  repetirSenha = '';
  senhaIvalida = true;
  img = 'assets/img/avatar.jpg';
  user = StorageUtil.getUser();

  constructor(private service: ApiService,
              public router: Router) {
    this.service.key = 'USUARIO';
  }

  public loadUserById(): void {

    this.service.getById(StorageUtil.getUser().id)
      .subscribe((r: any) => {
        this.objEdit = r;
      });
  }

  public updateImage() {
    $('.inputFile').click();
  }

  public login() {
    if (this.senhaAtual === '') {
      return;
    }

    this.service.path = 'login';
    this.service.post({ login: StorageUtil.getUser().login, senha: this.senhaAtual }).subscribe((r) => {

      this.senhaIvalida = false;
      MsgUtil.msgInfoToast('Digite a nova senha');

    }, (error: HttpErrorResponse) => {
      MsgUtil.msgErrorToast(error.error.messages[0].message);
    });
  }

  public alterarSenha() {

    this.service.path = StorageUtil.getUser().id;
    this.service.put(Config.payloadSenha(this.novaSenha)).subscribe(() => {
      this.resetInpus();
      MsgUtil.msgSuccessToast('Senha alterada com sucesso');
    });
  }

  resetInpus(): void {
    this.senhaAtual = '';
    this.novaSenha = '';
    this.repetirSenha = '';
    this.senhaIvalida = true;
  }

  public logout() {
    StorageUtil.clearSession();
    StorageUtil.cleanAgendamento();
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.loadUserById();
  }

  OnDestroy() {
    this.service.path = '';
    this.service.key = '';
  }


}
